import React from "react";
import {Spinner, Container} from 'react-bootstrap';

export default function Loading() {
    return (
        <>
            <Container className="d-flex justify-content-center align-items-center" style={ {height: '100vh'} }>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </Container>
        </>
    )
}
