export const navModules = [
    {
        role: "Admin",
        modules: [
            {
                name: "Root",
                value: "root",
                disabled: false,
                hide: true,
                permissions: [
                    {
                        name: "read",
                        path: "/",
                        value: "root.read",
                    },
                ],
            },
            {
                name: "Home",
                value: "home",
                disabled: false,
                hide: true,
                permissions: [
                    {
                        name: "read",
                        path: "/home",
                        value: "home.read",
                    },
                ],
            },
            {
                name: "Profile",
                value: "profile",
                href: "/profile",
                hide: true,
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/profile",
                        value: "profile.read",
                    },
                ],
            },
            {
                name: "Centri Master",
                value: "mastercyber",
                href: "/mastercyber",
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/mastercyber",
                        value: "mastercyber.read",
                    },
                    {
                        name: "create",
                        path: "/mastercyber/new",
                        value: "mastercyber.create",
                    },
                    {
                        name: "view",
                        path: "/mastercyber/[id]",
                        value: "mastercyber.view",
                    },
                    {
                        name: "edit",
                        path: "/mastercyber/[id]/edit",
                        value: "mastercyber.edit",
                    },
                ],
            },
            {
                name: "Centri Cyber",
                value: "punticyber",
                href: "/punticyber",
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/punticyber",
                        value: "punticyber.read",
                    },
                    {
                        name: "create",
                        path: "/punticyber/new",
                        value: "punticyber.create",
                    },
                    {
                        name: "view",
                        path: "/punticyber/[id]",
                        value: "punticyber.view",
                    },
                    {
                        name: "edit",
                        path: "/punticyber/[id]/edit",
                        value: "punticyber.edit",
                    },
                ],
            },
            {
                name: "Ambassadors",
                value: "ambassador",
                href: "/ambassador",
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/ambassador",
                        value: "ambassador.read",
                    },
                    {
                        name: "create",
                        path: "/ambassador/new",
                        value: "ambassador.create",
                    },
                    {
                        name: "view",
                        path: "/ambassador/[id]",
                        value: "ambassador.view",
                    },
                    {
                        name: "edit",
                        path: "/ambassador/[id]/edit",
                        value: "ambassador.edit",
                    },
                ],
            },
            {
                name: "Clienti",
                value: "customers",
                href: "/customers",
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/customers",
                        value: "customers.read",
                    },
                    {
                        name: "create",
                        path: "/customers/new",
                        value: "customers.create",
                    },
                    {
                        name: "view",
                        path: "/customers/[id]",
                        value: "customers.view",
                    },
                    {
                        name: "edit",
                        path: "/customers/[id]/edit",
                        value: "customers.edit",
                    },
                ],
            },
            {
                name: "Convenzioni",
                value: "agreements",
                href: "/agreements",
                disabled: false,
                hide: false,
                permissions: [
                    {
                        name: "read",
                        path: "/agreements",
                        value: "agreements.read",
                    },
                    {
                        name: "create",
                        path: "/agreements/new",
                        value: "agreements.create",
                    },
                    {
                        name: "view",
                        path: "/agreements/[id]",
                        value: "agreements.view",
                    },
                    {
                        name: "edit",
                        path: "/agreements/[id]/edit",
                        value: "agreements.edit",
                    },
                ]},
            {
                name: "Ordini",
                value: "orders",
                href: "/orders",
                disabled: false,
                hide: false,
                permissions: [
                    {
                        name: "read",
                        path: "/orders",
                        value: "orders.read",
                    },
                    {
                        name: "view",
                        path: "/orders/[id]",
                        value: "orders.view",
                    },
                ],
            },
            {
                name: "Prodotti",
                value: "products",
                href: "/products",
                disabled: false,
                superadmin: true,
                permissions: [
                    {
                        name: "read",
                        path: "/products",
                        value: "products.read",
                        superadmin: true,
                    },
                    {
                        name: "create",
                        path: "/products/new",
                        value: "products.create",
                        superadmin: true,
                    },
                    {
                        name: "view",
                        path: "/products/[id]",
                        value: "products.view",
                        superadmin: true,
                    },
                    {
                        name: "edit",
                        path: "/products/[id]/edit",
                        value: "products.edit",
                        superadmin: true,
                    },
                ],
            },
            {
                name: "Compensi",
                value: "remuneration",
                disabled: true,
                hide: true,
                permissions: [],
            },
        ],
    },
    {
        role: "Sales",
        modules: [
            {
                name: "Profile",
                value: "profile",
                href: "/profile",
                hide: true,
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/profile",
                        value: "profile.read",
                    },
                ],
            },
            {
                name: "Root",
                value: "root",
                disabled: false,
                hide: true,
                permissions: [
                    {
                        name: "read",
                        path: "/",
                        value: "root.read",
                    },
                ],
            },
            {
                name: "Home",
                value: "home",
                disabled: false,
                hide: true,
                permissions: [
                    {
                        name: "read",
                        path: "/home",
                        value: "home.read",
                    },
                ],
            },
            {
                name: "Centri Master",
                value: "mastercyber",
                href: "/mastercyber",
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/mastercyber",
                        value: "mastercyber.read",
                    },
                    {
                        name: "create",
                        path: "/mastercyber/new",
                        value: "mastercyber.create",
                    },
                    {
                        name: "view",
                        path: "/mastercyber/[id]",
                        value: "mastercyber.view",
                    },
                    {
                        name: "edit",
                        path: "/mastercyber/[id]/edit",
                        value: "mastercyber.edit",
                    },
                ],
            },
            {
                name: "Centri Cyber",
                value: "punticyber",
                href: "/punticyber",
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/punticyber",
                        value: "punticyber.read",
                    },
                    {
                        name: "create",
                        path: "/punticyber/new",
                        value: "punticyber.create",
                    },
                    {
                        name: "view",
                        path: "/punticyber/[id]",
                        value: "punticyber.view",
                    },
                    {
                        name: "edit",
                        path: "/punticyber/[id]/edit",
                        value: "punticyber.edit",
                    },
                ],
            },
            {
                name: "Ambassadors",
                value: "ambassador",
                href: "/ambassador",
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/ambassador",
                        value: "ambassador.read",
                    },
                    {
                        name: "create",
                        path: "/ambassador/new",
                        value: "ambassador.create",
                    },
                    {
                        name: "view",
                        path: "/ambassador/[id]",
                        value: "ambassador.view",
                    },
                    {
                        name: "edit",
                        path: "/ambassador/[id]/edit",
                        value: "ambassador.edit",
                    },
                ],
            },
            {
                name: "Clienti",
                value: "customers",
                href: "/customers",
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/customers",
                        value: "customers.read",
                    },
                    {
                        name: "view",
                        path: "/customers/[id]",
                        value: "customers.view",
                    },
                ],
            },
            {
                name: "Ordini",
                value: "orders",
                href: "/orders",
                disabled: false,
                hide: false,
                permissions: [
                    {
                        name: "read",
                        path: "/orders",
                        value: "orders.read",
                    },
                    {
                        name: "view",
                        path: "/orders/[id]",
                        value: "orders.view",
                    },
                ],
            },
            {
                name: "Compensi",
                value: "remuneration",
                disabled: true,
                hide: true,
                permissions: [],
            },
        ],
    },
    {
        role: "MasterCenter",
        modules: [
            {
                name: "Profile",
                value: "profile",
                href: "/profile",
                hide: true,
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/profile",
                        value: "profile.read",
                    },
                ],
            },
            {
                name: "Root",
                value: "root",
                disabled: false,
                hide: true,
                permissions: [
                    {
                        name: "read",
                        path: "/",
                        value: "root.read",
                    },
                ],
            },
            {
                name: "Home",
                value: "home",
                disabled: false,
                hide: true,
                permissions: [
                    {
                        name: "read",
                        path: "/home",
                        value: "home.read",
                    },
                ],
            },
            {
                name: "Centri Cyber",
                value: "punticyber",
                href: "/punticyber",
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/punticyber",
                        value: "punticyber.read",
                    },
                    {
                        name: "view",
                        path: "/punticyber/[id]",
                        value: "punticyber.view",
                    },
                ],
            },
            {
                name: "Ambassadors",
                value: "ambassador",
                href: "/ambassador",
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/ambassador",
                        value: "ambassador.read",
                    },
                    {
                        name: "view",
                        path: "/ambassador/[id]",
                        value: "ambassador.view",
                    },
                ],
            },
            {
                name: "Clienti",
                value: "customers",
                href: "/customers",
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/customers",
                        value: "customers.read",
                    },
                    {
                        name: "view",
                        path: "/customers/[id]",
                        value: "customers.view",
                    },
                ],
            },
            {
                name: "Ordini",
                value: "orders",
                href: "/orders",
                disabled: false,
                hide: false,
                permissions: [
                    {
                        name: "read",
                        path: "/orders",
                        value: "orders.read",
                    },
                    {
                        name: "view",
                        path: "/orders/[id]",
                        value: "orders.view",
                    },
                ],
            },
            {
                name: "Compensi",
                value: "fees",
                href: "/fees",
                disabled: false,
                hide: false,
                permissions: [ {
                    name: "read",
                    path: "/fees",
                    value: "fees.read",
                }],
            },
        ],
    },
    {
        role: "Center",
        modules: [
            {
                name: "Profile",
                value: "profile",
                href: "/profile",
                hide: true,
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/profile",
                        value: "profile.read",
                    },
                ],
            },
            {
                name: "Root",
                value: "root",
                disabled: false,
                hide: true,
                permissions: [
                    {
                        name: "read",
                        path: "/",
                        value: "root.read",
                    },
                ],
            },
            {
                name: "Home",
                value: "home",
                disabled: false,
                hide: true,
                permissions: [
                    {
                        name: "read",
                        path: "/home",
                        value: "home.read",
                    },
                ],
            },
            {
                name: "Ambassadors",
                value: "ambassador",
                href: "/ambassador",
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/ambassador",
                        value: "ambassador.read",
                    },
                    {
                        name: "view",
                        path: "/ambassador/[id]",
                        value: "ambassador.view",
                    },
                ],
            },
            {
                name: "Clienti",
                value: "customers",
                href: "/customers",
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/customers",
                        value: "customers.read",
                    },
                    // {
                    //     name: "create",
                    //     path: "/customers/new",
                    //     value: "customers.create",
                    // },
                    {
                        name: "view",
                        path: "/customers/[id]",
                        value: "customers.view",
                    },
                    {
                        name: "csv-signup",
                        path: "/customers/csv-signup",
                        value: "customers.csv-signup",
                    },
                    // {
                    //     name: "edit",
                    //     path: "/customers/[id]/edit",
                    //     value: "customers.edit",
                    // },
                ],
            },
            {
                name: "Ordini",
                value: "orders",
                href: "/orders",
                disabled: false,
                hide: false,
                permissions: [
                    {
                        name: "read",
                        path: "/orders",
                        value: "orders.read",
                    },
                    {
                        name: "create",
                        path: "/orders/new",
                        value: "orders.create",
                    },
                    {
                        name: "view",
                        path: "/orders/[id]",
                        value: "orders.view",
                    },
                    {
                        name: "edit",
                        path: "/orders/[id]/edit",
                        value: "orders.edit",
                    },
                    {
                        name: "vat",
                        path: "/orders/[id]/edit/vat",
                        value: "vat.edit",
                    },
                ],
            },
            {
                name: "Lead",
                value: "lead",
                href: "/lead",
                disabled: false,
                hide: false,
                permissions: [
                    {
                        name: "read",
                        path: "/lead",
                        value: "lead.read",
                    },
                    {
                        name: "view",
                        path: "/lead/[id]",
                        value: "lead.view",
                    },
                ],
            },
            {
                name: "Compensi",
                value: "fees",
                href: "/fees",
                disabled: false,
                hide: false,
                permissions: [{
                    name: "read",
                    path: "/fees",
                    value: "fees.read",
                }],
            },
        ],
    },
    {
        role: "Ambassador",
        modules: [
            {
                name: "Profile",
                value: "profile",
                href: "/profile",
                hide: true,
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/profile",
                        value: "profile.read",
                    },
                ],
            },
            {
                name: "Root",
                value: "root",
                disabled: false,
                hide: true,
                permissions: [
                    {
                        name: "read",
                        path: "/",
                        value: "root.read",
                    },
                ],
            },
            {
                name: "Home",
                value: "home",
                disabled: false,
                hide: true,
                permissions: [
                    {
                        name: "read",
                        path: "/home",
                        value: "home.read",
                    },
                ],
            },
            {
                name: "Ordini",
                value: "orders",
                href: "/orders",
                disabled: false,
                hide: false,
                permissions: [
                    {
                        name: "read",
                        path: "/orders",
                        value: "orders.read",
                    },
                    {
                        name: "create",
                        path: "/orders/new",
                        value: "orders.create",
                    },
                    {
                        name: "view",
                        path: "/orders/[id]",
                        value: "orders.view",
                    },
                    {
                        name: "edit",
                        path: "/orders/[id]/edit",
                        value: "orders.edit",
                    },
                    {
                        name: "vat",
                        path: "/orders/[id]/edit/vat",
                        value: "vat.edit",
                    },
                ],
            },
            {
                name: "Clienti",
                value: "customers",
                href: "/customers",
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/customers",
                        value: "customers.read",
                    },
                    // {
                    //     name: "create",
                    //     path: "/customers/new",
                    //     value: "customers.create",
                    // },
                    {
                        name: "view",
                        path: "/customers/[id]",
                        value: "customers.view",
                    },
                    {
                        name: "edit",
                        path: "/customers/[id]/edit",
                        value: "customers.edit",
                    },
                ],
            },
            // {
            //     name: "Compensi",
            //     value: "remuneration",
            //     disabled: true,
            //     hide: true,
            //     permissions: [],
            // },
        ],
    },
    {
        role: "Customer",
        modules: [
            {
                name: "Root",
                value: "root",
                disabled: false,
                hide: true,
                permissions: [
                    {
                        name: "read",
                        path: "/",
                        value: "root.read",
                    },
                ],
            },
            {
                name: "Profile",
                value: "profile",
                href: "/profile",
                hide: true,
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/profile",
                        value: "profile.read",
                    },
                ],
            },
            {
                name: "Home",
                value: "home",
                disabled: false,
                hide: true,
                permissions: [
                    {
                        name: "read",
                        path: "/home",
                        value: "home.read",
                    },
                ],
            },
            {
                name: "Acquista",
                value: "landing",
                disabled: false,
                hide: false,
                href: "/landing/buy",
                permissions: [
                    {
                        name: "buy",
                        path: "/landing/buy",
                        value: "root.read",
                    },
                    {
                        name: "login",
                        path: "/landing/login",
                        value: "root.login",
                    },
                    {
                        name: "vat",
                        path: "/landing/vat",
                        value: "root.vat",
                    },
                    {
                        name: "checkout",
                        path: "/landing/checkout",
                        value: "root.checkout",
                    },
                    {
                        name: "success",
                        path: "/landing/buy/success",
                        value: "root.success",
                    },
                    {
                        name: "fail",
                        path: "/landing/buy/fail",
                        value: "root.fail",
                    },
                ],
            },
            {
                name: "Ordini",
                value: "orders",
                href: "/orders",
                hide: false,
                disabled: false,
                permissions: [
                    {
                        name: "read",
                        path: "/orders",
                        value: "orders.read",
                    },
                    {
                        name: "view",
                        path: "/orders/[id]",
                        value: "orders.view",
                    },
                    {
                        name: "edit",
                        path: "/orders/[id]/edit",
                        value: "orders.edit",
                    },
                    {
                        name: "vat",
                        path: "/orders/[id]/edit/vat",
                        value: "vat.edit",
                    },
                    {
                        name: "checkout",
                        path: "/orders/[id]/edit/checkout",
                        value: "checkout.edit",
                    },
                ],
            },
        ],
    },
];
