import axios from "axios";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {calculateQueryString} from "../helpers/apiHelpers";

/*** Queries **********************/

const _fetcher = (baseUrl, userId=null, includes = [], query = {}) => {
    const uri = (userId) ? `/${userId}` : '';
    let queryStr = calculateQueryString({includes: includes, ...query})
    return axios.get(`${baseUrl}${uri}${queryStr}`)
        .then(res => {
            return res.data
        });
}
export const useUserMetadata = ({userId = null, includes = [], query = {}, fetcher = _fetcher, queryOptions = {}})=>{
    return useQuery(['users','metadata'],() => fetcher('/api/protected/users/metadata'), {refetchInterval: 10000, ...queryOptions})
}
export const useUsersQuery = (userId = null, includes = [], query = {}, fetcher = _fetcher) => {
    return useQuery(['users', userId], () => fetcher('/api/protected/users', userId, includes, query), {refetchInterval: 10000})
}

export const useUsersForSelectQuery = (userId = null, includes = [], query = {}, fetcher = _fetcher) => {
    return useQuery(['users','for-select', userId], () => fetcher('/api/protected/users/for-select', userId, includes, query), {refetchInterval: 10000})
}

export const useUsersMeQuery = ({includes = [], query = {}, fetcher = _fetcher, queryOptions = {}}) => {
    return useQuery(['users','me'], () => fetcher('/api/protected/users/me', null, includes, query), {refetchInterval: 10000, ...queryOptions})
}


/*** Mutations **********************/
export const createUsersMutator = (params) => {
    return axios.post(
        `/api/protected/users`, params
    ).then(res => res);
}

export const useCreateUsersMutation = (mutator = createUsersMutator) => {
    const queryClient = useQueryClient()
    return useMutation(
        ['create-users'],
        (params) => mutator(params),
        {onSuccess: () => queryClient.invalidateQueries(["users"])}
    );
}