// RoleContext.js
import {useUser} from '@auth0/nextjs-auth0/client';
import {createContext, useContext, useState, useEffect} from 'react';
import {useUserMetadata} from "../queries/users";

const RoleContext = createContext();

const RoleProvider = ({children}) => {
    const [role, setRole] = useState();
    const [isSuperadmin, setIsSuperadmin] = useState(false);
    const {user, error: errorUser, isLoading: isLoadingUser} = useUser();
    const userMetadataQueryEnabled = !!user;
    const {data: metadata, error: errorMetadata, isLoading: isLoadingMetadata} = useUserMetadata({queryOptions:{enabled:userMetadataQueryEnabled}} );
    const [isLoading , setIsLoading ] = useState(true)

    useEffect(() => {
        if (user && process.env.NEXT_PUBLIC_AUTH0_AUDIENCE) {
            const role = user[process.env.NEXT_PUBLIC_AUTH0_AUDIENCE + '/roles'] && user[process.env.NEXT_PUBLIC_AUTH0_AUDIENCE + '/roles'][0];
            setRole(role);
        }
        setIsLoading((userMetadataQueryEnabled && isLoadingMetadata) || isLoadingUser)
    }, [isLoadingUser, isLoadingMetadata, userMetadataQueryEnabled, user]);
    useEffect(() => {
        if (metadata) {
            setIsSuperadmin(metadata?.is_superadmin?.toString()?.toLowerCase() === 'true' || false);
        }
        setIsLoading(isLoadingMetadata || isLoadingUser)
    }, [isLoadingMetadata, metadata]);

    return (
        <RoleContext.Provider value={ {role, isSuperadmin, isLoading} }>
            { children }
        </RoleContext.Provider>
    );
};

const useRole = () => useContext(RoleContext);

export {RoleProvider, useRole};
