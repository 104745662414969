import { Button, Col, Container, Row } from 'react-bootstrap';
import { useRouter } from 'next/router';
export default function UnderCostruction() {
    const router = useRouter()

  return (
    <div className="d-flex align-items-center bg-auth border-top-2 ">
      <Container>
        <Row className="justify-content-center">
          <Col xs={12} md={5} xl={4} className="my-5">
            <div className="text-center">
              <h6 className="text-uppercase text-muted mb-4">Pagina in Costruzione</h6>
              <h1 className="display-4 mb-3">Lavori in corso 🚧</h1>
              <Button  onClick={() => router.push('/')}  size="lg" className='mt-5'>
                Torna alla Home
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
