import React from "react";
import {Card, Col, Form, Row, CloseButton} from 'react-bootstrap';
import {formatAssetType, formatAssetTypePlaceholder} from "../../helpers/formatAssetTypeHelper";
import CustomSelect from "../../widgets/form-helpers/CustomSelect";
import {sectors} from "../../helpers/formatAssetTypeHelper";
import {Field} from "formik";

export default function ProductCardSection({
                                               child_item_line,
                                               formikProps,
                                               onDeleteItemLine,
                                               canDeleteProducts = false,
                                               onBlurFactory = null,
                                               clickedOnSubmit = false,
                                               ...props
                                           }) {

    // const debouncedEmailValidation = _.debounce((name, val, field, form) => {
    //     checkBlackListMutation({'items':[val]})
    //         .then(res => {
    //             if (res.data.blacklisted.length>0) {
    //                 toast.error("Dominio non permesso")
    //                 field.setError('Dominio non permesso')
    //                 form.setStatus(null)
    //             } else {
    //                 field.setError(null)
    //             }
    //         });
    // }, 500, {
    //     trailing: true
    // });

    return (

            <Card.Body>
                <Row>
                    <Col sm={ 9 }>
                        { child_item_line.assets.map(asset => (
                            <Form.Group controlId={ child_item_line.id + '_' + asset.id } className='mb-1'
                                        key={ child_item_line.id + '_' + asset.id }>
                                <Row className='mb-1'>
                                    <Col sm={ 3 }
                                         className="my-auto">{ `${ formatAssetType(asset.type) }` }</Col>
                                    <Col sm={ 9 } className="form-group my-auto">
                                        { asset.type === 'Sector' ? (
                                            <>
                                                <CustomSelect
                                                    name={child_item_line.id + '_' + asset.id}
                                                    placeholder={ formatAssetTypePlaceholder(asset.type) }
                                                    onBlur={ (onBlurFactory && onBlurFactory(child_item_line.id + '_' + asset.id, formikProps)) ?? formikProps.handleBlur }
                                                    options={ sectors }
                                                    onChange={formikProps.handleChange}
                                                    formGroupClassName={"mt-1"}
                                                    clickedOnSubmit={clickedOnSubmit}
                                                />
                                            </>
                                        ) : asset.type === 'Sector' ? (
                                            <>
                                                <Field name={child_item_line.id + '_' + asset.id}>
                                                    {({
                                                          field, // { name, value, onChange, onBlur }
                                                          form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                                          meta,
                                                      }) => {

                                                        // const handleDomainChange = (e) => {
                                                        //     // field.setStatus("asyncValidating")
                                                        //     // form.setStatus("asyncValidating")
                                                        //     form.handleChange(e);
                                                        //     field.setError("Validazione in corso...")
                                                        //     debouncedEmailValidation(name, e.target.value, form);
                                                        // };

                                                        return (
                                                        <>
                                                            <Form.Control
                                                                type={asset.type === 'Email' ? 'email' : 'text'}
                                                                placeholder={formatAssetTypePlaceholder(asset.type)}
                                                                value={formikProps.values[child_item_line.id + '_' + asset.id]}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={(onBlurFactory && onBlurFactory(field.name, formikProps)) ?? formikProps.handleBlur}
                                                                onKeyDown={e => {
                                                                    e.key === 'Enter' && e.preventDefault()
                                                                }}
                                                                isInvalid={(meta.touched || clickedOnSubmit) && !!meta.error}
                                                            />
                                                            <Form.Control.Feedback
                                                                type="invalid">{meta.error}</Form.Control.Feedback>
                                                        </>)}}
                                                </Field>
                                            </>
                                        ) : (
                                            <>
                                                <Field name={child_item_line.id + '_' + asset.id}>
                                                    {({
                                                          field, // { name, value, onChange, onBlur }
                                                          form: {touched, errors}, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                                                          meta,
                                                      }) => (
                                                        <>
                                                            <Form.Control
                                                                type={asset.type === 'Email' ? 'email' : 'text'}
                                                                placeholder={formatAssetTypePlaceholder(asset.type)}
                                                                value={formikProps.values[child_item_line.id + '_' + asset.id]}
                                                                onChange={formikProps.handleChange}
                                                                onBlur={(onBlurFactory && onBlurFactory(field.name, formikProps)) ?? formikProps.handleBlur}
                                                                onKeyDown={e => {
                                                                    e.key === 'Enter' && e.preventDefault()
                                                                }}
                                                                isInvalid={(meta.touched || clickedOnSubmit) && !!meta.error}
                                                            />
                                                            <Form.Control.Feedback
                                                                type="invalid">{meta.error}</Form.Control.Feedback>
                                                        </>)}
                                                </Field>
                                            </>
                                        ) }
                                    </Col>
                                </Row>
                            </Form.Group>))
                        }</Col>
                    <Col sm={ 2 } className="d-flex align-items-center justify-content-end">
                        <div>
                            <h1 className='mb-0'>{ child_item_line.subtotal_price } €</h1>
                            <h5 className='text-muted mt-1 mb-0'>IVA ESCLUSA</h5>
                        </div>
                    </Col>
                    <Col sm={ 1 } className="d-flex justify-content-end delete-item-line-icon" >
                        { canDeleteProducts && (<CloseButton size="xl" onClick={ () => onDeleteItemLine(child_item_line) }/>) }
                    </Col>
                </Row>
            </Card.Body>

    );
}
