import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';


function WrapperComponent(props) {
  return (
    <div className="main-content mx-3">
      <Container fluid >
        <Row className="justify-content-center">
          <Col md={12} xl={10} xxl={8} >
            {props.children}
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default WrapperComponent;