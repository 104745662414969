import React from "react";
import { Card, Col, Row } from 'react-bootstrap';
import { WrapperComponent } from '../components'
export default function NoDataSignUp() {
    return (
        <WrapperComponent>
            <Card className='mt-5' >
                <Row className="d-flex justify-content-center align-items-center" style={{ height: '50vh' }}>
                    <Col xs={12} md={5} xl={4} className="my-5">
                        <h6 className="text-uppercase text-muted mb-4">Link non attivo</h6>
                        <h6 className="text-uppercase text-muted mb-4"></h6>
                        <h1 className="display-4 mb-3">Questo link per l'iscrizione non è valido</h1>
                        <h1 className="display-5 mb-3">Contatta il tuo partner</h1>
                    </Col>
                </Row>
            </Card>
        </WrapperComponent>
    )
}

