import {Accordion} from "react-bootstrap";
import React from "react";

export default function Faq() {
    return (<Accordion>
        <Accordion.Item eventKey="0" className={ 'mb-4 ' }>
            <Accordion.Header className={ 'text-uppercase' }>IN COSA CONSISTE IL TEST
                GRATUITO?</Accordion.Header>
            <Accordion.Body>
                Il test gratuito permette di scoprire rapidamente il tuo livello di rischio cyber, rilevato
                da un’analisi della superficie esterna esposta (domini e credenziali). La valutazione viene
                effettuata automaticamente attraverso un modulo della piattaforma di Threat Intelligence di
                DuskRise e mostra un indicatore del rischio generale oltre ad una analisi quantitativa delle
                esposizioni rilevate (numero di vulnerabilità e di credenziali).
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className={ 'mb-4 ' }>
            <Accordion.Header className={ 'text-uppercase' }>QUALI AZIONI POSSO COMPIERE UNA VOLTA
                OTTENUTO IL RISULTATO DEL TEST GRATUITO?</Accordion.Header>
            <Accordion.Body>
                Una volta determinato il livello di esposizione della superficie esterna, potrai
                immediatamente scegliere di effettuare un’analisi di secondo livello Cyber Check-Up
                che ti permetterà di conoscere nel dettaglio tutte le informazioni utili alla messa in
                sicurezza del tuo spazio digitale. Oppure attivare il servizio di Telesorveglianza su
                domini ed email per avere un quadro esteso e sempre aggiornato su minacce presenti e future.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className={ ' mb-4 ' }>
            <Accordion.Header className={ 'text-uppercase' }>CHE DIFFERENZA C’è TRA INSTANT CHECK-UP E
                CYBER CHECK-UP?</Accordion.Header>
            <Accordion.Body>
                L’Instant Check-Up fornisce un quadro generico dell’esposizione e serve a maturare una
                consapevolezza del pericolo esistente. Tuttavia non fornisce indicazioni specifiche per
                poter affrontare e scongiurare le minacce, cosa che permette di fare il Cyber-Check-Up,
                elencando tutte le credenziali esposte, le vulnerabilità e le infezioni in maniera specifica
                e dettagliata, accanto a suggerimenti e mitigazioni utili a risolvere le criticità.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" className={ 'mb-4 ' }>
            <Accordion.Header className={ 'text-uppercase' }>PERCHè DOVREI ATTIVARE LA TELESORVEGLIANZA,
                quali vantaggi otterrei?</Accordion.Header>
            <Accordion.Body>
                La Telesorveglianza su domini e credenziali è un servizio unico nel suo genere. Rappresenta
                in pieno la nostra volontà di democratizzare e rendere accessibile la Cyber Threat
                Intelligence, normalmente appannaggio di grandi aziende e governi. Se il Cyber Check-Up
                fornisce un quadro delle minacce attive nel momento presente, la Telesorveglianza lo estende
                nel futuro, mantenendo un controllo attivo sulla tua superficie esterna, informandoti
                tempestivamente in caso di nuove minacce scoperte. Il servizio di Telesorveglianza, include
                un Cyber Check-Up iniziale.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4" className={ ' mb-4 ' }>
            <Accordion.Header className={ 'text-uppercase' }>CHE DIFFERENZA C’è TRA TELESORVEGLIANZA
                DOMINI ED EMAIL?</Accordion.Header>
            <Accordion.Body>
                Nel caso dei domini, verrà monitorata e notificata la presenza di debolezze relative alla
                superficie di attacco e la presenza di infezioni da botnet e virus, oltre alla lista di
                email esposte, connesse al dominio. Nel caso delle email esterne al dominio messe sotto
                Telesorveglianza, verrà segnalata la violazione delle password, la presenza su liste nel
                dark web e l’utilizzo improprio per effettuare accessi non autorizzati.
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
            <Accordion.Header className={ 'text-uppercase' }>QUALI VANTAGGI OTTENGO IN TERMINI DI
                CONFORMITà ALLE REGOLAMENTAZIONI?</Accordion.Header>
            <Accordion.Body>
                Le nostre soluzioni rappresentano uno strumento di supporto fondamentale per la conformità
                alle stringenti regolamentazioni come la NIS2, ma rendono anche le aziende pronte e
                competitive per affrontare i rigorosi standard del nuovo Codice degli Appalti, tutto mentre
                si riduce significativamente il rischio di incorrere in sanzioni o penalizzazioni.
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>)
}