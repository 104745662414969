export const INCLUDE_COORDINATOR = 'INCLUDE_COORDINATOR';
export const INCLUDE_CENTER = 'INCLUDE_CENTER';
export const INCLUDE_MASTERCENTER = 'INCLUDE_MASTERCENTER';
export const INCLUDE_AMBASSADORS_COUNT = 'INCLUDE_AMBASSADORS_COUNT';
export const INCLUDE_CENTERS_COUNT = 'INCLUDE_CENTERS_COUNT';
export const INCLUDE_AMBASSADOR = 'INCLUDE_AMBASSADOR';
export const INCLUDE_ORDER = 'INCLUDE_ORDER';
export const INCLUDE_USER = 'INCLUDE_USER';
export const INCLUDE_PAYMENT_TYPES = 'INCLUDE_PAYMENT_TYPES';
export const INCLUDE_INVOICE_INFORMATION = 'INCLUDE_INVOICE_INFORMATION'


export function calculateQueryString(query: object) {
    let queryStrArr: string[] = [];
    Object.keys(query).forEach((k) => {
        const value = query[k];
        if (Array.isArray(value)) {
            for (var subvalue of value) {
                queryStrArr.push(`${k}=${subvalue}`);
            }
        } else {
            queryStrArr.push(`${k}=${value}`);
        }
    });
    return queryStrArr.length > 0 ? "?" + queryStrArr.join("&") : "";
}

export function calculateIncludes(includes: string[], queryStrPrefix: string) {
    let includesArr: string[] = [];
    for (let include of includes) {
        includesArr.push(`includes=${include}`);
    }
    return includesArr.length > 0 && queryStrPrefix
        ? queryStrPrefix + includesArr.join("&")
        : includesArr.join("&");
}

export function getModuleApiPath(user) {
    return user &&
        user[process.env.AUTH0_AUDIENCE + "/roles"] &&
        user[process.env.AUTH0_AUDIENCE + "/roles"][0] === "Customer"
        ? "/api/store"
        : "/api/admin";
}


