import React from 'react';
import {Button, Col, Tooltip, Navbar,Nav} from 'react-bootstrap';
import {Header} from '.';
import canIDoIt from '../helpers/PermissionHelper';
import {useRole} from '../helpers/RoleProvider';
import FeatherIcon from "feather-icons-react";
import Link from "next/link";


export function HeaderButton({onClick, children, ...btnProps}) {
    return <Button onClick={onClick} {...btnProps}>
        {children}
    </Button>
}

export function HeaderLinkButton({linkPath, linkPathParams, children, ...btnProps}) {
    const {role, isSuperadmin} = useRole()


    return (
        role && canIDoIt(role, linkPath, isSuperadmin) &&
        <Link href={{pathname: linkPath, query:{...linkPathParams}}}>
            <HeaderButton {...btnProps}>
                {children}
            </HeaderButton>
        </Link>
    )
}

export function HeaderEditButton({linkPath, linkPathParams, ignoreFeatureFlags = false, children, ...btnProps}) {
    const enabledEdit = ignoreFeatureFlags || (process.env.NEXT_PUBLIC_ENABLE_EDIT?.toLowerCase() === 'true')

    const renderTooltipEdit = (props) => (
        <Tooltip id="button-tooltip" {...props}>
            Modifica
        </Tooltip>
    );

    return (
        enabledEdit &&
        // This overlay is not working properly, should be fixed
        // <OverlayTrigger
        //     placement="bottom"
        //     delay={{show: 250, hide: 400}}
        //     overlay={renderTooltipEdit}
        // >
            <HeaderLinkButton linkPath={linkPath} linkPathParams={linkPathParams} {...btnProps}>
                {children ?? <FeatherIcon icon="edit" size="1.2em"/>}
            </HeaderLinkButton>
        // </OverlayTrigger>
    )
}


export default function HeaderComponent({title, pretitle, button, buttonName, editButton, buttonsConfig, children}) {
    const {role} = useRole()
    const checkStatusHeaderButton = (role) => {
        if (Array.isArray(children)) {
            if (children.some(element => (
                !!element
                && (!element?.props?.linkPath || canIDoIt(role, element?.props?.linkPath)))  // HeaderLinkButton
                && (element?.type !== HeaderEditButton || element?.props?.ignoreFeatureFlags || (process.env.NEXT_PUBLIC_ENABLE_EDIT?.toLowerCase() === 'true')))  // HeaderLinkButton
            ) {
                return true;
            } else {
                return false;
            }
        } else if (children === false) {
            return false;
        } else if ((!children?.props?.linkPath || canIDoIt(role, children?.props?.linkPath)) // HeaderLinkButton
            && (children?.type !== HeaderEditButton || children?.props?.ignoreFeatureFlags || (process.env.NEXT_PUBLIC_ENABLE_EDIT?.toLowerCase() === 'true'))) {
            return true;
        }
    }

    return (
        <Header>
            <Header.Body>
                    <Navbar expand="md" className='bg-transparent border-0'>
                            <Col>
                                <Header.Pretitle as="h6" className="text-capitalize ">{pretitle}</Header.Pretitle>
                                <Header.Title as="h1" className="text-capitalize ">{title}</Header.Title>
                            </Col>
                        {checkStatusHeaderButton(role) &&<Navbar.Toggle aria-controls="header-navbar-nav"><FeatherIcon
                            icon="more-vertical"/></Navbar.Toggle> }
                            <Navbar.Collapse id="header-navbar-nav">
                                <Nav className="me-auto text-end ">
                                    {
                                        Array.isArray(children)
                                            ? children.map((child, i) => child && <Nav.Item xs="auto" key={`headerNav-${i}`} className='ps-3 mb-2'>{child}</Nav.Item>)
                                            : (<Nav.Item  xs="auto" className='ps-3 mb-2'>{children}</Nav.Item >)
                                    }
                                </Nav>
                            </Navbar.Collapse>
                    </Navbar>
            </Header.Body>
        </Header>
    );
}
