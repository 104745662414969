import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useUser } from "@auth0/nextjs-auth0/client";
import Loading from "./Loading";
import UnAuthorized from "./UnAuthorized";
import canIDoIt from "../helpers/PermissionHelper";
import { useRole } from "../helpers/RoleProvider";
import { Topbar } from '../components';
import {useMobileAppView} from "../helpers/MobileAppViewProvider";

export default function RouteWrapper({ Component, pageProps }) {
    const router = useRouter();
    const { role, isSuperadmin, isLoading } = useRole();
    const { isMobileAppView } = useMobileAppView();
    const [topBarView, setTopBarView] = useState(true)
    const { user, error, isLoading: userLoading } = useUser();
    const [authorizedToRoute, setAuthorizedToRoute] = useState(false);

    const urlWithOutBar = [
        '/landing/instant-checkup/[id]',
        '/landing/promo/forbes',
        '/landing/instant-checkup/partners/[tkn]',
        '/landing/instant-checkup/unsubscribe',
        '/landing/verify/processing',
        '/landing/verify/instant-checkup',
        '/landing/verify/checkup',
        '/landing/verify/email',
        '/landing/verify/error',
        '/landing/termscondition',
        '/landing/privacypolicy',
        '/landing/app-download',
        '/beprof/attiva',
        '/landing/signup/[name]',
        '/landing/signup/confirm-identity',
        '/qr/v1'
    ]

    useEffect(() => {
        setTopBarView(!urlWithOutBar.includes(router.pathname))
    }, [router.pathname]);

    useEffect(() => {
        const regexOnlyLogged = /^(public)|(qr)|(beprof)|(error)|(landing\/verify)|(landing\/instant-checkup)|(api\/auth\/logout)\/?.*$/;
        const regexPuntoCyber = /^\/(landing)|(beprof)|(error)|(public)|(qr)|(api\/auth\/logout)\/?.*$/
        const includeLanding = (process.env.NEXT_PUBLIC_FEATURE_ONLY_LOGGED === 'true');
        const regex = includeLanding ? regexOnlyLogged : regexPuntoCyber ;
        if (router && router.asPath) {
            if (!isLoading && !role) {
                if (regex.test(router.asPath)) {
                    setAuthorizedToRoute(true);
                } else {
                    router.push("/api/auth/login");
                }
            } else if (role) {
                if (regex.test(router.asPath)) {
                    setAuthorizedToRoute(true);
                } else {
                    setAuthorizedToRoute(canIDoIt(role, router.pathname, isSuperadmin));
                }
            }
        }
    }, [isLoading, role, isSuperadmin, router, router.pathname]);

    return authorizedToRoute && !role ? (
        <>
            {(!isMobileAppView) && topBarView && <Topbar />}
            <Component { ...pageProps } />
        </>
    ) : !role ? (
        <>
            {/*{(!isMobileAppView) && topBarView && <Topbar />}*/}
            <Loading/>
        </>
    ) : authorizedToRoute ? (
        <>
            {(!isMobileAppView) && topBarView && <Topbar />}
            <Component { ...pageProps } />
        </>
    ) : (
        <>
            {(!isMobileAppView) && topBarView && <Topbar />}
            <UnAuthorized/>
        </>
    );
}
