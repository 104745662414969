import * as Yup from "yup";
export const INVALID_EMAIL_ERROR = 'Email non valida'
export const INVALID_DOMAIN_ERROR = 'Dominio non valido'
export const INVALID_BLACKLIST_DOMAIN = 'Dominio non selezionabile'
export const EXPIRED_AGREEMENT = 'Codice convenzione non più valido'
export const INVALID_AGREEMENT = 'Codice convenzione non valido'
export const NOT_EXISTENT_AGREEMENT = 'Codice convenzione non esistente'
export const REQUIRED_FIELD= 'Campo obbligatorio'

const domainRegExp = /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/
const agreementCodeRegExp = /(^[A-Za-z]{2}\d{4}$)/
const yupMailSchema = Yup.string().email(INVALID_EMAIL_ERROR)
const yupDomainSchema = Yup.string()
    .matches(domainRegExp, INVALID_DOMAIN_ERROR)


const yupStringSchema = Yup.string()
const yupAgreementSchema = Yup.string().matches(agreementCodeRegExp, INVALID_AGREEMENT).nullable()
const yupMailSchemaRequired = yupMailSchema.required(REQUIRED_FIELD)
const yupDomainSchemaRequired = yupDomainSchema.required(REQUIRED_FIELD)
const yupStringSchemaRequired = yupStringSchema.required(REQUIRED_FIELD)

const yupObjectSchema = Yup.object()

export const sectors = [
    {value: 'Commercio', name: 'Commercio'},
    {value: 'Trasporti', name: 'Trasporti'},
    {value: 'ComunicazioneMarketing', name: 'Comunicazione e marketing'},
    {value: 'AgricolturaPesca', name: 'Agricoltura e pesca'},
    {value: 'NoleggioAgenzieViaggio', name: 'Noleggio e agenzie di viaggio'},
    {value: 'Automotive', name: 'Automotive'},
    {value: 'AutomotiveProduttori', name: 'Automotive Produttori'},
    {value: 'ServiziInformaticiDigital', name: 'Servizi informatici e digital'},
    {value: 'Intrattenimento', name: 'Intrattenimento'},
    {value: 'Formazione', name: 'Formazione'},
    {value: 'Manifatturiero', name: 'Manifatturiero'},
    {value: 'AssistenzaSanitaria', name: 'Assistenza sanitaria'},
    {value: 'TurismoOspitalita', name: 'Turismo e ospitalità'},
    {value: 'ServiziFinanziari', name: 'Servizi finanziari'},
    {value: 'ArchitetturaDesign', name: 'Architettura e design'},
    {value: 'ServiziPubblici', name: 'Servizi pubblici'},
    {value: 'ContabilitaGestione', name: 'Contabilità e gestione'},
    {value: 'ServiziLegali', name: 'Servizi legali'},
    {value: 'Bancario', name: 'Bancario'},
    {value: 'Biotecnologia', name: 'Biotecnologia'},
    {value: 'AbbigliamentoAccessori', name: 'Abbigliamento e accessori'},
    {value: 'CostruzioniIngegneria', name: 'Costruzioni e ingegneria'},
    {value: 'Energia', name: 'Energia'},
    {value: 'Sport', name: 'Sport'},
    {value: 'GiocoDAzzardo', name: 'Gioco d\'azzardo'},
    {value: 'Benessere', name: 'Benessere'},
    {value: 'Media', name: 'Media'},
    {value: 'AgenziePubblicitarie', name: 'Agenzie pubblicitarie'},
    {value: 'NoProfit', name: 'No profit'},
    {value: 'IndustriaFarmaceutica', name: 'Farmaceutico - Industria'},
    {value: 'Farmacie', name: 'Farmacie'},
    {value: 'ServiziTecnologici', name: 'Servizi tecnologici'},
    {value: 'Telecomunicazioni', name: 'Telecomunicazioni'},
    {value: 'Logistica', name: 'Logistica'},
    {value: 'AmbienteGestioneRifiuti', name: 'Ambiente e gestione rifiuti'},
    {value: 'Gaming', name: 'Gaming'},
    {value: 'Assicurazioni', name: 'Assicurazioni'},
    {value: 'Artigianato', name: 'Artigianato'},
]
export function formatAssetType(asset_type) {
    switch (asset_type) {
        case 'Email':
            return 'Email'
        case 'Domain':
            return 'Dominio'
        case 'Sector':
            return 'Settore'
        case 'CompanyName':
            return 'Nome Azienda'
    }
}
export function formatStatusScore(level)  {
    switch (level?.toUpperCase()) {
        case "LOW":
            return "basso";
        case "MEDIUM":
            return "medio";
        case "HIGH":
            return "alto";
        case "CRITICAL":
            return "critico";
    }
}
export function formatAssetTypePlaceholder(asset_type) {
    switch (asset_type) {
        case 'Email':
            return 'es: myaccount@domain.it'
        case 'Domain':
            return 'es: acmesrl.it'
        case 'Sector':
            return 'Seleziona Settore'
        case 'CompanyName':
            return 'es: Acme'
    }
}
export function validateInputAssetType(asset_type, areFieldsRequired){
    switch (asset_type) {
        case 'Email':
            return areFieldsRequired ? yupMailSchemaRequired : yupMailSchema
        case 'Domain':
            return areFieldsRequired ? yupDomainSchemaRequired : yupDomainSchema
        case 'Sector':
            return areFieldsRequired ? yupStringSchemaRequired : yupStringSchema
        default:
            return areFieldsRequired ? yupStringSchemaRequired : yupStringSchema
    }
}
export function getSectorByValue(value) {
    const sector = sectors.find(sector => sector.value === value);
    return sector ? sector.name : null;
}

export function getAgreementCodeSchema(){
    return yupAgreementSchema
}

export function instantCheckUpFormatter(value){
    let instantCheckUp = value
    if( value.report_type == 'instant_checkup_event'){
        delete instantCheckUp.botnets_exposure
        delete instantCheckUp.credentials_exposure
        delete instantCheckUp.dump_credentials
        delete instantCheckUp.vulnerabilities
        delete instantCheckUp.overall_risk_score
        delete instantCheckUp.credentials_exposure_score
        delete instantCheckUp.virus_infection_score
        delete instantCheckUp.attack_surface_risk_score
    }
    return instantCheckUp
}