import '../styles/puntocyber.scss'
import Head from 'next/head';
import SSRProvider from 'react-bootstrap/SSRProvider';
import {UserProvider} from '@auth0/nextjs-auth0/client';
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {appWithTranslation} from 'next-i18next'
import {Toaster} from 'react-hot-toast';
import {useEffect} from 'react';
import RouteWrapper from '../components/RouteWrapper';
import {RoleProvider} from '../helpers/RoleProvider';
import {GOOGLE_MAPS_DEFAUL_LANGUAGE} from "../helpers/languageHelper";
import {Antonio, Epilogue} from "next/font/google"
import {MobileAppViewProvider} from "../helpers/MobileAppViewProvider";
import Script from "next/script";

const antonio = Antonio({subsets: ['latin'], weight:['100']})
const epilogue = Epilogue({ subsets: ['latin'], weight:['400','700','800','900']})

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 3000,  // TODO: Make this longer but CAREFULLY test the application and look for possible bugs
            retry: (failureCount, error) => {
                console.error(error.message);
                return failureCount < 2 && (!error?.response?.status || error.response.status >= 500);
            },
            retryDelay: attemptIndex => Math.min(1000 * 5 * (attemptIndex + 1), 10000),
        },
    },
})


function MyApp(props) {
    const initMap = () => { }
    useEffect(() => {
        const onLoad = () => {
            // the Google Maps API has loaded
            // global services instances
            window.googleAutocomplete = new window.google.maps.places.AutocompleteService();
            window.googlePlacesService = new google.maps.places.PlacesService(document.createElement('div'));
        };
        window.initMap = initMap
        // check if the google maps api has loaded
        if (window.google) {
            onLoad();
        } else {
            // if the api hasn't loaded yet, add a callback function to the window object
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&language=${GOOGLE_MAPS_DEFAUL_LANGUAGE}&libraries=places&callback=initMap`;
            script.async = true;
            script.defer = true;
            script.onload = onLoad;
            document.head.appendChild(script);
        }
    }, []);
    return (
        <>
            <QueryClientProvider client={queryClient}>
                <Head>
                    <link rel="shortcut icon" href={ `/img/favicon/puntocyber_favicon.svg` } type="image/x-icon"/>
                    <title>PuntoCyber-Store</title>
                </Head>
                <Script src={ `https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTM_ID}` } />
                <Script id="google-analytics">
                    {`
                            window.dataLayer = window.dataLayer || [];
                            function gtag(){dataLayer.push(arguments);}
                            gtag('js', new Date()); 
                            gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID}', {
                            page_path: window.location.pathname,
                    });
                            
                        `}
                </Script>
                <SSRProvider>
                    <UserProvider>
                        <Toaster position="top-right" containerStyle={{top: 80}} toastOptions={{
                            className: '',
                            style: {
                                padding: '16px',
                            },
                        }} />

                        <RoleProvider>
                            <MobileAppViewProvider>
                                <style jsx global>{ `
                                      :root {
                                        --antonio-font: ${ antonio.style.fontFamily };
                                        --epilogue-font: ${ epilogue.style.fontFamily };
                                      }
                                    ` }</style>
                                <div className={ epilogue.className }>

                                    <RouteWrapper { ...props } />
                                </div>
                            {/*<ReactQueryDevtools />*/}
                            </MobileAppViewProvider>
                        </RoleProvider>

                    </UserProvider>
                </SSRProvider>

            </QueryClientProvider>
        </>
    );
}

export default appWithTranslation(MyApp);
