import React from 'react';

export default function Panel(props) {
    const panelStyles = {
            background: 'rgba(27, 32, 41, 0.28)',
        ...props.style, // Merge external styles with the default styles
    };
    const panelClasses = `border border-2 border-dark ${props.className || ''}`;
    return (
        <div className={panelClasses} style={panelStyles}>
            {props.children}
        </div>
    );
}