import React from "react";

export default function TermsCondition() {
    return(
        <div>
            <div className="ml-4 mr-3">
                <div>
                    <p>I presenti Termini di servizio (il "Contratto") applicabili all’uso dei servizi
                        forniti da DuskRise anche
                        attraverso i Prodotti (i “Servizi”), sono stipulati tra DuskRise e la persona fisica
                        o giuridica che accetta
                        i presenti termini (il "Cliente") e regolano l'accesso e l'utilizzo dei Servizi da
                        parte del Cliente.</p>
                    <p>Il presente Contratto entrerà in vigore dalla settimana successiva alla data in cui
                        il Cliente accetterà
                        l’ordine di DuskRise (“Data di Validità”). Se l'accettazione avviene per conto del
                        Cliente, si dichiara e si
                        garantisce: (i) di avere la piena autorità legale di vincolare il Cliente al
                        presente Contratto; (ii) di
                        avere letto e compreso il presente Contratto e (iii) di accettare il presente
                        Contratto per conto del
                        Cliente.</p>
                </div>
                <div>
                    <h3>1. Fornitura dei Servizi</h3>
                    <h3>1.1 Uso dei Servizi.</h3>
                    <p>
                        Per la Durata del Contratto DuskRise fornirà i Servizi conformemente al Contratto,
                        inclusi gli accordi SLA,
                        e il Cliente può utilizzare tali Servizi e integrarli in qualsiasi sua
                        infrastruttura IT conformemente al
                        Contratto.
                    </p>
                    <h3>1.2 Console di amministrazione.</h3>
                    <p>
                        Il Cliente avrà accesso alla Console di amministrazione, ove prevista nell’ambito
                        dei Servizi, tramite la
                        quale può gestirne l'uso.
                    </p>
                    <h3>1.3 Account.</h3>
                    <p>
                        Il Cliente deve disporre di un Account, ove previsto, per utilizzare i Servizi ed è
                        responsabile delle
                        informazioni che fornisce per la creazione dell'Account, della sicurezza delle
                        proprie password per
                        l'Account e di qualsiasi utilizzo del proprio Account. DuskRise non è tenuta in
                        alcun modo a fornire più di
                        un account al Cliente.
                    </p>
                </div>
                <div>
                    <h3>1.4 Modifiche.</h3>

                    <ul>
                        <li>
                            (a) Ai Servizi. DuskRise ha facoltà di introdurre aggiornamenti ragionevoli ai
                            Servizi. DuskRise
                            informerà preventivamente il Cliente qualora intendesse apportare una modifica
                            sostanziale ai Servizi
                            con un impatto significativo sull'utilizzo dei Servizi da parte del Cliente, a
                            condizione che
                            quest’ultimo abbia un contratto attivo.
                        </li>
                        <li>
                            (b) Al Contratto. DuskRise ha facoltà di apportare modifiche al presente
                            Contratto, compresi i prezzi (e
                            gli eventuali documenti correlati). Salvo laddove diversamente indicato da
                            DuskRise, le modifiche
                            sostanziali al Contratto entrano in vigore 10 giorni dopo la relativa
                            informativa, ad eccezione delle
                            modifiche relative a nuove funzionalità che entreranno in vigore immediatamente.
                            DuskRise fornirà un
                            preavviso di almeno 90 giorni in caso di modifiche sostanzialmente negative a
                            qualsivoglia SLA (i)
                            inviando un'email all'indirizzo email di notifica; (ii) pubblicando un avviso
                            sulla Console di
                            amministrazione; o (iii) pubblicando un avviso sulla pagina web relativa allo
                            SLA applicabile.
                        </li>
                        <li>
                            (c) Termini per il trattamento e la sicurezza dei dati. DukRise potrebbe
                            modificare i “Termini per il
                            trattamento e la sicurezza dei dati” soltanto laddove la modifica sia resa
                            necessaria per rispettare
                            leggi o regolamenti vigenti, ordini del Tribunale o indicazioni emanate dalle
                            autorità competenti, ove
                            la modifica sia espressamente consentita dai “Termini per il trattamento e la
                            sicurezza dei dati” o nel
                            caso in cui tale modifica:

                            <p>
                                (i) sia commercialmente ragionevole;

                                (ii) non comporti un peggioramento generale della sicurezza dei Servizi;

                                (iii) non rimuova o non estenda l'ambito di eventuali limitazioni relative
                                al trattamento dei Dati
                                del Cliente da parte di DuskRise, come descritto nella sezione “Ambito del
                                trattamento dei dati” dei
                                “Termini per il trattamento e la sicurezza dei dati”; o

                                (iv) non abbia un impatto negativo rilevante sui diritti del Cliente
                                previsti dai “Termini per il
                                trattamento e la sicurezza dei dati”.
                            </p>
                            Qualora DuskRise apporti una modifica sostanziale ai “Termini per il trattamento
                            e la sicurezza dei
                            dati” in conformità con la presente Sezione, il Cliente verrà prontamente
                            informato di tale modifica
                            attraverso una comunicazione via posta elettronica contenente tali termini.
                        </li>

                        <li>
                            (d) Interruzione dei Servizi. DuskRise informerà il Cliente almeno 12 mesi prima
                            di interrompere
                            qualsiasi Servizio (o funzionalità rilevante associata), a meno che DuskRise non
                            sostituisca tale
                            Servizio o funzionalità interrotta con un Servizio o funzionalità
                            sostanzialmente simile. Inoltre,
                            DuskRise avviserà il Cliente almeno 12 mesi prima di modificare in modo
                            significativo una funzionalità
                            di DuskRise rivolta al Cliente qualora questo avvenga in modo non
                            retrocompatibile. Niente nella
                            presente Sezione 1.4(d) (Interruzione dei Servizi) limita la facoltà di DuskRise
                            di apportare le
                            modifiche necessarie a rispettare la legge vigente, affrontare un rischio
                            significativo per la sicurezza
                            o evitare un onere economico o tecnico sostanziale.
                        </li>
                    </ul>


                </div>
                <div>
                    <h3>1.5 Prodotti.</h3>
                    <p>DuskRise può mettere a disposizione del Cliente i Prodotti, hardware e Software o
                        report, inclusi software o
                        hardware di terze parti. L'utilizzo di qualsiasi software o hardware da parte del
                        Cliente è soggetto alle
                        disposizioni pertinenti contenute nei Termini specifici del Servizio ove previsti.
                    </p>
                </div>
                <div>
                    <h3>1.5 Termini specifici del Servizio e Termini per il trattamento e la sicurezza dei
                        dati.</h3>
                    <p>I “Termini specifici del Servizio” e i “Termini per il trattamento e la sicurezza dei
                        dati” sono inclusi
                        tramite questo
                        riferimento nel Contratto.
                    </p>
                </div>
                <div>
                    <h3>2. Termini di pagamento</h3>

                    <ul>
                        <li>
                            2.1 Fatturazione online. Il Cliente dovrà pagare i corrispettivi contestualmente
                            alla firma e
                            accettazione
                            dell’ordine o, ove previsto, alla scadenza annuale per poter continuare ad
                            utilizzare i Servizi. A
                            fronte del
                            pagamento DuskRise invierà la relativa fattura. I pagamenti effettuati tramite
                            bonifico bancario devono
                            includere i riferimenti bancari forniti da DuskRise.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>2.2 Imposte</h3>

                    <ul>
                        <li>
                            (a) Il Cliente è responsabile del pagamento di eventuali Imposte e pagherà a
                            DuskRise i costi dei
                            Servizi senza
                            alcuna facoltà di dedurre importi per tali eventuali Imposte. Qualora per
                            DuskRise sussista l&#39;obbligo
                            legale di
                            incassare o pagare tali Imposte, il relativo importo dovrà essere fatturato al
                            Cliente e il Cliente
                            pagherà tali
                            Imposte a DuskRise in aggiunta ai corrispettivi, a meno che il Cliente non
                            fornisca tempestivamente a
                            DuskRise un certificato valido di esenzione fiscale.
                        </li>

                        <li>
                            (b) Se richiesto dalla legge vigente, il Cliente fornirà a DuskRise i dati
                            fiscali identificativi
                            applicabili che
                            DuskRise potrebbe richiedere per garantirne la conformità alle normative fiscali
                            vigenti e alle autorità
                            fiscali
                            competenti nelle giurisdizioni applicabili. Il Cliente sarà tenuto a pagare (o
                            rimborsare a DuskRise)
                            eventuali
                            tasse, interessi, sanzioni o multe derivanti da qualsiasi dichiarazione errata
                            del Cliente.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>2.3 Controversie relative alle fatture e rimborsi.</h3>
                    <p>Nei limiti consentiti dalla legge, il Cliente rinuncia a qualsiasi rivendicazione
                        relativa ai corrispettivi a
                        meno che tale rivendicazione non sia prodotta entro 30 giorni dalla data della
                        fattura. Gli eventuali
                        rimborsi (se presenti)
                        sono a esclusiva discrezione di DuskRise e saranno esclusivamente sotto forma di
                        credito per i Servizi.
                        Nessuna parte del presente Contratto impone a DuskRise di estendere il credito a una
                        parte.</p>
                </div>
                <div>
                    <h3>2.4 Pagamenti insoluti; sospensione.</h3>
                    <p>Nei casi di Servizi che prevedono il rinnovo annuale, i ritardi di pagamento saranno
                        soggetti a interessi al
                        tasso
                        dell&#39;1,5% al mese (o al tasso massimo consentito dalla legge, se inferiore) a
                        partire dalla data di
                        scadenza del
                        pagamento fino al completo pagamento. Il Cliente sarà responsabile di tutte le spese
                        (incluse le spese
                        legali)
                        ragionevolmente sostenute da DuskRise per la riscossione di tali importi insoluti.
                        Se il Cliente è in
                        ritardo nel
                        pagamento dei Servizi, DuskRise potrà sospendere i Servizi o risolvere il Contratto
                        per inadempimento come
                        stabilito dalla Sezione 10.2 (Risoluzione per inadempimento).
                    </p>
                </div>
                <div>
                    <h3>2.5 Nessun numero di ordine di acquisto richiesto.</h3>
                    <p>Il Cliente è obbligato a pagare tutte i corrispettivi applicabili senza che DuskRise
                        debba fornire un numero
                        di
                        ordine di acquisto sulla fattura di DuskRise (o altri documenti).
                    </p>
                </div>
                <div>
                    <h3>3. Obblighi del Cliente</h3>
                    <h3>3.1 Conformità.</h3>
                    <p>Il Cliente è tenuto a (a) garantire che il proprio utilizzo dei Servizi (incluso
                        l&#39;utilizzo da parte dei
                        propri Utenti
                        finali) rispetti le disposizioni del presente Contratto, (b) compiere ogni sforzo
                        ragionevole per impedire e
                        interrompere qualsiasi accesso o uso non autorizzato dei Servizi o qualsiasi altra
                        violazione della propria
                        sicurezza che possa pregiudicare DuskRise o possa avere un qualunque impatto ai
                        sensi del presente
                        Contratto e (c) informare tempestivamente DuskRise di qualsiasi utilizzo o accesso
                        non autorizzato ai
                        Servizi,
                        all&#39;Account o alla password del Cliente di cui il Cliente venga a conoscenza.
                        DuskRise si riserva il
                        diritto di
                        indagare su eventuali violazioni da parte del Cliente.</p>
                    <h3>3.2 Privacy.</h3>
                    <p>
                        Con riferimento alle attività oggetto del presente Contratto, a seguito della
                        sottoscrizione per
                        accettazione dello
                        stesso, il Cliente si impegna ad osservare tutti gli obblighi derivanti dalla
                        normativa in materia di
                        Protezione dei
                        Dati Personali, in particolare il Regolamento UE n. 2016/679 (“GDPR”) nonché ogni
                        ulteriore norma dettata a
                        livello nazionale o sovranazionale in materia di protezione dei dati personali, in
                        particolare con
                        riferimento ai
                        provvedimenti emanati dall’Autorità Garante per la Protezione dei Dati Personali
                        ovvero del Comitato Europeo
                        per la Protezione dei Dati.
                        Il Cliente è responsabile per (e dichiara di aver ottenuto, ove necessario) tutti i
                        consensi e le
                        autorizzazioni che
                        permettono (a) l&#39;utilizzo e la ricezione dei Servizi da parte del Cliente e (b)
                        l&#39;accesso, l&#39;archiviazione
                        e il
                        trattamento da parte di DuskRise dei dati relativi al Cliente ai sensi del presente
                        Contratto, con
                        particolare
                        riferimento all’eventuale trattamento, da parte di DuskRise, di dati personali dei
                        dipendenti del Cliente.
                        In particolare, il Cliente dichiara di aver debitamente informato i propri
                        dipendenti ed eventuali altri
                        soggetti
                        interessati dall’esecuzione dei Servizi oggetto del presente Contratto, ed acquisito
                        il relativo consenso,
                        ove
                        necessario, manlevando espressamente DuskRise da ogni responsabilità derivante
                        dall’illegittimo
                        trattamento”.
                        Il Cliente è responsabile per (e dichiara di aver ottenuto) tutti i consensi e le
                        notifiche necessari che
                        permettono
                        (a) l&#39;utilizzo e la ricezione dei Servizi da parte del Cliente e (b)
                        l&#39;accesso, l&#39;archiviazione
                        e il trattamento da parte
                        di DuskRise dei dati forniti dal Cliente ai sensi del presente Contratto.
                    </p>
                    <h3>3.3 Limitazioni.</h3>
                    <p>
                        Il Cliente non potrà e non consentirà agli Utenti finali di (a) copiare, modificare
                        o creare un&#39;opera
                        derivata dai
                        Servizi; (b) retro-ingegnerizzare, decompilare, tradurre, disassemblare o tentare in
                        altro modo di estrarre
                        una
                        parte o la totalità del codice sorgente dei Servizi; (c) vendere, rivendere,
                        concedere in licenza,
                        trasferire o
                        distribuire una parte o la totalità dei Servizi; (d) accedere o utilizzare i Servizi
                        (i) per Attività ad
                        alto rischio; (ii) in
                        violazione dei brevetti depositati; (iii) in modo da evitare di incorrere nel
                        pagamento dei corrispettivi o
                        per
                        aggirare i limiti o modalità d&#39;uso specifici del Servizio; (vi) in modo da
                        violare o causare la
                        violazione delle leggi
                        sul controllo delle esportazioni; o (vii) per trasmettere, memorizzare o elaborare
                        informazioni sensibili
                        soggette
                        alle normative dei diversi paesi; (e) utilizzare i Servizi in qualsiasi modo che
                        violi qualsiasi legge o
                        regolamento
                        locale, nazionale o internazionale applicabile; oppure (f) intraprendere
                        intenzionalmente qualsiasi azione
                        che
                        possa danneggiare, impedire il normale funzionamento dei Servizi o imporre un carico
                        irragionevole o
                        sproporzionatamente elevato o che possa essere ragionevolmente interpretata come
                        interferenza con il
                        Prodotto.
                    </p>
                    <h3>3.4 Documentazione.</h3>
                    <p>DuskRise può fornire Documentazione relativa all&#39;utilizzo dei Servizi da parte
                        del Cliente.</p>
                </div>
                <div>
                    <h3>4. Sospensione</h3>
                    <h3>4.1 Violazioni dei termini di utilizzo.</h3>
                    <p>Qualora DuskRise venga a conoscenza del fatto che l&#39;utilizzo dei Servizi da parte
                        del Cliente o di
                        qualsiasi
                        Utente finale del Cliente violi i termini di utilizzo, DuskRise informerà il Cliente
                        della violazione
                        chiedendogli di
                        rimediare a tale violazione. Qualora il Cliente non riesca a risolvere la violazione
                        entro 24 ore dalla
                        richiesta di
                        DuskRise, DuskRise potrà sospendere totalmente o parzialmente l&#39;utilizzo dei
                        Servizi da parte del
                        Cliente
                        finché la violazione non sarà risolta riservandosi il diritto di adire per le vie
                        legali per eventuali danni
                        causati da
                        tale violazione.
                    </p>
                    <h3>4.2 Altre sospensioni</h3>
                    <p>In deroga alla Sezione 4.1 (Violazioni dei termini di utilizzo), DuskRise può
                        sospendere immediatamente,
                        totalmente o in parte, l&#39;utilizzo dei Servizi da parte del Cliente qualora (a)
                        DuskRise ritenga che l&#39;utilizzo
                        dei
                        Servizi da parte del Cliente o di qualsiasi Utente finale del Cliente possa avere un
                        impatto negativo sui
                        Servizi,
                        sull&#39;utilizzo dei Servizi da parte di altri clienti o dei loro utenti finali o
                        sulla rete o sui server
                        DuskRise utilizzati
                        per fornire i Servizi; (b) si sospetti l&#39;accesso non autorizzato di terze parti
                        ai Servizi; (c) DuskRise
                        ritenga di
                        essere tenuta a sospendere immediatamente l&#39;utilizzo dei Servizi per rispettare
                        la legge vigente; o (d)
                        il Cliente
                        violi la Sezione 3.3 (Limitazioni). DuskRise revocherà tale sospensione non appena
                        le circostanze causa
                        della
                        sospensione saranno state risolte. Su richiesta del Cliente, a meno che non sia
                        proibito dalla legge
                        vigente,
                        DuskRise comunicherà al Cliente le basi della sospensione non appena ragionevolmente
                        possibile.</p>
                </div>
                <div>
                    <h3>5. Diritti di proprietà intellettuale</h3>
                    <p>Salvo quanto espressamente specificato nel presente Contratto, il Contratto non
                        concede ad alcuna parte
                        alcun diritto, implicito o di altro tipo, relativo ai contenuti o alla proprietà
                        intellettuale dell&#39;altra
                        parte. Per ciò che
                        concerne le parti, il Cliente conserva tutti i Diritti di proprietà intellettuale
                        dei propri dati e delle
                        applicazioni del
                        Cliente e DuskRise conserva tutti i Diritti di proprietà intellettuale dei Servizi
                        (ivi inclusi i Prodotti)
                        e del
                        Software.</p>
                </div>
                <div>
                    <h3>6. Diritti di proprietà intellettuale in caso di sviluppi software</h3>

                    <ul>
                        <li>
                            6.1 Nel caso in cui il Cliente richieda a DuskRise di sviluppare alcune
                            funzionalità aggiuntive su
                            proprio requisito e specifica, al fine di implementare le nuove funzionalità ai
                            Servizi offerti
                            nell’esecuzione del
                            Contratto, Duskrise potrà utilizzare prodotti, materiali, strumenti e
                            metodologie quali, a titolo
                            esemplificativo e
                            non esaustivo, programmi e librerie che sono (e resteranno) di proprietà della
                            stessa Duskrise fatti
                            salvi
                            eventuali diritti di licenza che saranno in ogni caso riconosciuti al Cliente
                            per tutto il periodo di
                            durata del
                            rapporto contrattuale in quanto funzionali alla corretta operatività dei
                            Servizi.
                        </li>

                        <li>
                            6.2 Lo sviluppo del software aggiuntivo costituirà, invece, un prodotto
                            personalizzato e sviluppato ad
                            hoc da
                            Duskrise sulla base delle indicazioni e specifiche tecniche fornite dal Cliente.
                            I diritti di proprietà
                            intellettuale
                            relativi al predetto sviluppo software e solo per le componenti sviluppate su
                            specifica del Cliente
                            saranno
                            invece di proprietà del Cliente.
                        </li>

                        <li>
                            6.3 Laddove nelle fasi esecutive di sviluppo del software aggiuntivo, vengano
                            impiegati prodotti,
                            materiali,
                            strumenti e metodologie medio tempore realizzati da Duskrise questi sono e
                            resteranno di proprietà di
                            DuskRise. In tali ipotesi, sarà cura di Duskrise indicare e comunicare al
                            Cliente il dettaglio di tali
                            ulteriori
                            prodotti, materiali, strumenti e metodologie affinché risulti chiaro il
                            permanere in capo a DuskRise dei
                            relativi
                            diritti di proprietà intellettuale.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>7. Protezione dei dati del Cliente</h3>
                    <h3>7.1 Protezione dei dati del Cliente.</h3>
                    <p>DuskRise accederà o utilizzerà i Dati del Cliente solo per fornire i Servizi e i
                        servizi di assistenza
                        tecnica al
                        Cliente. DuskRise ha implementato e manterrà in vigore misure di salvaguardia
                        amministrative, fisiche e
                        tecniche per proteggere i Dati del Cliente, come ulteriormente descritto nei
                        “Termini per il trattamento e
                        la
                        sicurezza dei dati”.
                        DuskRise tratterà i dati personali relativi a soggetti operanti nell’ambito
                        dell’organizzazione aziendale
                        del Cliente
                        solo per fornire i Servizi e i servizi di assistenza tecnica al Cliente. DuskRise ha
                        implementato e manterrà
                        in
                        vigore adeguate misure di sicurezza, sia tecniche che organizzative, per proteggere
                        i dati personali da
                        eventuali
                        distruzioni o perdite di natura illecita o accidentale, danni, alterazioni,
                        divulgazioni o accessi non
                        autorizzati, ed
                        in particolare, laddove il trattamento comporta trasmissioni di dati su una rete, da
                        qualsiasi altra forma
                        illecita di
                        trattamento, come ulteriormente descritto nei “Termini per il trattamento e la
                        sicurezza dei dati”</p>
                    <h3>7.2 Feedback del Cliente.</h3>
                    <p>Il Cliente può, a propria discrezione, fornire feedback o suggerimenti a DuskRise in
                        merito ai Servizi
                        (&quot;Feedback&quot;). Se il Cliente fornisce un Feedback, DuskRise potrà
                        utilizzare tale Feedback senza
                        limitazioni e
                        senza obblighi nei confronti del Cliente.</p>
                    <h3>7.3 Analisi comparativa.</h3>
                    <p>Il Cliente può condurre prove di analisi comparativa dei Servizi (ciascuno
                        un &quot;Test&quot;). Il Cliente
                        può divulgare
                        pubblicamente i risultati di tali Test solo se (a) ottiene il previo consenso
                        scritto di DuskRise, (b)
                        fornisce a
                        DuskRise tutte le informazioni necessarie per replicare i Test. Fermo restando
                        quanto sopra, il Cliente non
                        potrà intraprendere nessuna delle seguenti operazioni per conto di un fornitore di
                        servizi o prodotti
                        analoghi o
                        simili a quelli di DuskRise senza previo consenso scritto da parte di DuskRise: (i)
                        condurre (direttamente o
                        tramite terze parti) un test dei Servizi o (ii) divulgare i risultati di tali
                        test.</p>
                </div>
                <div>
                    <h3>8. Servizi di assistenza tecnica.</h3>
                    <h3>8.1 Da parte del Cliente.</h3>
                    <p>Laddove possibile e applicabile il Cliente è responsabile del supporto tecnico di
                        primo livello delle proprie
                        Applicazioni e dei propri Progetti e di eventuali richieste relative alla fase di
                        installazione e primo
                        utilizzo dei
                        Servizi DuskRise. Per tale supporto sarà cura di DuskRise fornire apposita
                        formazione in modalità “train to
                        the
                        trainer” agli operatori di supporto tecnico di primo livello del Cliente ed un
                        knowledge base contenente
                        casi e modi di risoluzione dei problemi comunemente riscontrabili nelle fasi di
                        adozione ed utilizzo dei
                        Servizi
                        DuskRise.</p>
                    <h3>8.2 Da parte di DuskRise.</h3>
                    <p>Ove applicabile per i Servizi, subordinatamente al pagamento dei corrispettivi
                        contrattuali applicabili,
                        DuskRise
                        fornirà servizi di assistenza tecnica al Cliente di secondo e terzo livello per i 12
                        mesi successivi
                        all’attivazione
                        del Servizio, in conformità con le “Linee guida sui servizi di assistenza
                        tecnica”.</p>
                </div>
                <div>
                    <h3>9. Informazioni riservate.</h3>
                    <h3>9.1 Obblighi.</h3>
                    <p>
                        Il destinatario utilizzerà le Informazioni riservate della parte divulgante solo per
                        esercitare i propri
                        diritti e
                        adempiere ai propri obblighi del presente Contratto, adottando un livello di
                        attenzione ragionevole per
                        salvaguardarne la riservatezza. Il destinatario delle Informazioni riservate può
                        divulgarle solo a
                        Consociate,
                        dipendenti, agenti o consulenti professionali che devono esserne a conoscenza e che
                        abbiano accettato per
                        iscritto di mantenerne la riservatezza (o che siano altrimenti vincolati a
                        mantenerne la riservatezza, nel
                        caso di
                        consulenti professionali). Il destinatario si assicurerà che tali persone fisiche e
                        giuridiche utilizzino le
                        Informazioni riservate ricevute solo per esercitare i diritti e adempiere agli
                        obblighi previsti dal
                        presente
                        Contratto.
                    </p>
                    <h3>9.2 Divulgazione obbligatoria.</h3>
                    <p>
                        Nonostante eventuali indicazioni contrarie contenute nel presente Contratto, il
                        destinatario può anche
                        divulgare
                        Informazioni riservate nella misura richiesta dalla legge vigente, a condizione che
                        il destinatario compia
                        ogni
                        ragionevole sforzo commerciale per (a) informare tempestivamente l&#39;altra parte
                        di tale divulgazione
                        prima della
                        divulgazione stessa e (b) soddisfare le ragionevoli richieste dell&#39;altra parte
                        riguardo ai propri sforzi
                        per opporsi
                        alla divulgazione. Fatte salve le precedenti disposizioni, le sottosezioni (a) e (b)
                        sopra riportate non si
                        applicheranno se il destinatario stabilisce che il rispetto di (a) e (b) potrebbe
                        (i) comportare una
                        violazione della
                        legge; (ii) ostacolare un&#39;indagine governativa o (iii) causare la morte o gravi
                        lesioni fisiche a un
                        privato.
                    </p>
                </div>
                <div>
                    <h3>10. Durata e risoluzione.</h3>
                    <h3>10.1 Durata contrattuale.</h3>
                    <p>La &quot;Durata&quot; del presente Contratto decorrerà dalla Data di validità e
                        rimarrà in vigore per un
                        totale di 36 mesi
                        per tutti i Servizi.</p>
                    <h3>10.2 Risoluzione per inadempimento.</h3>
                    <p>DuskRise può risolvere il Contratto se il Cliente (a) è inadempiente rispetto agli
                        impegni assunti con il
                        Contratto
                        e non pone rimedio a tale violazione entro 30 giorni dalla ricezione della notifica
                        scritta o (b) sospende
                        la
                        propria attività.</p>
                    <h3>10.3 Recesso.</h3>
                    <ol type="A">
                        <li>Duskrise avrà facoltà di recedere dal presente Contratto dandone comunicazione
                            scritta con un preavviso
                            di
                            30 giorni qualora il Cliente diventi oggetto di procedure di insolvenza e le
                            procedure non vengono
                            respinte
                            entro 90 giorni.
                        </li>
                        <li>Il Cliente avrà altresì il diritto di recedere dal Contratto, senza alcuna
                            penalità, eccezion fatta per
                            l’obbligo di
                            restituzione delle componenti hardware in comodato, ove previste, e senza dover
                            specificare il motivo,
                            entro
                            14 giorni dalla richiesta dell&#39;ordine. Il Cliente può esercitare il suo
                            diritto di recesso, entro il
                            termine, mediante
                            comunicazione scritta via Posta Elettronica Certificata (duskrise@pec.it),
                            esprimendo la sua volontà di
                            recedere dal contratto.
                        </li>
                        <li>Il Cliente avrà altresì facoltà di recedere dal presente Contratto dandone
                            comunicazione scritta con un
                            preavviso di 30 giorni prima del dodicesimo mese o con un preavviso di 30 giorni
                            prima del
                            ventiquattresimo mese, mediante comunicazione scritta via Posta Elettronica
                            Certificata
                            (duskrise@pec.it), che formalizzi la
                            richiesta di recesso.
                        </li>
                    </ol>
                    <p>
                        Resta inteso che il diritto di recesso verrà riconosciuto se e solo se le componenti
                        hardware in comodato,
                        ove
                        previste nel Servizio, vengano restituite al più entro 14 giorni dalla richiesta di
                        recesso; oppure in caso
                        di
                        disdetta al entro 5 giorni prima del dodicesimo mese o entro 5 giorni prima del
                        ventiquattresimo mese presso
                        il Punto Cyber in cui si è effettuato l’ordine. In ogni caso qualora il recesso non
                        venga formalizzato nei
                        termini sopra previsti, DuskRise continuerà ad addebitare gli importi previsti e ad
                        erogare il Servizio.
                    </p>
                    <h3>10.4 Effetto della risoluzione e del recesso.</h3>
                    <p>Se il Contratto viene risolto, tutti i diritti e l&#39;accesso ai Servizi (incluso
                        l&#39;accesso ai Dati del
                        Cliente, se inerenti)
                        termineranno, salvo laddove diversamente indicato nel presente Contratto.</p>
                </div>

                <div>
                    <h3>11. Pubblicità.</h3>
                    <p>Se il Cliente desidera utilizzare gli Elementi distintivi del brand DuskRise in
                        relazione al suo utilizzo dei
                        Servizi,
                        deve ottenere l&#39;autorizzazione scritta da DuskRise. DuskRise può includere il
                        nome o gli Elementi
                        distintivi del
                        brand del Cliente in un elenco di clienti DuskRise online o in materiali
                        pubblicitari. DuskRise può anche
                        fare
                        riferimento verbalmente al Cliente come cliente dei Servizi. L&#39;eventuale
                        utilizzo degli Elementi
                        distintivi del
                        brand di una parte andrà a vantaggio della parte che detiene i Diritti di proprietà
                        intellettuale
                        relativamente a tali
                        Elementi distintivi del brand. Ciascuna parte potrà revocare all&#39;altra parte il
                        diritto di utilizzare
                        gli Elementi
                        distintivi del brand conformemente alla presente Sezione, previa notifica scritta
                        all&#39;altra parte e
                        concedendo un
                        periodo ragionevole per interrompere l&#39;utilizzo.</p>
                </div>
                <div>
                    <h3>12. Dichiarazioni e garanzie.</h3>
                    <p>Ciascuna parte dichiara e garantisce (a) di avere pieno potere legale per stipulare e
                        rendere esecutivo il
                        presente Contratto e (b) che rispetterà tutte le leggi e i regolamenti vigenti per
                        la sua esecuzione,
                        ricezione e
                        uso applicabili all&#39;uso dei Servizi, a seconda dei casi.</p>
                </div>
                <div>
                    <h3>13. Disclaimer.</h3>
                    <p>Salvo laddove espressamente specificato nel Contratto, nella misura massima
                        consentita dalla legge vigente,
                        DuskRise (a) non offre altre garanzie di alcun tipo, siano queste esplicite,
                        implicite, di legge o di altro
                        genere,
                        incluse garanzie di commerciabilità, di qualità soddisfacente, di idoneità a uno
                        scopo particolare o
                        conformità
                        con ogni descrizione di servizi e prodotti fornita da Duskrise, di non violazione o
                        di utilizzo privo di
                        errori e
                        ininterrotto dei Servizi o del Software e (b) non risponde di contenuti o
                        informazioni resi accessibili
                        mediante i
                        Servizi.</p>
                </div>
                <div>
                    <h3>14. Limitazione di responsabilità.</h3>
                    <h3>14.1 Limitazione della responsabilità indiretta.</h3>
                    <p>Nella misura consentita dalla legge vigente e in conformità con la Sezione 14.3
                        (Responsabilità illimitate),
                        nessuna delle parti avrà responsabilità (su base contrattuale, extracontrattuale o
                        altrimenti) per eventuali
                        (a)
                        danni indiretti, consequenziali, speciali, incidentali o punitivi o (b) perdite di
                        ricavi, profitti,
                        risparmi o reputazione
                        (a prescindere dal fatto che tali perdite rientrino o non rientrino nella
                        valutazione delle parti alla data
                        del
                        Contratto).</p>
                    <h3>14.2 Limitazione dell&#39;importo di responsabilità.</h3>
                    <p>La responsabilità complessiva totale di ciascuna parte per i danni derivanti da o
                        relativi al Contratto è
                        limitata ai corrispettivi dovuti dal Cliente per i 12 mesi di Servizio.</p>
                    <h3>14.3 Responsabilità illimitate.</h3>
                    <p>Nulla nel presente Contratto esclude o limita la responsabilità di ciascuna parte in
                        caso di:

                        (a) dolo o colpa grave;

                        (b) violazione dei diritti di proprietà intellettuale dell&#39;altra parte;

                        (c) violazione degli obblighi di pagamento ai sensi del Contratto; o

                        (d) questioni per cui non è possibile escludere o limitare la responsabilità ai
                        sensi della legge vigente.
                    </p>
                </div>
                <div>
                    <h3>15. Disposizioni varie</h3>
                    <h3>15.1 Notifiche.</h3>
                    <p>DuskRise comunicherà al Cliente le notifiche ai sensi del Contratto inviando
                        un&#39;email all&#39;indirizzo
                        di posta
                        elettronica di notifica comunicato al momento dell’attivazione del Servizio. Il
                        Cliente trasmetterà le
                        notifiche a
                        DuskRise ai sensi del Contratto inviando un&#39;email all&#39;indirizzo comunicato
                        al Cliente al momento
                        dell’attivazione
                        del Servizio. La notifica sarà considerata ricevuta nel momento in cui l&#39;email
                        viene ricevuta come
                        verificato da
                        ricevuta scritta o automatica o da log elettronico (a seconda dei casi). Il Cliente
                        sarà ritenuto
                        responsabile di
                        mantenere aggiornato il proprio indirizzo email di notifica per tutta la durata del
                        Contratto. Ai sensi del
                        presente
                        Contratto, le parti possono utilizzare le email per soddisfare i requisiti di
                        approvazione e consenso
                        scritti.</p>
                    <h3>15.2 Trasferimento.</h3>
                    <p>Nessuna delle parti può cedere in tutto o in parte il presente Contratto senza il
                        consenso scritto dell&#39;altra
                        parte,
                        fatta eccezione per una Consociata, laddove (a) detta Consociata abbia accettato per
                        iscritto di essere
                        vincolata dai termini del presente Contratto e (b) la parte cedente rimanga
                        responsabile degli obblighi
                        derivanti
                        dal presente Contratto prima della cessione e abbia comunicato all&#39;altra parte
                        tale trasferimento.
                        Qualsiasi altro
                        tentativo di cessione sarà considerato nullo. Qualora il Cliente ceda in tutto o in
                        parte il presente
                        Contratto a
                        una Consociata in un&#39;altra giurisdizione e tale trasferimento comporti una
                        migliore gestione del
                        contratto da
                        parte di altra società del gruppo DuskRise contraente, il presente Contratto potrà
                        essere automaticamente
                        trasferito a detta società del gruppo DuskRise.</p>
                    <h3>15.3 Passaggio del controllo.</h3>
                    <p>Ove applicabile, se una parte subisce un passaggio di controllo (ad esempio, tramite
                        acquisto o vendita di
                        azioni, fusione o altra forma di transazione societaria), la parte in questione
                        dovrà darne comunicazione
                        scritta
                        all&#39;altra parte entro 30 giorni dal passaggio di controllo.</p>
                    <h3>15.4 Forza maggiore.</h3>
                    <p>Nessuna parte sarà ritenuta responsabile in caso di inadempimento o adempimento
                        tardivo dei propri obblighi
                        se causato da circostanze che esulano dal ragionevole controllo della parte
                        inadempiente, inclusi calamità
                        naturali, atti di guerra o terrorismo, sommosse, sciopero di terzi, azioni
                        governative e disordini su
                        Internet.</p>
                    <h3>15.5 Cessione mediante subcontratto.</h3>
                    <p>DuskRise può cedere mediante subcontratto gli obblighi ai sensi del presente
                        Contratto, ma rimarrà
                        responsabile verso il Cliente di tutti gli obblighi ceduti mediante
                        subcontratto.</p>
                    <h3>15.6 Mancata instaurazione di un rapporto di agenzia.</h3>
                    <p>Il presente Contratto non crea alcun rapporto di agenzia, di partnership o di joint
                        venture tra le parti.</p>
                    <h3>15.7 Esclusione di rinuncia.</h3>
                    <p>L&#39;omissione o il ritardo nell&#39;esercizio di un qualsiasi diritto previsto dal
                        presente Contratto non
                        costituirà una
                        rinuncia ad alcun diritto da parte di nessuna delle parti.</p>
                    <h3>15.8 Clausola di salvaguardia.</h3>
                    <p>Nel caso in cui una qualsiasi disposizione del presente Contratto dovesse risultare
                        non valida, illegale o
                        non
                        azionabile, le disposizioni rimanenti rimarranno comunque in vigore nella misura in
                        cui tale disposizione
                        non sia
                        considerata essenziale ai fini del presente Contratto.</p>
                    <h3>15.9 Emendamenti.</h3>
                    <p>Ad eccezione di quanto indicato nella sezione 1.4 (b) (Modifiche: Al Contratto) o (c)
                        (Modifiche: Ai Termini
                        per il
                        trattamento e la sicurezza dei dati), eventuali modifiche devono essere in forma
                        scritta, firmate da
                        entrambe le
                        parti e devono dichiarare espressamente che si tratta di emendamenti al presente
                        Contratto.</p>
                    <h3>15.10 Sopravvivenza.</h3>
                    <p>Le seguenti sezioni rimarranno valide anche dopo la scadenza o la risoluzione del
                        presente Contratto: Sezione
                        2 (Termini di pagamento), Sezione 5 (Diritti di proprietà intellettuale), Sezione 6
                        (Diritti di proprietà
                        intellettuale
                        in caso di sviluppi software); Protezione dei dati del Cliente; Feedback; Analisi
                        comparativa), Sezione 9
                        (Informazioni riservate), Sezione 10.3 (Effetto della risoluzione e del recesso),
                        Sezione 13 (Disclaimer),
                        Sezione 14 (Limitazione di responsabilità) e Sezione 15 (Disposizioni varie).</p>
                    <h3>15.11 Intero Contratto.</h3>
                    <p>Il presente Contratto illustra tutti i termini concordati dalle parti in relazione al
                        suo oggetto e
                        sostituisce tutti gli
                        altri accordi e intese tra le parti relativi allo stesso oggetto. Con la
                        sottoscrizione del presente
                        Contratto,
                        nessuna delle parti ha fatto affidamento, né avrà alcun diritto o rimedio basati su
                        affermazioni,
                        dichiarazioni o
                        garanzie (rese per negligenza o in buona fede), ad eccezione di quanto espressamente
                        stabilito dal presente
                        Contratto.</p>
                    <h3>15.12 Criterio di precedenza.</h3>
                    <p>Qualora si verifichi un conflitto tra i documenti che compongono il presente
                        Contratto, l&#39;ordine di
                        precedenza dei
                        documenti sarà il seguente: i “Termini per il trattamento e la sicurezza dei dati” e
                        il Contratto.</p>
                    <h3>15.13 Intestazioni.</h3>
                    <p>Titoli e didascalie utilizzate nel Contratto hanno solo scopo di riferimento e non
                        avranno alcun effetto
                        sull&#39;interpretazione del Contratto.</p>
                    <h3>15.14 Conflitto di lingue.</h3>
                    <p>Qualora il presente Contratto venisse tradotto in altre lingue diverse
                        dall&#39;italiano e si generasse una
                        discrepanza tra il testo in italiano e il testo tradotto, prevarrà il testo in
                        italiano, salvo laddove
                        espressamente
                        specificato nella traduzione.</p>
                    <h3>15.15 Definizioni.</h3>
                    <ul>
                        <li>&quot;Account&quot; indica l&#39;account DuskRise del Cliente.</li>
                        <li>&quot;Applicazione del Cliente&quot; identifica un programma software che il
                            Cliente crea o ospita o
                            integra utilizzando o non utilizzando i Servizi.
                        </li>
                        <li>&quot;Attività ad alto rischio&quot; indica le attività nelle quali
                            l&#39;utilizzo o il mancato
                            funzionamento dei Servizi può
                            ragionevolmente causare decesso, lesioni alla persona o danni ambientali.
                        </li>
                        <li>&quot;Console di amministrazione&quot; indica, ove applicabili, la o le console
                            online o la security
                            dashboard o
                            l’interfaccia utente forniti da DuskRise al Cliente per l&#39;amministrazione,
                            la gestione e l’utilizzo
                            dei Servizi.
                        </li>
                        <li>&quot;Consociata&quot; indica qualsiasi persona giuridica che Controlli una
                            parte, ne sia Controllata o
                            sia sottoposta a
                            comune Controllo con tale parte, sia direttamente che indirettamente.
                        </li>
                        <li>&quot;Controllo&quot; indica il controllo di oltre il 50% dei diritti di voto o
                            di partecipazioni al
                            capitale di una parte.
                        </li>
                        <li>&quot;Corrispettivi&quot; identifica i costi applicabili per ogni Servizio e
                            ogni imposta applicabile.
                        </li>
                        <li>&quot;Dati del cliente&quot; indica i dati forniti a DuskRise dal Cliente o
                            dagli Utenti finali del
                            Cliente tramite i Servizi
                            attraverso il proprio Account.
                        </li>
                        <li>&quot;Diritti di proprietà intellettuale&quot; indica i diritti attuali e futuri
                            a livello mondiale
                            regolati da leggi sui brevetti,
                            leggi sul copyright, norme sui segreti industriali e norme sui diritti morali,
                            nonché tutti gli altri
                            diritti simili.
                        </li>
                        <li>&quot;Documentazione&quot; indica la documentazione di DuskRise (che può essere
                            aggiornata di volta in
                            volta) nel
                            formato generalmente reso disponibile da DuskRise ai propri clienti per
                            l&#39;utilizzo con i Servizi.
                        </li>
                        <li>&quot;Durata&quot; ha il significato specificato nella Sezione 8 (Durata e
                            risoluzione) del presente
                            Contratto.
                        </li>
                        <li>&quot;Elementi distintivi del brand&quot; indica i nomi commerciali, i marchi, i
                            marchi di servizio, i
                            loghi, i nomi di dominio
                            e altri elementi distintivi del brand o marca di ciascuna parte,
                            rispettivamente, come garantito di
                            volta in volta
                            da tale parte.
                        </li>
                        <li>&quot;Imposte&quot; indica tutte le imposte e tasse nazionali, regionali,
                            comunali, sul reddito,
                            sull’attività, fatturato,
                            stipendi, proprietà, vendite, diritti d’uso, accise, dogane, valore aggiunto,
                            consumo, beni e Prodotti,
                            bollo e
                            ogni altra imposta analoga o oneri, ivi incluse sanzioni e interessi, ad
                            eccezione delle imposte basate
                            sul
                            reddito netto, sugli utili netti, sul valore patrimoniale, sul valore
                            immobiliare o sull&#39;occupazione
                            di DuskRise.
                        </li>
                        <li>&quot;Informazioni riservate&quot; indica le informazioni che una parte (o una
                            Società consociata)
                            comunica all&#39;altra
                            parte ai sensi del presente Contratto e che sono classificate come riservate o
                            che normalmente sarebbero
                            considerate informazioni riservate in tali circostanze. Sono escluse le
                            informazioni di cui il
                            destinatario era
                            già a conoscenza senza violazione di alcun obbligo di riservatezza, le
                            informazioni prodotte in modo
                            indipendente dal destinatario, quelle che vengano legittimamente fornite al
                            destinatario da una terza
                            parte
                            senza obblighi di riservatezza o nonché quelle che diventino di dominio pubblico
                            per colpe non
                            imputabili al
                            destinatario. Fatte salve le disposizioni della frase precedente, i dati del
                            Cliente sono considerati
                            Informazioni riservate.
                        </li>
                        <li>&quot;incluso&quot; indica incluso a titolo esemplificativo.</li>
                        <li>&quot;Indirizzo email di notifica&quot; indica gli indirizzi email specificati
                            dal Cliente
                            all’attivazione del Servizio.
                        </li>
                        <li>&quot;Linee guida per i servizi di assistenza tecnica&quot; indica le linee
                            guida per i servizi di
                            assistenza di DuskRise in
                            vigore per i Servizi.
                        </li>
                        <li>&quot;Procedimento giudiziario&quot; identifica una richiesta di divulgazione di
                            dati presentata in
                            virtù di disposizioni di
                            legge, regolamentazioni statali, ingiunzioni di tribunali, citazioni, mandati
                            oppure di un&#39;altra
                            entità giuridica
                            valida, procedura legale o procedimenti analoghi.
                        </li>
                        <li>“Prodotti” indica il complesso dei singoli prodotti e soluzioni DuskRise,
                            hardware e Software e
                            documentazione prodotta, tra cui Protezione SmartWorking, Cyber Checkup,
                            Sorveglianza Cyber,
                            Protezione Premium.
                        </li>
                        <li>&quot;Responsabilità&quot; indica qualsiasi tipo di responsabilità, sia per
                            inadempienza contrattuale,
                            illecito civile
                            (inclusa la negligenza) o altro, sia prevedibile che contemplato dalle parti.
                        </li>
                        <li>&quot;Servizio di assistenza tecnica&quot; indica il servizio di assistenza
                            tecnica attualmente fornito
                            da DuskRise al
                            Cliente conformemente alle “Linee guida per i servizi di assistenza tecnica”.
                        </li>
                        <li>&quot;Software&quot; significa tutti gli strumenti scaricabili, eventuali
                            software development kit o
                            software per computer
                            simili forniti da DuskRise in relazione ai Servizi, oltre a eventuali
                            aggiornamenti che DuskRise dovesse
                            di
                            volta in volta apportare a tale Software.
                        </li>
                        <li>&quot;Sospendere&quot; o &quot;Sospensione&quot; significa disabilitare o
                            limitare l&#39;accesso a o l&#39;utilizzo
                            dei Servizi o componenti
                            dei Servizi.
                        </li>
                        <li>“Termini specifici del Servizio” indica i termini e condizioni specifici di
                            utilizzo dei singoli
                            componenti dei
                            Prodotti.
                        </li>
                        <li>&quot;Utente finale del Cliente&quot; indica un privato a cui il Cliente
                            consente di utilizzare i
                            Servizi. Per chiarezza, gli
                            Utenti finali possono includere in maniera indicativa e non esaustiva i
                            dipendenti di Consociate del
                            Cliente e
                            altre terze parti.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>Termini per il trattamento e la sicurezza dei dati</h3>
                    <p>Di seguito vengono descritte le modalità con cui raccogliamo e trattiamo le
                        informazioni personali del
                        Cliente in
                        relazione ai Servizi DuskRise.</p>
                    <p>
                        Offriamo i Servizi DuskRise tramite partner autorizzati di Duskrise ed in
                        particolare utilizzando i servizi
                        di
                        Google Cloud Platform. Nei termini seguenti, quando si fa riferimento ai nostri
                        Clienti si intendono inclusi
                        gli
                        Utenti finali del Cliente.</p>
                </div>
                <div>
                    <h3>Quali informazioni raccogliamo</h3>
                    <p>Per fornire i Servizi, DuskRise tratta Dati di servizio. Nel seguito vengono
                        descritti i termini per il
                        trattamento e
                        la sicurezza dei dati che riguardano esclusivamente i Dati di servizio.</p>
                    <p>Per Dati di servizio si intendono le informazioni che DuskRise raccoglie o genera
                        durante la fornitura e
                        l&#39;amministrazione dei Servizi. I Dati di servizio includono a titolo
                        esemplificativo:</p>

                    <ul>
                        <li>a) Pagamenti e transazioni relativi al Servizio. Conserviamo ragionevoli record
                            aziendali su addebiti,
                            pagamenti, dettagli di fatturazione e problemi.
                        </li>
                        <li>b) Impostazioni e configurazioni dei Servizi. Registriamo la configurazione e le
                            impostazioni dell&#39;utente,
                            tra cui
                            attributi e identificatori di risorsa. Sono incluse le impostazioni relative a
                            servizi e sicurezza per i
                            dati e altre
                            risorse.
                        </li>
                        <li>c) Dettagli tecnici e operativi dell&#39;utilizzo dei Servizi da parte
                            dell&#39;utente. Raccogliamo
                            informazioni sull&#39;utilizzo,
                            lo stato operativo, rapporti su errori di software e arresti anomali,
                            credenziali di autenticazione,
                            metriche di
                            qualità e prestazioni e altri dettagli tecnici necessari per il funzionamento e
                            il mantenimento dei
                            Servizi e del
                            software correlato. Queste informazioni possono includere identificatori dei
                            dispositivi, identificatori
                            da
                            cookie o token e indirizzi IP, URL e DNS.
                        </li>
                        <li>d) Comunicazioni dirette degli utenti. Conserviamo documentazione sulle
                            comunicazioni e interazioni con
                            noi e
                            i nostri Clienti, ad esempio quando l&#39;utente fornisce un feedback o
                            informazioni di contatto, pone
                            domande o
                            richiede assistenza tecnica.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>Finalità del trattamento dei dati</h3>
                    <p>DuskRise tratta i Dati di servizio per le seguenti finalità:</p>
                    <ul>
                        <li>a) Fornire i Servizi richiesti dall&#39;utente. I Dati di servizio vengono
                            utilizzati principalmente per
                            fornire i Servizi
                            richiesti dall&#39;utente e dai nostri clienti. Ciò comprende diverse attività
                            di elaborazione che sono
                            necessarie
                            per fornire i Servizi, tra cui l&#39;elaborazione per la fatturazione relativa
                            all&#39;utilizzo dei
                            servizi, per assicurare che
                            i servizi funzionino come previsto, per rilevare e impedire attività malevole o
                            altri problemi che
                            potrebbero
                            verificarsi e per proteggere i dati dell&#39;utente e i servizi che l&#39;utente
                            utilizza.
                        </li>
                        <li>b) Data monetization. I dati di servizio possono essere utilizzati da DuskRise
                            ai fini di analisi di
                            mercato,
                            comportamentali e decisionali per l’erogazione e il miglioramento dei servizi
                            richiesti dai Clienti, nel
                            rispetto
                            della normativa vigente.
                        </li>
                        <li>
                            c) Offrire suggerimenti per ottimizzare l&#39;utilizzo dei Servizi. Possiamo
                            trattare i Dati di servizio
                            per offrire
                            all&#39;utente e ai nostri clienti suggerimenti e consigli. Questi suggerimenti
                            possono includere modi
                            per
                            proteggere meglio l&#39;account o i dati dell&#39;utente, migliorare le
                            prestazioni e informazioni su
                            prodotti o servizi e
                            funzionalità nuovi o correlati. Possiamo inoltre valutare la risposta
                            dell&#39;utente ai suggerimenti
                            ricevuti.
                        </li>
                        <li>d) Mantenere e migliorare i Servizi. Valutiamo i Dati di servizio per poter
                            migliorare le prestazioni e
                            la
                            funzionalità dei Servizi. Quando ottimizziamo i Servizi per l&#39;utente, questi
                            potrebbero migliorare
                            per nostri i
                            clienti e viceversa.
                        </li>
                        <li>e) Fornire e migliorare altri servizi richiesti dall&#39;utente. Possiamo
                            utilizzare i Dati di servizio
                            per fornire e
                            migliorare altri servizi richiesti dall&#39;utente e dai nostri clienti, inclusi
                            servizi di Google o di
                            terze parti abilitati
                            tramite i Servizi, le console amministrative, eventuali API, Google Cloud
                            Platform e Marketplace.
                        </li>
                        <li>f) Fornire assistenza all&#39;utente. Utilizziamo i Dati di servizio quando
                            necessario per fornire
                            assistenza tecnica e
                            servizi professionali secondo quanto richiesto dall&#39;utente e dai nostri
                            clienti e per valutare se le
                            esigenze
                            dell&#39;utente sono state soddisfatte. Utilizziamo inoltre i Dati di servizio
                            per migliorare l&#39;assistenza
                            online e per
                            comunicare con l&#39;utente e con i nostri clienti. Ciò include le notifiche
                            sugli aggiornamenti ai
                            Servizi e la
                            risposta alle richieste di assistenza.
                        </li>
                        <li>g) Proteggere il cliente, gli utenti e DuskRise. Utilizziamo i Dati di servizio
                            per migliorare la
                            sicurezza e
                            l&#39;affidabilità dei nostri servizi. Ciò include il rilevamento, la
                            prevenzione e la risposta a frodi,
                            utilizzi illeciti,
                            rischi per la sicurezza e problemi tecnici che potrebbero danneggiare gli
                            utenti, i clienti o DuskRise.
                            Queste
                            attività sono una parte importante del nostro impegno a proteggere i nostri
                            servizi.
                        </li>
                        <li>h) Ottemperare agli obblighi di legge. Potremmo dover trattare i Dati di
                            servizio per ottemperare ai
                            nostri
                            obblighi di legge, ad esempio per rispondere a un procedimento giudiziario o a
                            una richiesta del governo
                            con forza esecutiva o per ottemperare agli obblighi relativi alla tenuta e alla
                            conservazione dei
                            registri
                            contabili.
                        </li>
                        <li>i) Altre finalità con il consenso dell&#39;utente. Possiamo richiedere il
                            consenso dell&#39;utente per
                            trattare le
                            informazioni per altre finalità non coperte da quanto definito nei presenti
                            Termini. L&#39;utente ha il
                            diritto di
                            ritirare il consenso in qualsiasi momento.
                        </li>
                    </ul>
                    <p>Per queste finalità possiamo utilizzare i Dati di servizio in combinazione con le
                        informazioni raccolte da
                        altri
                        prodotti e servizi DuskRise o di Google. Possiamo utilizzare algoritmi per
                        riconoscere pattern nei Dati di
                        servizio, è possibile anche che la raccolta e revisione di Dati di servizio avvenga
                        manualmente, ad esempio
                        quando l&#39;utente interagisce direttamente con i team di fatturazione o di
                        assistenza. Possiamo aggregare
                        e anonimizzare i Dati di servizio per eliminare i dettagli personali, e possiamo
                        utilizzare i Dati di
                        Servizio per la
                        reportistica interna e l&#39;analisi del prodotto e delle operazioni commerciali
                        applicabili.</p>
                </div>
                <div>
                    <h3>Dove vengono archiviati i dati</h3>
                    <p>I Servizi DuskRise si basano sull’utilizzo dei servizi di Google Cloud Platform da
                        queste località. Il
                        trattamento
                        dei Dati di servizio può avvenire su server di Google situati fuori dal paese perché
                        i Dati di servizio
                        vengono
                        generalmente trattati da unità operative centralizzate o regionalizzate come
                        fatturazione, assistenza e
                        sicurezza.
                        Indipendentemente da dove vengono trattati i Dati di servizio, applichiamo le stesse
                        protezioni descritte in
                        questo documento. Nel caso di trasferimento dei Dati di servizio fuori dallo Spazio
                        economico europeo,
                        DuskRise rispetta le normative applicabili.</p>
                </div>
                <div>
                    <h3>Come proteggiamo i dati</h3>
                    <p>Creiamo i Servizi DuskRise con funzionalità di sicurezza solide per la protezione
                        delle informazioni. Le
                        informazioni che otteniamo nel fornire i servizi ci aiutano a rilevare e bloccare
                        automaticamente le minacce
                        alla
                        sicurezza, affinché gli utenti non siano coinvolti.</p>
                    <p>Ci adoperiamo per proteggere l’utente e DuskRise da accessi non autorizzati alle
                        informazioni in nostro
                        possesso e dall’alterazione, dalla divulgazione e dalla distruzione non autorizzate
                        di tali informazioni. In
                        particolare, utilizzando i servizi offerti da Google Cloud:</p>
                    <ul>
                        <li>a) Criptiamo i Dati di servizio inattivi e in transito tra le nostre
                            strutture.
                        </li>
                        <li>b) Esaminiamo regolarmente le nostre prassi di raccolta, archiviazione e
                            trattamento delle informazioni,
                            comprese le misure sulla sicurezza fisica, per impedire l&#39;accesso non
                            autorizzato ai sistemi.
                        </li>
                        <li>c) Consentiamo l&#39;accesso alle informazioni personali soltanto a dipendenti,
                            consulenti e partner di
                            DuskRise
                            che necessitano di tali informazioni per poterle elaborare per noi. Tutti coloro
                            a cui viene concesso
                            tale
                            accesso devono rispettare rigidi obblighi contrattuali in merito alla
                            riservatezza e potrebbero essere
                            soggetti
                            a sanzioni o risoluzione del contratto qualora non rispettassero tali obblighi.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>Come condividiamo i dati</h3>
                    <p>Non condividiamo Dati di servizio con società, organizzazioni o privati che non fanno
                        parte di DuskRise, ad
                        eccezione dei seguenti casi:</p>

                    <ul>
                        <li>Con il consenso dell&#39;utente
                            <p>Condivideremo Dati di servizio al di fuori di DuskRise solo ed esclusivamente
                                con il consenso dell&#39;utente.</p>
                            <p>Con gli amministratori dell&#39;utente e i rivenditori autorizzati.</p>
                            <p>Quando l&#39;utente utilizza i Servizi, l&#39;amministratore dell&#39;utente
                                e i partner autorizzati
                                a gestire l&#39;account dell&#39;utente
                                o dell&#39;organizzazione dell&#39;utente avranno accesso a determinati Dati
                                di servizio. Ad
                                esempio, potrebbero essere
                                in grado di:</p>
                            <ul>
                                <li>
                                    Visualizzare informazioni sull&#39;account e dati di fatturazione,
                                    attività e statistiche. o
                                    Modificare la password dell&#39;account.
                                </li>
                                <li>Sospendere o interrompere l&#39;accesso all&#39;account da parte
                                    dell&#39;utente.
                                </li>
                                <li>Accedere ai dati dell&#39;account per ottemperare a una legge o una
                                    norma vigente, un
                                    procedimento giudiziario
                                    o una richiesta del governo con forza esecutiva.
                                </li>
                                <li>Limitare la capacità dell&#39;utente di eliminare o modificare le
                                    informazioni o le impostazioni
                                    sulla privacy.
                                </li>
                            </ul>
                        </li>
                        <li>Per trattamenti esterni
                            <p>Forniamo informazioni alle nostre società consociate, ai nostri partner e ad
                                altre aziende o persone
                                fidate
                                affinché si occupino del trattamento per nostro conto, in base alle nostre
                                istruzioni e nel rispetto
                                dei termini del
                                presente documento e di altre misure appropriate relative a riservatezza e
                                sicurezza.</p></li>
                        <li>Per motivi legali
                            <p>Potremo condividere Dati di servizio all&#39;esterno di DuskRise se riteniamo
                                in buona fede che l&#39;accesso,
                                l&#39;utilizzo,
                                la conservazione o la divulgazione di tali informazioni siano
                                ragionevolmente necessari per:</p>
                            <ul>
                                <li>ottemperare a leggi o norme vigenti, procedimenti giudiziari o richieste
                                    del governo con forza
                                    esecutiva.
                                </li>
                                <li>applicare accordi vigenti, comprese le indagini in merito a potenziali
                                    violazioni.
                                </li>
                                <li>rilevare, impedire o altrimenti gestire attività fraudolente o problemi
                                    relativi alla sicurezza
                                    o di natura tecnica.
                                </li>
                                <li>tutelare i diritti, la proprietà o la sicurezza di DuskRise, dei nostri
                                    clienti, utenti e del
                                    pubblico, come richiesto o
                                    consentito dalla legge.
                                </li>
                            </ul>
                            <p>Qualora DuskRise dovesse essere coinvolta in una riorganizzazione, fusione,
                                acquisizione o vendita di
                                asset,
                                continuerà a garantire la riservatezza delle informazioni personali
                                dell&#39;utente e comunicherà
                                agli utenti
                                interessati il futuro trasferimento delle informazioni personali o
                                l&#39;applicazione di norme sulla
                                privacy diverse.</p>
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>Accesso ai dati</h3>
                    <p>Il Cliente potrebbe richiedere di accedere ed esportare i suoi dati per eseguirne il
                        backup o il
                        trasferimento.
                        Alcuni servizi DuskRise consentono all&#39;utente di accedere e scaricare
                        direttamente i dati archiviati nei
                        servizi.</p>
                    <p>Il Cliente può accedere a diverse categorie di Dati di servizio direttamente dai
                        Servizi di DuskRise, incluse
                        informazioni di contatto per la fatturazione, informazioni su pagamenti e
                        transazioni, nonché impostazioni e
                        configurazioni relative ai Servizi.</p>
                    <p>Se l&#39;utente è altrimenti impossibilitato ad accedere ai suoi dati, può richiedere
                        l&#39;accesso
                        utilizzando i servizi di
                        assistenza di DuskRise.</p>
                </div>
                <div>
                    <h3>Eliminazione e conservazione dei dati</h3>
                    <p>Conserviamo i Dati di servizio per periodi di tempo diversi a seconda della loro
                        natura, dell&#39;uso che ne
                        facciamo
                        e di come il Cliente configura le sue impostazioni.</p>
                    <p>I Dati di servizio vengono eliminati o anonimizzati quando non sono più necessari a
                        garantire adeguati
                        livelli di
                        sicurezza e protezione del Cliente. Impostiamo i periodi di tempo per la
                        conservazione di ogni tipo di dati
                        e
                        operazione in base alle finalità della raccolta dei dati specifici e ci assicuriamo
                        che non vengono
                        conservati più
                        a lungo del necessario.
                        A volte dobbiamo conservare determinati dati per un periodo esteso di tempo per
                        finalità aziendali o legali
                        legittime. Ad esempio, quando DuskRise elabora un pagamento effettuato dal Cliente
                        conserviamo i relativi
                        dati
                        come richiesto per fini fiscali o contabili. Altre finalità aziendali o legali
                        legittime che possono
                        richiedere la
                        conservazione dei dati da parte nostra sono ad esempio sicurezza, prevenzione di
                        frodi e usi illeciti,
                        garanzia di
                        continuità dei servizi e conformità alle leggi o alle normative vigenti.</p>
                    <p>Quando eliminiamo i dati, eseguiamo procedure dettagliate per assicurarci che i dati
                        siano rimossi in modo
                        sicuro e completo dai sistemi attivi o conservati solo in forma anonimizzata.
                        Prendiamo adeguate misure per
                        assicurarci che i nostri servizi proteggano le informazioni da cancellazioni
                        accidentali o da parte di
                        malintenzionati mediante l&#39;uso di sistemi di backup.</p>
                </div>
                <div>
                    <h3>Standard europei sulla privacy e GDPR</h3>
                    <p>Qualora al trattamento delle informazioni relative al Cliente si applichi la legge
                        sulla protezione dei dati
                        dell&#39;Unione Europea, del Regno Unito o della Svizzera, l&#39;utente avrà
                        determinati diritti, tra cui il
                        diritto di
                        accedere, correggere, eliminare o esportare le proprie informazioni, nonché di
                        opporsi al trattamento delle
                        proprie informazioni o di richiederne la limitazione.</p>
                    <p>Per gli utenti residenti nello Spazio economico europeo, nel Regno Unito o in
                        Svizzera, il titolare del
                        trattamento dei dati responsabile per i Dati di servizio è DuskRise S.r.l. Tuttavia,
                        qualora il nostro
                        cliente abbia
                        stipulato un contratto relativo ai Servizi con una Consociata di DuskRise, tale
                        società sarà il titolare del
                        trattamento dei dati responsabile per i Dati di servizio soltanto in relazione alla
                        fatturazione dei
                        Servizi.</p>
                    <p>Oltre alle finalità e ai presupposti definiti in questo documento possiamo trattare
                        le informazioni sulla
                        base delle
                        seguenti motivazioni legali:</p>
                    <ul>
                        <li>Se necessario per l&#39;adempimento di un contratto con il Cliente.<p>Possiamo
                            trattare le informazioni
                            quando necessario al fine di stipulare un contratto con il Cliente o
                            ottemperare agli impegni contrattuali con il Cliente.</p></li>
                        <li>Quando rispettiamo gli obblighi legali.<p>Tratteremo le informazioni
                            dell&#39;utente quando saremo
                            soggetti a obblighi di legge che ci impongono di farlo.</p></li>
                        <li>Quando perseguiamo interessi legittimi.<p>Possiamo trattare Dati di servizio
                            sulla base di interessi
                            legittimi nostri e di terzi, applicando al contempo
                            salvaguardie appropriate a protezione della privacy dell&#39;utente. Ciò
                            significa che trattiamo le
                            informazioni
                            relative al Cliente al fine di fornire i Servizi richiesti; offrire suggerimenti
                            per ottimizzare l&#39;uso
                            dei Servizi;
                            mantenere e migliorare i Servizi; fornire e migliorare altri servizi richiesti
                            dal Cliente; fornire
                            assistenza; e
                            proteggere contro danni ai diritti, alla proprietà o alla sicurezza di DuskRise,
                            dei suoi utenti, dei
                            suoi clienti,
                            secondo quanto prescritto o consentito dalla legge.</p></li>
                    </ul>
                </div>
                <div>
                    <h3>Linee guida per i servizi di assistenza tecnica</h3>
                    <p>Le seguenti linee guida per i servizi di supporto tecnico (&quot;Linee Guida&quot;)
                        si applicano ai contratti
                        in cui DuskRise
                        fornisce i Servizi e il relativo supporto tecnico al Cliente.</p>
                    <ol type="1">
                        <li>Come parte dell&#39;acquisto dei Servizi da parte del Cliente, DuskRise fornirà
                            al Cliente un Supporto
                            di base
                            tramite help desk di secondo e terzo livello.
                        </li>
                        <li>Supporto di base. Il Cliente riceverà gli aggiornamenti automatici dei Servizi e
                            gli aggiornamenti di
                            manutenzione, il supporto per le richieste di fatturazione e l&#39;accesso alla
                            documentazione e ai
                            white paper.
                        </li>
                        <li>Presentazione della richiesta di supporto.
                            <ol>
                                <li>Supporto di prima linea. Ove applicabile il Punto Cyber fornirà
                                    assistenza di primo livello al
                                    Cliente.
                                    DuskRise fornirà assistenza di secondo e terzo livello solo al Cliente.
                                </li>
                                <li>Sforzi del Cliente per risolvere gli errori. Prima di inoltrare una
                                    richiesta al Punto Cyber, il
                                    Cliente compirà
                                    ogni ragionevole sforzo per risolvere qualsiasi errore, bug,
                                    malfunzionamento o difetto di
                                    connettività di rete
                                    senza rivolgersi a DuskRise. Successivamente, il Cliente potrà
                                    presentare una Richiesta di
                                    supporto.
                                </li>
                                <li>Procedure per il riconoscimento e la risoluzione delle Richieste. Quando
                                    presenta una Richiesta,
                                    il Cliente
                                    fornirà tutte le informazioni diagnostiche richieste e assisterà il
                                    personale di assistenza di
                                    DuskRise nella
                                    misura necessaria a risolvere una Richiesta.
                                </li>
                                <li>Riconoscimento della Richiesta. DuskRise può rispondere a una Richiesta
                                    confermando il
                                    ricevimento della
                                    stessa. Il Cliente riconosce e comprende che DuskRise potrebbe non
                                    essere in grado di fornire
                                    risposte o
                                    risolvere tutte le Richieste.
                                </li>
                                <li>Richieste di funzionalità. Se DuskRise ritiene che una Richiesta sia una
                                    Richiesta di una nuova
                                    funzionalità,
                                    DuskRise registrerà tale Richiesta per considerarla da aggiungere a un
                                    futuro aggiornamento o
                                    rilascio dei
                                    Servizi e considererà la questione chiusa. DuskRise non ha alcun obbligo
                                    di rispondere o
                                    risolvere qualsiasi
                                    Richiesta di Funzioni o di includere tale Richiesta di Funzioni in
                                    qualsiasi aggiornamento o
                                    release futura.
                                </li>
                                <li>DuskRise in merito ad una Richiesta di Supporto non ha alcun obbligo ai
                                    sensi delle presenti
                                    Linee Guida
                                    di: (i) scrivere, costruire o migliorare qualsiasi Servizio, o scrivere
                                    codice per migliorare i
                                    Servizi; (ii)
                                    configurare nuovi Servizi per il Cliente; o (iii) progettare, costruire
                                    o rivedere i propri
                                    servizi per far
                                    funzionare l&#39;infrastruttura del Cliente.
                                </li>
                            </ol>
                        </li>
                        <li>Orari di assistenza e tempi di risposta DuskRise elaborerà le Richieste durante
                            il normale orario di
                            lavoro
                            dalle 9 alle 18 dal lunedì al venerdì (“Orario di Funzionamento”). Tutte le
                            Richieste ricevute al di
                            fuori degli
                            Orari di Funzionamento saranno registrate ed elaborate durante il Giorno
                            lavorativo successivo. Gli
                            unici
                            livelli di servizio contemplati si basano sul livello “next business day”.
                        </li>
                        <li>Manutenzione. Per garantire prestazioni ottimali dei Servizi, DuskRise effettua
                            una Manutenzione
                            periodica.
                            Nella maggior parte dei casi, la Manutenzione avrà un impatto negativo limitato
                            o nullo sulla
                            disponibilità e
                            sulla funzionalità dei Servizi. Se DuskRise prevede che la Manutenzione
                            programmata possa avere un
                            impatto negativo sulla disponibilità o sulla funzionalità dei Servizi, DuskRise
                            si impegnerà per quanto
                            possibile a fornire un preavviso in merito alla Manutenzione. Inoltre, DuskRise
                            può eseguire in
                            qualsiasi
                            momento una Manutenzione straordinaria di emergenza. Se DuskRise prevede che
                            tale Manutenzione non
                            programmata di emergenza possa influire negativamente sulla disponibilità o
                            sulla funzionalità dei
                            Servizi,
                            DuskRise si impegnerà in modo ragionevole a fornire un preavviso di tale
                            Manutenzione. Gli avvisi di
                            manutenzione di cui sopra saranno forniti tramite lo strumento di supporto di
                            DuskRise o tramite un&#39;e-mail
                            all&#39;indirizzo e-mail di notifica.
                        </li>
                        <li>Supporto linguistico in generale. Tutta l&#39;assistenza fornita da DuskRise ai
                            sensi delle presenti
                            Linee Guida
                            sarà fornita in lingua italiana o inglese.
                        </li>
                        <li>Attività di trattamento dei Dati di Supporto. DuskRise raccoglie ed elabora i
                            Dati di Supporto, che
                            rientrano
                            nei Dati di Servizio, allo scopo di fornire assistenza tecnica ai sensi delle
                            presenti Linee Guida e di
                            mantenere i Servizi.
                        </li>
                        <li>Servizi professionali. Oltre ai servizi di assistenza e manutenzione sopra
                            descritti, DuskRise può
                            fornire al
                            Cliente servizi di consulenza limitati ai sensi delle presenti Linee Guida, in
                            conformità con un ordine
                            aggiuntivo tra DuskRise e il Cliente e con le schede tecniche associate ai
                            servizi. Potrebbero essere
                            applicati
                            costi aggiuntivi. I servizi di consulenza sono solo raccomandazioni. Il Cliente
                            è responsabile dei
                            risultati
                            ottenuti nel momento in cui decide se mettere in pratica i consigli di DuskRise.
                            DuskRise può consegnare
                            le
                            raccomandazioni al Cliente sotto forma di documento di lavoro o relazione, che
                            il Cliente può
                            utilizzare,
                            modificare e riprodurre per i propri scopi commerciali interni. DuskRise non
                            concederà altrimenti in
                            licenza
                            alcuna proprietà intellettuale al Cliente come parte dei servizi di consulenza
                            forniti ai sensi delle
                            presenti
                            Linee Guida. Qualsiasi altro servizio di consulenza, professionale o di
                            implementazione sarà soggetto ai
                            termini di un accordo separato tra DuskRise e il Cliente.
                        </li>
                    </ol>
                </div>
                <div>
                    <div className="ml-4 mr-3 ">
                        <h2>Termini di servizio applicabili all’utilizzo dei servizi DuskRise</h2>
                        <div>
                            <p>I presenti Termini di servizio (il "Contratto") applicabili all’uso dei
                                servizi forniti da DuskRise
                                anche attraverso i Prodotti (i “Servizi”), sono stipulati tra DuskRise e la
                                persona fisica o
                                giuridica che accetta i presenti termini (il "Cliente") e regolano l'accesso
                                e l'utilizzo dei
                                Servizi da parte del Cliente.</p>
                            <p>Il presente Contratto entrerà in vigore dalla settimana successiva alla data
                                in cui il Cliente
                                accetterà l’ordine di DuskRise (“Data di Validità”). Se l'accettazione
                                avviene per conto del
                                Cliente, si dichiara e si garantisce: (i) di avere la piena autorità legale
                                di vincolare il Cliente
                                al presente Contratto; (ii) di avere letto e compreso il presente Contratto
                                e (iii) di accettare il
                                presente Contratto per conto del Cliente.</p>
                        </div>
                        <div>
                            <h3>1. Fornitura dei Servizi</h3>
                            <h3>1.1 Uso dei Servizi.</h3>
                            <p>
                                Per la Durata del Contratto DuskRise fornirà i Servizi conformemente al
                                Contratto, inclusi gli
                                accordi SLA, e il Cliente può utilizzare tali Servizi e integrarli in
                                qualsiasi sua infrastruttura
                                IT conformemente al Contratto.
                            </p>
                            <h3>1.2 Console di amministrazione.</h3>
                            <p>
                                Il Cliente avrà accesso alla Console di amministrazione, ove prevista
                                nell’ambito dei Servizi,
                                tramite la quale può gestirne l'uso.
                            </p>
                            <h3>1.3 Account.</h3>
                            <p>
                                Il Cliente deve disporre di un Account, ove previsto, per utilizzare i
                                Servizi ed è responsabile
                                delle informazioni che fornisce per la creazione dell'Account, della
                                sicurezza delle proprie
                                password per l'Account e di qualsiasi utilizzo del proprio Account. DuskRise
                                non è tenuta in alcun
                                modo a fornire più di un account al Cliente.
                            </p>
                        </div>
                        <div>
                            <h3>1.4 Modifiche.</h3>

                            <ul>
                                <li>
                                    (a) Ai Servizi. DuskRise ha facoltà di introdurre aggiornamenti
                                    ragionevoli ai Servizi. DuskRise
                                    informerà preventivamente il Cliente qualora intendesse apportare una
                                    modifica sostanziale ai
                                    Servizi con un impatto significativo sull'utilizzo dei Servizi da parte
                                    del Cliente, a
                                    condizione che quest’ultimo abbia un contratto attivo.
                                </li>

                                <li>
                                    (b) Al Contratto. DuskRise ha facoltà di apportare modifiche al presente
                                    Contratto, compresi i
                                    prezzi (e gli eventuali documenti correlati). Salvo laddove diversamente
                                    indicato da DuskRise,
                                    le modifiche sostanziali al Contratto entrano in vigore 10 giorni dopo
                                    la relativa informativa,
                                    ad eccezione delle modifiche relative a nuove funzionalità che
                                    entreranno in vigore
                                    immediatamente. DuskRise fornirà un preavviso di almeno 90 giorni in
                                    caso di modifiche
                                    sostanzialmente negative a qualsivoglia SLA (i) inviando un'email
                                    all'indirizzo email di
                                    notifica; (ii) pubblicando un avviso sulla Console di amministrazione; o
                                    (iii) pubblicando un
                                    avviso sulla pagina web relativa allo SLA applicabile.
                                </li>

                                <li>
                                    (c) Termini per il trattamento e la sicurezza dei dati. DukRise potrebbe
                                    modificare i “Termini
                                    per il trattamento e la sicurezza dei dati” soltanto laddove la modifica
                                    sia resa necessaria per
                                    rispettare leggi o regolamenti vigenti, ordini del Tribunale o
                                    indicazioni emanate dalle
                                    autorità competenti, ove la modifica sia espressamente consentita dai
                                    “Termini per il
                                    trattamento e la sicurezza dei dati” o nel caso in cui tale modifica:

                                    <p>
                                        (i) sia commercialmente ragionevole;

                                        (ii) non comporti un peggioramento generale della sicurezza dei
                                        Servizi;

                                        (iii) non rimuova o non estenda l'ambito di eventuali limitazioni
                                        relative al trattamento
                                        dei Dati del Cliente da parte di DuskRise, come descritto nella
                                        sezione “Ambito del
                                        trattamento dei dati” dei “Termini per il trattamento e la sicurezza
                                        dei dati”; o

                                        (iv) non abbia un impatto negativo rilevante sui diritti del Cliente
                                        previsti dai “Termini
                                        per il trattamento e la sicurezza dei dati”.
                                    </p>
                                    Qualora DuskRise apporti una modifica sostanziale ai “Termini per il
                                    trattamento e la sicurezza
                                    dei dati” in conformità con la presente Sezione, il Cliente verrà
                                    prontamente informato di tale
                                    modifica attraverso una comunicazione via posta elettronica contenente
                                    tali termini.
                                </li>

                                <li>
                                    (d) Interruzione dei Servizi. DuskRise informerà il Cliente almeno 12
                                    mesi prima di interrompere
                                    qualsiasi Servizio (o funzionalità rilevante associata), a meno che
                                    DuskRise non sostituisca
                                    tale Servizio o funzionalità interrotta con un Servizio o funzionalità
                                    sostanzialmente simile.
                                    Inoltre, DuskRise avviserà il Cliente almeno 12 mesi prima di modificare
                                    in modo significativo
                                    una funzionalità di DuskRise rivolta al Cliente qualora questo avvenga
                                    in modo non
                                    retrocompatibile. Niente nella presente Sezione 1.4(d) (Interruzione dei
                                    Servizi) limita la
                                    facoltà di DuskRise di apportare le modifiche necessarie a rispettare la
                                    legge vigente,
                                    affrontare un rischio significativo per la sicurezza o evitare un onere
                                    economico o tecnico
                                    sostanziale.
                                </li>
                            </ul>


                        </div>
                        <div>
                            <h3>1.5 Prodotti.</h3>
                            <p>DuskRise può mettere a disposizione del Cliente i Prodotti, hardware e
                                Software o report, inclusi
                                software o hardware di terze parti. L'utilizzo di qualsiasi software o
                                hardware da parte del Cliente
                                è soggetto alle disposizioni pertinenti contenute nei Termini specifici del
                                Servizio ove previsti.
                            </p>
                        </div>
                        <div>
                            <h3>1.5 Termini specifici del Servizio e Termini per il trattamento e la
                                sicurezza dei dati.</h3>
                            <p>I “Termini specifici del Servizio” e i “Termini per il trattamento e la
                                sicurezza dei dati” sono
                                inclusi tramite questo
                                riferimento nel Contratto.
                            </p>
                        </div>
                        <div>
                            <h3>2. Termini di pagamento</h3>
                            <ul>
                                <li>
                                    2.1 Fatturazione online. Il Cliente dovrà pagare i corrispettivi
                                    contestualmente alla firma e
                                    accettazione
                                    dell’ordine o, ove previsto, alla scadenza annuale per poter continuare
                                    ad utilizzare i Servizi.
                                    A fronte del
                                    pagamento DuskRise invierà la relativa fattura. I pagamenti effettuati
                                    tramite bonifico bancario
                                    devono
                                    includere i riferimenti bancari forniti da DuskRise.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3>2.2 Imposte</h3>
                            <ul>
                                <li>
                                    (a) Il Cliente è responsabile del pagamento di eventuali Imposte e
                                    pagherà a DuskRise i costi
                                    dei Servizi senza
                                    alcuna facoltà di dedurre importi per tali eventuali Imposte. Qualora
                                    per DuskRise sussista l&#39;obbligo
                                    legale di
                                    incassare o pagare tali Imposte, il relativo importo dovrà essere
                                    fatturato al Cliente e il
                                    Cliente pagherà tali
                                    Imposte a DuskRise in aggiunta ai corrispettivi, a meno che il Cliente
                                    non fornisca
                                    tempestivamente a
                                    DuskRise un certificato valido di esenzione fiscale.
                                </li>
                                <li>
                                    (b) Se richiesto dalla legge vigente, il Cliente fornirà a DuskRise i
                                    dati fiscali
                                    identificativi applicabili che
                                    DuskRise potrebbe richiedere per garantirne la conformità alle normative
                                    fiscali vigenti e alle
                                    autorità fiscali
                                    competenti nelle giurisdizioni applicabili. Il Cliente sarà tenuto a
                                    pagare (o rimborsare a
                                    DuskRise) eventuali
                                    tasse, interessi, sanzioni o multe derivanti da qualsiasi dichiarazione
                                    errata del Cliente.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3>2.3 Controversie relative alle fatture e rimborsi.</h3>
                            <p>Nei limiti consentiti dalla legge, il Cliente rinuncia a qualsiasi
                                rivendicazione relativa ai
                                corrispettivi a meno che tale rivendicazione non sia prodotta entro 30
                                giorni dalla data della
                                fattura. Gli eventuali rimborsi (se presenti)
                                sono a esclusiva discrezione di DuskRise e saranno esclusivamente sotto
                                forma di credito per i
                                Servizi.
                                Nessuna parte del presente Contratto impone a DuskRise di estendere il
                                credito a una parte.</p>
                        </div>
                        <div>
                            <h3>2.4 Pagamenti insoluti; sospensione.</h3>
                            <p>Nei casi di Servizi che prevedono il rinnovo annuale, i ritardi di pagamento
                                saranno soggetti a
                                interessi al tasso
                                dell&#39;1,5% al mese (o al tasso massimo consentito dalla legge, se
                                inferiore) a partire dalla data
                                di scadenza del
                                pagamento fino al completo pagamento. Il Cliente sarà responsabile di tutte
                                le spese (incluse le
                                spese legali)
                                ragionevolmente sostenute da DuskRise per la riscossione di tali importi
                                insoluti. Se il Cliente è
                                in ritardo nel
                                pagamento dei Servizi, DuskRise potrà sospendere i Servizi o risolvere il
                                Contratto per
                                inadempimento come
                                stabilito dalla Sezione 10.2 (Risoluzione per inadempimento).
                            </p>
                        </div>
                        <div>
                            <h3>2.5 Nessun numero di ordine di acquisto richiesto.</h3>
                            <p>Il Cliente è obbligato a pagare tutte i corrispettivi applicabili senza che
                                DuskRise debba fornire un
                                numero di
                                ordine di acquisto sulla fattura di DuskRise (o altri documenti).
                            </p>
                        </div>
                        <div>
                            <h3>3. Obblighi del Cliente</h3>
                            <h3>3.1 Conformità.</h3>
                            <p>Il Cliente è tenuto a (a) garantire che il proprio utilizzo dei Servizi
                                (incluso l&#39;utilizzo da
                                parte dei propri Utenti
                                finali) rispetti le disposizioni del presente Contratto, (b) compiere ogni
                                sforzo ragionevole per
                                impedire e
                                interrompere qualsiasi accesso o uso non autorizzato dei Servizi o qualsiasi
                                altra violazione della
                                propria
                                sicurezza che possa pregiudicare DuskRise o possa avere un qualunque impatto
                                ai sensi del presente
                                Contratto e (c) informare tempestivamente DuskRise di qualsiasi utilizzo o
                                accesso non autorizzato
                                ai Servizi,
                                all&#39;Account o alla password del Cliente di cui il Cliente venga a
                                conoscenza. DuskRise si
                                riserva il diritto di
                                indagare su eventuali violazioni da parte del Cliente.</p>
                            <h3>3.2 Privacy.</h3>
                            <p>
                                Con riferimento alle attività oggetto del presente Contratto, a seguito
                                della sottoscrizione per
                                accettazione dello
                                stesso, il Cliente si impegna ad osservare tutti gli obblighi derivanti
                                dalla normativa in materia
                                di Protezione dei
                                Dati Personali, in particolare il Regolamento UE n. 2016/679 (“GDPR”) nonché
                                ogni ulteriore norma
                                dettata a
                                livello nazionale o sovranazionale in materia di protezione dei dati
                                personali, in particolare con
                                riferimento ai
                                provvedimenti emanati dall’Autorità Garante per la Protezione dei Dati
                                Personali ovvero del Comitato
                                Europeo
                                per la Protezione dei Dati.
                                Il Cliente è responsabile per (e dichiara di aver ottenuto, ove necessario)
                                tutti i consensi e le
                                autorizzazioni che
                                permettono (a) l&#39;utilizzo e la ricezione dei Servizi da parte del
                                Cliente e (b) l&#39;accesso, l&#39;archiviazione
                                e il
                                trattamento da parte di DuskRise dei dati relativi al Cliente ai sensi del
                                presente Contratto, con
                                particolare
                                riferimento all’eventuale trattamento, da parte di DuskRise, di dati
                                personali dei dipendenti del
                                Cliente.
                                In particolare, il Cliente dichiara di aver debitamente informato i propri
                                dipendenti ed eventuali
                                altri soggetti
                                interessati dall’esecuzione dei Servizi oggetto del presente Contratto, ed
                                acquisito il relativo
                                consenso, ove
                                necessario, manlevando espressamente DuskRise da ogni responsabilità
                                derivante dall’illegittimo
                                trattamento”.
                                Il Cliente è responsabile per (e dichiara di aver ottenuto) tutti i consensi
                                e le notifiche
                                necessari che permettono
                                (a) l&#39;utilizzo e la ricezione dei Servizi da parte del Cliente e (b)
                                l&#39;accesso, l&#39;archiviazione
                                e il trattamento da parte
                                di DuskRise dei dati forniti dal Cliente ai sensi del presente Contratto.
                            </p>
                            <h3>3.3 Limitazioni.</h3>
                            <p>
                                Il Cliente non potrà e non consentirà agli Utenti finali di (a) copiare,
                                modificare o creare un&#39;opera
                                derivata dai
                                Servizi; (b) retro-ingegnerizzare, decompilare, tradurre, disassemblare o
                                tentare in altro modo di
                                estrarre una
                                parte o la totalità del codice sorgente dei Servizi; (c) vendere, rivendere,
                                concedere in licenza,
                                trasferire o
                                distribuire una parte o la totalità dei Servizi; (d) accedere o utilizzare i
                                Servizi (i) per
                                Attività ad alto rischio; (ii) in
                                violazione dei brevetti depositati; (iii) in modo da evitare di incorrere
                                nel pagamento dei
                                corrispettivi o per
                                aggirare i limiti o modalità d&#39;uso specifici del Servizio; (vi) in modo
                                da violare o causare la
                                violazione delle leggi
                                sul controllo delle esportazioni; o (vii) per trasmettere, memorizzare o
                                elaborare informazioni
                                sensibili soggette
                                alle normative dei diversi paesi; (e) utilizzare i Servizi in qualsiasi modo
                                che violi qualsiasi
                                legge o regolamento
                                locale, nazionale o internazionale applicabile; oppure (f) intraprendere
                                intenzionalmente qualsiasi
                                azione che
                                possa danneggiare, impedire il normale funzionamento dei Servizi o imporre
                                un carico irragionevole o
                                sproporzionatamente elevato o che possa essere ragionevolmente interpretata
                                come interferenza con il
                                Prodotto.
                            </p>
                            <h3>3.4 Documentazione.</h3>
                            <p>DuskRise può fornire Documentazione relativa all&#39;utilizzo dei Servizi da
                                parte del Cliente.</p>
                        </div>
                        <div>
                            <h3>4. Sospensione</h3>
                            <h3>4.1 Violazioni dei termini di utilizzo.</h3>
                            <p>Qualora DuskRise venga a conoscenza del fatto che l&#39;utilizzo dei Servizi
                                da parte del Cliente o
                                di qualsiasi
                                Utente finale del Cliente violi i termini di utilizzo, DuskRise informerà il
                                Cliente della
                                violazione chiedendogli di
                                rimediare a tale violazione. Qualora il Cliente non riesca a risolvere la
                                violazione entro 24 ore
                                dalla richiesta di
                                DuskRise, DuskRise potrà sospendere totalmente o parzialmente l&#39;utilizzo
                                dei Servizi da parte
                                del Cliente
                                finché la violazione non sarà risolta riservandosi il diritto di adire per
                                le vie legali per
                                eventuali danni causati da
                                tale violazione.
                            </p>
                            <h3>4.2 Altre sospensioni</h3>
                            <p>In deroga alla Sezione 4.1 (Violazioni dei termini di utilizzo), DuskRise può
                                sospendere
                                immediatamente,
                                totalmente o in parte, l&#39;utilizzo dei Servizi da parte del Cliente
                                qualora (a) DuskRise ritenga
                                che l&#39;utilizzo dei
                                Servizi da parte del Cliente o di qualsiasi Utente finale del Cliente possa
                                avere un impatto
                                negativo sui Servizi,
                                sull&#39;utilizzo dei Servizi da parte di altri clienti o dei loro utenti
                                finali o sulla rete o sui
                                server DuskRise utilizzati
                                per fornire i Servizi; (b) si sospetti l&#39;accesso non autorizzato di
                                terze parti ai Servizi; (c)
                                DuskRise ritenga di
                                essere tenuta a sospendere immediatamente l&#39;utilizzo dei Servizi per
                                rispettare la legge
                                vigente; o (d) il Cliente
                                violi la Sezione 3.3 (Limitazioni). DuskRise revocherà tale sospensione non
                                appena le circostanze
                                causa della
                                sospensione saranno state risolte. Su richiesta del Cliente, a meno che non
                                sia proibito dalla legge
                                vigente,
                                DuskRise comunicherà al Cliente le basi della sospensione non appena
                                ragionevolmente possibile.</p>
                        </div>
                        <div>
                            <h3>5. Diritti di proprietà intellettuale</h3>
                            <p>Salvo quanto espressamente specificato nel presente Contratto, il Contratto
                                non concede ad alcuna
                                parte
                                alcun diritto, implicito o di altro tipo, relativo ai contenuti o alla
                                proprietà intellettuale dell&#39;altra
                                parte. Per ciò che
                                concerne le parti, il Cliente conserva tutti i Diritti di proprietà
                                intellettuale dei propri dati e
                                delle applicazioni del
                                Cliente e DuskRise conserva tutti i Diritti di proprietà intellettuale dei
                                Servizi (ivi inclusi i
                                Prodotti) e del
                                Software.</p>
                        </div>
                        <div>
                            <h3>6. Diritti di proprietà intellettuale in caso di sviluppi software</h3>

                            <ul>
                                <li>
                                    6.1 Nel caso in cui il Cliente richieda a DuskRise di sviluppare alcune
                                    funzionalità aggiuntive
                                    su proprio requisito e specifica, al fine di implementare le nuove
                                    funzionalità ai Servizi
                                    offerti nell’esecuzione del
                                    Contratto, Duskrise potrà utilizzare prodotti, materiali, strumenti e
                                    metodologie quali, a
                                    titolo esemplificativo e
                                    non esaustivo, programmi e librerie che sono (e resteranno) di proprietà
                                    della stessa Duskrise
                                    fatti salvi
                                    eventuali diritti di licenza che saranno in ogni caso riconosciuti al
                                    Cliente per tutto il
                                    periodo di durata del
                                    rapporto contrattuale in quanto funzionali alla corretta operatività dei
                                    Servizi.
                                </li>

                                <li>
                                    6.2 Lo sviluppo del software aggiuntivo costituirà, invece, un prodotto
                                    personalizzato e
                                    sviluppato ad hoc da
                                    Duskrise sulla base delle indicazioni e specifiche tecniche fornite dal
                                    Cliente. I diritti di
                                    proprietà intellettuale
                                    relativi al predetto sviluppo software e solo per le componenti
                                    sviluppate su specifica del
                                    Cliente saranno
                                    invece di proprietà del Cliente.
                                </li>

                                <li>
                                    6.3 Laddove nelle fasi esecutive di sviluppo del software aggiuntivo,
                                    vengano impiegati
                                    prodotti, materiali,
                                    strumenti e metodologie medio tempore realizzati da Duskrise questi sono
                                    e resteranno di
                                    proprietà di
                                    DuskRise. In tali ipotesi, sarà cura di Duskrise indicare e comunicare
                                    al Cliente il dettaglio
                                    di tali ulteriori
                                    prodotti, materiali, strumenti e metodologie affinché risulti chiaro il
                                    permanere in capo a
                                    DuskRise dei relativi
                                    diritti di proprietà intellettuale.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3>7. Protezione dei dati del Cliente</h3>
                            <h3>7.1 Protezione dei dati del Cliente.</h3>
                            <p>DuskRise accederà o utilizzerà i Dati del Cliente solo per fornire i Servizi
                                e i servizi di
                                assistenza tecnica al
                                Cliente. DuskRise ha implementato e manterrà in vigore misure di
                                salvaguardia amministrative,
                                fisiche e
                                tecniche per proteggere i Dati del Cliente, come ulteriormente descritto nei
                                “Termini per il
                                trattamento e la
                                sicurezza dei dati”.
                                DuskRise tratterà i dati personali relativi a soggetti operanti nell’ambito
                                dell’organizzazione
                                aziendale del Cliente
                                solo per fornire i Servizi e i servizi di assistenza tecnica al Cliente.
                                DuskRise ha implementato e
                                manterrà in
                                vigore adeguate misure di sicurezza, sia tecniche che organizzative, per
                                proteggere i dati personali
                                da eventuali
                                distruzioni o perdite di natura illecita o accidentale, danni, alterazioni,
                                divulgazioni o accessi
                                non autorizzati, ed
                                in particolare, laddove il trattamento comporta trasmissioni di dati su una
                                rete, da qualsiasi altra
                                forma illecita di
                                trattamento, come ulteriormente descritto nei “Termini per il trattamento e
                                la sicurezza dei
                                dati”</p>
                            <h3>7.2 Feedback del Cliente.</h3>
                            <p>Il Cliente può, a propria discrezione, fornire feedback o suggerimenti a
                                DuskRise in merito ai
                                Servizi
                                (&quot;Feedback&quot;). Se il Cliente fornisce un Feedback, DuskRise potrà
                                utilizzare tale Feedback
                                senza limitazioni e
                                senza obblighi nei confronti del Cliente.</p>
                            <h3>7.3 Analisi comparativa.</h3>
                            <p>Il Cliente può condurre prove di analisi comparativa dei Servizi (ciascuno
                                un &quot;Test&quot;). Il
                                Cliente può divulgare
                                pubblicamente i risultati di tali Test solo se (a) ottiene il previo
                                consenso scritto di DuskRise,
                                (b) fornisce a
                                DuskRise tutte le informazioni necessarie per replicare i Test. Fermo
                                restando quanto sopra, il
                                Cliente non
                                potrà intraprendere nessuna delle seguenti operazioni per conto di un
                                fornitore di servizi o
                                prodotti analoghi o
                                simili a quelli di DuskRise senza previo consenso scritto da parte di
                                DuskRise: (i) condurre
                                (direttamente o
                                tramite terze parti) un test dei Servizi o (ii) divulgare i risultati di
                                tali test.</p>
                        </div>
                        <div>
                            <h3>8. Servizi di assistenza tecnica.</h3>
                            <h3>8.1 Da parte del Cliente.</h3>
                            <p>Laddove possibile e applicabile il Cliente è responsabile del supporto
                                tecnico di primo livello delle
                                proprie
                                Applicazioni e dei propri Progetti e di eventuali richieste relative alla
                                fase di installazione e
                                primo utilizzo dei
                                Servizi DuskRise. Per tale supporto sarà cura di DuskRise fornire apposita
                                formazione in modalità
                                “train to the
                                trainer” agli operatori di supporto tecnico di primo livello del Cliente ed
                                un knowledge base
                                contenente casi e modi di risoluzione dei problemi comunemente riscontrabili
                                nelle fasi di adozione
                                ed utilizzo dei Servizi
                                DuskRise.</p>
                            <h3>8.2 Da parte di DuskRise.</h3>
                            <p>Ove applicabile per i Servizi, subordinatamente al pagamento dei
                                corrispettivi contrattuali
                                applicabili, DuskRise
                                fornirà servizi di assistenza tecnica al Cliente di secondo e terzo livello
                                per i 12 mesi successivi
                                all’attivazione
                                del Servizio, in conformità con le “Linee guida sui servizi di assistenza
                                tecnica”.</p>
                        </div>
                        <div>
                            <h3>9. Informazioni riservate.</h3>
                            <h3>9.1 Obblighi.</h3>
                            <p>
                                Il destinatario utilizzerà le Informazioni riservate della parte divulgante
                                solo per esercitare i
                                propri diritti e
                                adempiere ai propri obblighi del presente Contratto, adottando un livello di
                                attenzione ragionevole
                                per
                                salvaguardarne la riservatezza. Il destinatario delle Informazioni riservate
                                può divulgarle solo a
                                Consociate,
                                dipendenti, agenti o consulenti professionali che devono esserne a
                                conoscenza e che abbiano
                                accettato per
                                iscritto di mantenerne la riservatezza (o che siano altrimenti vincolati a
                                mantenerne la
                                riservatezza, nel caso di
                                consulenti professionali). Il destinatario si assicurerà che tali persone
                                fisiche e giuridiche
                                utilizzino le
                                Informazioni riservate ricevute solo per esercitare i diritti e adempiere
                                agli obblighi previsti dal
                                presente
                                Contratto.
                            </p>
                            <h3>9.2 Divulgazione obbligatoria.</h3>
                            <p>
                                Nonostante eventuali indicazioni contrarie contenute nel presente Contratto,
                                il destinatario può
                                anche divulgare
                                Informazioni riservate nella misura richiesta dalla legge vigente, a
                                condizione che il destinatario
                                compia ogni
                                ragionevole sforzo commerciale per (a) informare tempestivamente l&#39;altra
                                parte di tale
                                divulgazione prima della
                                divulgazione stessa e (b) soddisfare le ragionevoli richieste dell&#39;altra
                                parte riguardo ai
                                propri sforzi per opporsi
                                alla divulgazione. Fatte salve le precedenti disposizioni, le sottosezioni
                                (a) e (b) sopra riportate
                                non si
                                applicheranno se il destinatario stabilisce che il rispetto di (a) e (b)
                                potrebbe (i) comportare una
                                violazione della
                                legge; (ii) ostacolare un&#39;indagine governativa o (iii) causare la morte
                                o gravi lesioni fisiche
                                a un privato.
                            </p>
                        </div>
                        <div>
                            <h3>10. Durata e risoluzione.</h3>
                            <h3>10.1 Durata contrattuale.</h3>
                            <p>La &quot;Durata&quot; del presente Contratto decorrerà dalla Data di validità
                                e rimarrà in vigore per
                                un totale di 36 mesi
                                per tutti i Servizi.</p>
                            <h3>10.2 Risoluzione per inadempimento.</h3>
                            <p>DuskRise può risolvere il Contratto se il Cliente (a) è inadempiente rispetto
                                agli impegni assunti
                                con il Contratto
                                e non pone rimedio a tale violazione entro 30 giorni dalla ricezione della
                                notifica scritta o (b)
                                sospende la
                                propria attività.</p>
                            <h3>10.3 Recesso.</h3>
                            <ol type="A">
                                <li>Duskrise avrà facoltà di recedere dal presente Contratto dandone
                                    comunicazione scritta con un
                                    preavviso di
                                    30 giorni qualora il Cliente diventi oggetto di procedure di insolvenza
                                    e le procedure non
                                    vengono respinte
                                    entro 90 giorni.
                                </li>
                                <li>Il Cliente avrà altresì il diritto di recedere dal Contratto, senza
                                    alcuna penalità, eccezion
                                    fatta per l’obbligo di
                                    restituzione delle componenti hardware in comodato, ove previste, e
                                    senza dover specificare il
                                    motivo, entro
                                    14 giorni dalla richiesta dell&#39;ordine. Il Cliente può esercitare il
                                    suo diritto di recesso,
                                    entro il termine, mediante
                                    comunicazione scritta via Posta Elettronica Certificata
                                    (duskrise@pec.it), esprimendo la sua
                                    volontà di
                                    recedere dal contratto.
                                </li>
                                <li>Il Cliente avrà altresì facoltà di recedere dal presente Contratto
                                    dandone comunicazione scritta
                                    con un
                                    preavviso di 30 giorni prima del dodicesimo mese o con un preavviso di
                                    30 giorni prima del
                                    ventiquattresimo mese, mediante comunicazione scritta via Posta
                                    Elettronica Certificata
                                    (duskrise@pec.it), che formalizzi la
                                    richiesta di recesso.
                                </li>
                            </ol>
                            <p>
                                Resta inteso che il diritto di recesso verrà riconosciuto se e solo se le
                                componenti hardware in
                                comodato, ove
                                previste nel Servizio, vengano restituite al più entro 14 giorni dalla
                                richiesta di recesso; oppure
                                in caso di
                                disdetta al entro 5 giorni prima del dodicesimo mese o entro 5 giorni prima
                                del ventiquattresimo
                                mese presso
                                il Punto Cyber in cui si è effettuato l’ordine. In ogni caso qualora il
                                recesso non venga
                                formalizzato nei
                                termini sopra previsti, DuskRise continuerà ad addebitare gli importi
                                previsti e ad erogare il
                                Servizio.
                            </p>
                            <h3>10.4 Effetto della risoluzione e del recesso.</h3>
                            <p>Se il Contratto viene risolto, tutti i diritti e l&#39;accesso ai Servizi
                                (incluso l&#39;accesso ai
                                Dati del Cliente, se inerenti)
                                termineranno, salvo laddove diversamente indicato nel presente
                                Contratto.</p>
                        </div>

                        <div>
                            <h3>11. Pubblicità.</h3>
                            <p>Se il Cliente desidera utilizzare gli Elementi distintivi del brand DuskRise
                                in relazione al suo
                                utilizzo dei Servizi,
                                deve ottenere l&#39;autorizzazione scritta da DuskRise. DuskRise può
                                includere il nome o gli
                                Elementi distintivi del
                                brand del Cliente in un elenco di clienti DuskRise online o in materiali
                                pubblicitari. DuskRise può
                                anche fare
                                riferimento verbalmente al Cliente come cliente dei Servizi. L&#39;eventuale
                                utilizzo degli Elementi
                                distintivi del
                                brand di una parte andrà a vantaggio della parte che detiene i Diritti di
                                proprietà intellettuale
                                relativamente a tali
                                Elementi distintivi del brand. Ciascuna parte potrà revocare all&#39;altra
                                parte il diritto di
                                utilizzare gli Elementi
                                distintivi del brand conformemente alla presente Sezione, previa notifica
                                scritta all&#39;altra
                                parte e concedendo un
                                periodo ragionevole per interrompere l&#39;utilizzo.</p>
                        </div>
                        <div>
                            <h3>12. Dichiarazioni e garanzie.</h3>
                            <p>Ciascuna parte dichiara e garantisce (a) di avere pieno potere legale per
                                stipulare e rendere
                                esecutivo il
                                presente Contratto e (b) che rispetterà tutte le leggi e i regolamenti
                                vigenti per la sua
                                esecuzione, ricezione e
                                uso applicabili all&#39;uso dei Servizi, a seconda dei casi.</p>
                        </div>
                        <div>
                            <h3>13. Disclaimer.</h3>
                            <p>Salvo laddove espressamente specificato nel Contratto, nella misura massima
                                consentita dalla legge
                                vigente,
                                DuskRise (a) non offre altre garanzie di alcun tipo, siano queste esplicite,
                                implicite, di legge o
                                di altro genere,
                                incluse garanzie di commerciabilità, di qualità soddisfacente, di idoneità a
                                uno scopo particolare o
                                conformità
                                con ogni descrizione di servizi e prodotti fornita da Duskrise, di non
                                violazione o di utilizzo
                                privo di errori e
                                ininterrotto dei Servizi o del Software e (b) non risponde di contenuti o
                                informazioni resi
                                accessibili mediante i
                                Servizi.</p>
                        </div>
                        <div>
                            <h3>14. Limitazione di responsabilità.</h3>
                            <h3>14.1 Limitazione della responsabilità indiretta.</h3>
                            <p>Nella misura consentita dalla legge vigente e in conformità con la Sezione
                                14.3 (Responsabilità
                                illimitate),
                                nessuna delle parti avrà responsabilità (su base contrattuale,
                                extracontrattuale o altrimenti) per
                                eventuali (a)
                                danni indiretti, consequenziali, speciali, incidentali o punitivi o (b)
                                perdite di ricavi, profitti,
                                risparmi o reputazione
                                (a prescindere dal fatto che tali perdite rientrino o non rientrino nella
                                valutazione delle parti
                                alla data del
                                Contratto).</p>
                            <h3>14.2 Limitazione dell&#39;importo di responsabilità.</h3>
                            <p>La responsabilità complessiva totale di ciascuna parte per i danni derivanti
                                da o relativi al
                                Contratto è limitata ai corrispettivi dovuti dal Cliente per i 12 mesi di
                                Servizio.</p>
                            <h3>14.3 Responsabilità illimitate.</h3>
                            <p>Nulla nel presente Contratto esclude o limita la responsabilità di ciascuna
                                parte in caso di:

                                (a) dolo o colpa grave;

                                (b) violazione dei diritti di proprietà intellettuale dell&#39;altra parte;

                                (c) violazione degli obblighi di pagamento ai sensi del Contratto; o

                                (d) questioni per cui non è possibile escludere o limitare la responsabilità
                                ai sensi della legge
                                vigente.
                            </p>
                        </div>
                        <div>
                            <h3>15. Disposizioni varie</h3>
                            <h3>15.1 Notifiche.</h3>
                            <p>DuskRise comunicherà al Cliente le notifiche ai sensi del Contratto inviando
                                un&#39;email all&#39;indirizzo
                                di posta
                                elettronica di notifica comunicato al momento dell’attivazione del Servizio.
                                Il Cliente trasmetterà
                                le notifiche a
                                DuskRise ai sensi del Contratto inviando un&#39;email all&#39;indirizzo
                                comunicato al Cliente al
                                momento dell’attivazione
                                del Servizio. La notifica sarà considerata ricevuta nel momento in cui
                                l&#39;email viene ricevuta
                                come verificato da
                                ricevuta scritta o automatica o da log elettronico (a seconda dei casi). Il
                                Cliente sarà ritenuto
                                responsabile di
                                mantenere aggiornato il proprio indirizzo email di notifica per tutta la
                                durata del Contratto. Ai
                                sensi del presente
                                Contratto, le parti possono utilizzare le email per soddisfare i requisiti
                                di approvazione e
                                consenso scritti.</p>
                            <h3>15.2 Trasferimento.</h3>
                            <p>Nessuna delle parti può cedere in tutto o in parte il presente Contratto
                                senza il consenso scritto
                                dell&#39;altra parte,
                                fatta eccezione per una Consociata, laddove (a) detta Consociata abbia
                                accettato per iscritto di
                                essere
                                vincolata dai termini del presente Contratto e (b) la parte cedente rimanga
                                responsabile degli
                                obblighi derivanti
                                dal presente Contratto prima della cessione e abbia comunicato all&#39;altra
                                parte tale
                                trasferimento. Qualsiasi altro
                                tentativo di cessione sarà considerato nullo. Qualora il Cliente ceda in
                                tutto o in parte il
                                presente Contratto a
                                una Consociata in un&#39;altra giurisdizione e tale trasferimento comporti
                                una migliore gestione del
                                contratto da
                                parte di altra società del gruppo DuskRise contraente, il presente Contratto
                                potrà essere
                                automaticamente
                                trasferito a detta società del gruppo DuskRise.</p>
                            <h3>15.3 Passaggio del controllo.</h3>
                            <p>Ove applicabile, se una parte subisce un passaggio di controllo (ad esempio,
                                tramite acquisto o
                                vendita di
                                azioni, fusione o altra forma di transazione societaria), la parte in
                                questione dovrà darne
                                comunicazione scritta
                                all&#39;altra parte entro 30 giorni dal passaggio di controllo.</p>
                            <h3>15.4 Forza maggiore.</h3>
                            <p>Nessuna parte sarà ritenuta responsabile in caso di inadempimento o
                                adempimento tardivo dei propri
                                obblighi
                                se causato da circostanze che esulano dal ragionevole controllo della parte
                                inadempiente, inclusi
                                calamità
                                naturali, atti di guerra o terrorismo, sommosse, sciopero di terzi, azioni
                                governative e disordini
                                su Internet.</p>
                            <h3>15.5 Cessione mediante subcontratto.</h3>
                            <p>DuskRise può cedere mediante subcontratto gli obblighi ai sensi del presente
                                Contratto, ma rimarrà
                                responsabile verso il Cliente di tutti gli obblighi ceduti mediante
                                subcontratto.</p>
                            <h3>15.6 Mancata instaurazione di un rapporto di agenzia.</h3>
                            <p>Il presente Contratto non crea alcun rapporto di agenzia, di partnership o di
                                joint venture tra le
                                parti.</p>
                            <h3>15.7 Esclusione di rinuncia.</h3>
                            <p>L&#39;omissione o il ritardo nell&#39;esercizio di un qualsiasi diritto
                                previsto dal presente
                                Contratto non costituirà una
                                rinuncia ad alcun diritto da parte di nessuna delle parti.</p>
                            <h3>15.8 Clausola di salvaguardia.</h3>
                            <p>Nel caso in cui una qualsiasi disposizione del presente Contratto dovesse
                                risultare non valida,
                                illegale o non
                                azionabile, le disposizioni rimanenti rimarranno comunque in vigore nella
                                misura in cui tale
                                disposizione non sia
                                considerata essenziale ai fini del presente Contratto.</p>
                            <h3>15.9 Emendamenti.</h3>
                            <p>Ad eccezione di quanto indicato nella sezione 1.4 (b) (Modifiche: Al
                                Contratto) o (c) (Modifiche: Ai
                                Termini per il
                                trattamento e la sicurezza dei dati), eventuali modifiche devono essere in
                                forma scritta, firmate da
                                entrambe le
                                parti e devono dichiarare espressamente che si tratta di emendamenti al
                                presente Contratto.</p>
                            <h3>15.10 Sopravvivenza.</h3>
                            <p>Le seguenti sezioni rimarranno valide anche dopo la scadenza o la risoluzione
                                del presente Contratto:
                                Sezione
                                2 (Termini di pagamento), Sezione 5 (Diritti di proprietà intellettuale),
                                Sezione 6 (Diritti di
                                proprietà intellettuale
                                in caso di sviluppi software); Protezione dei dati del Cliente; Feedback;
                                Analisi comparativa),
                                Sezione 9
                                (Informazioni riservate), Sezione 10.3 (Effetto della risoluzione e del
                                recesso), Sezione 13
                                (Disclaimer),
                                Sezione 14 (Limitazione di responsabilità) e Sezione 15 (Disposizioni
                                varie).</p>
                            <h3>15.11 Intero Contratto.</h3>
                            <p>Il presente Contratto illustra tutti i termini concordati dalle parti in
                                relazione al suo oggetto e
                                sostituisce tutti gli
                                altri accordi e intese tra le parti relativi allo stesso oggetto. Con la
                                sottoscrizione del presente
                                Contratto,
                                nessuna delle parti ha fatto affidamento, né avrà alcun diritto o rimedio
                                basati su affermazioni,
                                dichiarazioni o
                                garanzie (rese per negligenza o in buona fede), ad eccezione di quanto
                                espressamente stabilito dal
                                presente
                                Contratto.</p>
                            <h3>15.12 Criterio di precedenza.</h3>
                            <p>Qualora si verifichi un conflitto tra i documenti che compongono il presente
                                Contratto, l&#39;ordine
                                di precedenza dei
                                documenti sarà il seguente: i “Termini per il trattamento e la sicurezza dei
                                dati” e il
                                Contratto.</p>
                            <h3>15.13 Intestazioni.</h3>
                            <p>Titoli e didascalie utilizzate nel Contratto hanno solo scopo di riferimento
                                e non avranno alcun
                                effetto
                                sull&#39;interpretazione del Contratto.</p>
                            <h3>15.14 Conflitto di lingue.</h3>
                            <p>Qualora il presente Contratto venisse tradotto in altre lingue diverse
                                dall&#39;italiano e si
                                generasse una
                                discrepanza tra il testo in italiano e il testo tradotto, prevarrà il testo
                                in italiano, salvo
                                laddove espressamente
                                specificato nella traduzione.</p>
                            <h3>15.15 Definizioni.</h3>
                            <ul>
                                <li>&quot;Account&quot; indica l&#39;account DuskRise del Cliente.</li>
                                <li>&quot;Applicazione del Cliente&quot; identifica un programma software
                                    che il Cliente crea o
                                    ospita o integra utilizzando o non utilizzando i Servizi.
                                </li>
                                <li>&quot;Attività ad alto rischio&quot; indica le attività nelle quali
                                    l&#39;utilizzo o il mancato
                                    funzionamento dei Servizi può
                                    ragionevolmente causare decesso, lesioni alla persona o danni
                                    ambientali.
                                </li>
                                <li>&quot;Console di amministrazione&quot; indica, ove applicabili, la o le
                                    console online o la
                                    security dashboard o
                                    l’interfaccia utente forniti da DuskRise al Cliente per
                                    l&#39;amministrazione, la gestione e
                                    l’utilizzo dei Servizi.
                                </li>
                                <li>&quot;Consociata&quot; indica qualsiasi persona giuridica che Controlli
                                    una parte, ne sia
                                    Controllata o sia sottoposta a
                                    comune Controllo con tale parte, sia direttamente che indirettamente.
                                </li>
                                <li>&quot;Controllo&quot; indica il controllo di oltre il 50% dei diritti di
                                    voto o di
                                    partecipazioni al capitale di una parte.
                                </li>
                                <li>&quot;Corrispettivi&quot; identifica i costi applicabili per ogni
                                    Servizio e ogni imposta
                                    applicabile.
                                </li>
                                <li>&quot;Dati del cliente&quot; indica i dati forniti a DuskRise dal
                                    Cliente o dagli Utenti finali
                                    del Cliente tramite i Servizi
                                    attraverso il proprio Account.
                                </li>
                                <li>&quot;Diritti di proprietà intellettuale&quot; indica i diritti attuali
                                    e futuri a livello
                                    mondiale regolati da leggi sui brevetti,
                                    leggi sul copyright, norme sui segreti industriali e norme sui diritti
                                    morali, nonché tutti gli
                                    altri diritti simili.
                                </li>
                                <li>&quot;Documentazione&quot; indica la documentazione di DuskRise (che può
                                    essere aggiornata di
                                    volta in volta) nel
                                    formato generalmente reso disponibile da DuskRise ai propri clienti per
                                    l&#39;utilizzo con i
                                    Servizi.
                                </li>
                                <li>&quot;Durata&quot; ha il significato specificato nella Sezione 8 (Durata
                                    e risoluzione) del
                                    presente Contratto.
                                </li>
                                <li>&quot;Elementi distintivi del brand&quot; indica i nomi commerciali, i
                                    marchi, i marchi di
                                    servizio, i loghi, i nomi di dominio
                                    e altri elementi distintivi del brand o marca di ciascuna parte,
                                    rispettivamente, come garantito
                                    di volta in volta
                                    da tale parte.
                                </li>
                                <li>&quot;Imposte&quot; indica tutte le imposte e tasse nazionali,
                                    regionali, comunali, sul reddito,
                                    sull’attività, fatturato,
                                    stipendi, proprietà, vendite, diritti d’uso, accise, dogane, valore
                                    aggiunto, consumo, beni e
                                    Prodotti, bollo e
                                    ogni altra imposta analoga o oneri, ivi incluse sanzioni e interessi, ad
                                    eccezione delle imposte
                                    basate sul
                                    reddito netto, sugli utili netti, sul valore patrimoniale, sul valore
                                    immobiliare o sull&#39;occupazione
                                    di DuskRise.
                                </li>
                                <li>&quot;Informazioni riservate&quot; indica le informazioni che una parte
                                    (o una Società
                                    consociata) comunica all&#39;altra
                                    parte ai sensi del presente Contratto e che sono classificate come
                                    riservate o che normalmente
                                    sarebbero
                                    considerate informazioni riservate in tali circostanze. Sono escluse le
                                    informazioni di cui il
                                    destinatario era
                                    già a conoscenza senza violazione di alcun obbligo di riservatezza, le
                                    informazioni prodotte in
                                    modo
                                    indipendente dal destinatario, quelle che vengano legittimamente fornite
                                    al destinatario da una
                                    terza parte
                                    senza obblighi di riservatezza o nonché quelle che diventino di dominio
                                    pubblico per colpe non
                                    imputabili al
                                    destinatario. Fatte salve le disposizioni della frase precedente, i dati
                                    del Cliente sono
                                    considerati
                                    Informazioni riservate.
                                </li>
                                <li>&quot;incluso&quot; indica incluso a titolo esemplificativo.</li>
                                <li>&quot;Indirizzo email di notifica&quot; indica gli indirizzi email
                                    specificati dal Cliente
                                    all’attivazione del Servizio.
                                </li>
                                <li>&quot;Linee guida per i servizi di assistenza tecnica&quot; indica le
                                    linee guida per i servizi
                                    di assistenza di DuskRise in
                                    vigore per i Servizi.
                                </li>
                                <li>&quot;Procedimento giudiziario&quot; identifica una richiesta di
                                    divulgazione di dati presentata
                                    in virtù di disposizioni di
                                    legge, regolamentazioni statali, ingiunzioni di tribunali, citazioni,
                                    mandati oppure di un&#39;altra
                                    entità giuridica
                                    valida, procedura legale o procedimenti analoghi.
                                </li>
                                <li>“Prodotti” indica il complesso dei singoli prodotti e soluzioni
                                    DuskRise, hardware e Software e
                                    documentazione prodotta, tra cui Protezione SmartWorking, Cyber Checkup,
                                    Sorveglianza Cyber,
                                    Protezione Premium.
                                </li>
                                <li>&quot;Responsabilità&quot; indica qualsiasi tipo di responsabilità, sia
                                    per inadempienza
                                    contrattuale, illecito civile
                                    (inclusa la negligenza) o altro, sia prevedibile che contemplato dalle
                                    parti.
                                </li>
                                <li>&quot;Servizio di assistenza tecnica&quot; indica il servizio di
                                    assistenza tecnica attualmente
                                    fornito da DuskRise al
                                    Cliente conformemente alle “Linee guida per i servizi di assistenza
                                    tecnica”.
                                </li>
                                <li>&quot;Software&quot; significa tutti gli strumenti scaricabili,
                                    eventuali software development
                                    kit o software per computer
                                    simili forniti da DuskRise in relazione ai Servizi, oltre a eventuali
                                    aggiornamenti che DuskRise
                                    dovesse di
                                    volta in volta apportare a tale Software.
                                </li>
                                <li>&quot;Sospendere&quot; o &quot;Sospensione&quot; significa disabilitare
                                    o limitare l&#39;accesso
                                    a o l&#39;utilizzo dei Servizi o componenti
                                    dei Servizi.
                                </li>
                                <li>“Termini specifici del Servizio” indica i termini e condizioni specifici
                                    di utilizzo dei singoli
                                    componenti dei
                                    Prodotti.
                                </li>
                                <li>&quot;Utente finale del Cliente&quot; indica un privato a cui il Cliente
                                    consente di utilizzare
                                    i Servizi. Per chiarezza, gli
                                    Utenti finali possono includere in maniera indicativa e non esaustiva i
                                    dipendenti di Consociate
                                    del Cliente e
                                    altre terze parti.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3>Termini per il trattamento e la sicurezza dei dati</h3>
                            <p>Di seguito vengono descritte le modalità con cui raccogliamo e trattiamo le
                                informazioni personali
                                del Cliente in
                                relazione ai Servizi DuskRise.</p>
                            <p>
                                Offriamo i Servizi DuskRise tramite partner autorizzati di Duskrise ed in
                                particolare utilizzando i
                                servizi di
                                Google Cloud Platform. Nei termini seguenti, quando si fa riferimento ai
                                nostri Clienti si intendono
                                inclusi gli
                                Utenti finali del Cliente.</p>
                        </div>
                        <div>
                            <h3>Quali informazioni raccogliamo</h3>
                            <p>Per fornire i Servizi, DuskRise tratta Dati di servizio. Nel seguito vengono
                                descritti i termini per
                                il trattamento e
                                la sicurezza dei dati che riguardano esclusivamente i Dati di servizio.</p>
                            <p>Per Dati di servizio si intendono le informazioni che DuskRise raccoglie o
                                genera durante la
                                fornitura e
                                l&#39;amministrazione dei Servizi. I Dati di servizio includono a titolo
                                esemplificativo:</p>

                            <ul>
                                <li>a) Pagamenti e transazioni relativi al Servizio. Conserviamo ragionevoli
                                    record aziendali su
                                    addebiti,
                                    pagamenti, dettagli di fatturazione e problemi.
                                </li>
                                <li>b) Impostazioni e configurazioni dei Servizi. Registriamo la
                                    configurazione e le impostazioni
                                    dell&#39;utente, tra cui
                                    attributi e identificatori di risorsa. Sono incluse le impostazioni
                                    relative a servizi e
                                    sicurezza per i dati e altre
                                    risorse.
                                </li>
                                <li>c) Dettagli tecnici e operativi dell&#39;utilizzo dei Servizi da parte
                                    dell&#39;utente.
                                    Raccogliamo informazioni sull&#39;utilizzo,
                                    lo stato operativo, rapporti su errori di software e arresti anomali,
                                    credenziali di
                                    autenticazione, metriche di
                                    qualità e prestazioni e altri dettagli tecnici necessari per il
                                    funzionamento e il mantenimento
                                    dei Servizi e del
                                    software correlato. Queste informazioni possono includere identificatori
                                    dei dispositivi,
                                    identificatori da
                                    cookie o token e indirizzi IP, URL e DNS.
                                </li>
                                <li>d) Comunicazioni dirette degli utenti. Conserviamo documentazione sulle
                                    comunicazioni e
                                    interazioni con noi e
                                    i nostri Clienti, ad esempio quando l&#39;utente fornisce un feedback o
                                    informazioni di
                                    contatto, pone domande o
                                    richiede assistenza tecnica.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3>Finalità del trattamento dei dati</h3>
                            <p>DuskRise tratta i Dati di servizio per le seguenti finalità:</p>
                            <ul>
                                <li>a) Fornire i Servizi richiesti dall&#39;utente. I Dati di servizio
                                    vengono utilizzati
                                    principalmente per fornire i Servizi
                                    richiesti dall&#39;utente e dai nostri clienti. Ciò comprende diverse
                                    attività di elaborazione
                                    che sono necessarie
                                    per fornire i Servizi, tra cui l&#39;elaborazione per la fatturazione
                                    relativa all&#39;utilizzo
                                    dei servizi, per assicurare che
                                    i servizi funzionino come previsto, per rilevare e impedire attività
                                    malevole o altri problemi
                                    che potrebbero
                                    verificarsi e per proteggere i dati dell&#39;utente e i servizi che
                                    l&#39;utente utilizza.
                                </li>
                                <li>b) Data monetization. I dati di servizio possono essere utilizzati da
                                    DuskRise ai fini di
                                    analisi di mercato,
                                    comportamentali e decisionali per l’erogazione e il miglioramento dei
                                    servizi richiesti dai
                                    Clienti, nel rispetto
                                    della normativa vigente.
                                </li>
                                <li>
                                    c) Offrire suggerimenti per ottimizzare l&#39;utilizzo dei Servizi.
                                    Possiamo trattare i Dati di
                                    servizio per offrire
                                    all&#39;utente e ai nostri clienti suggerimenti e consigli. Questi
                                    suggerimenti possono
                                    includere modi per
                                    proteggere meglio l&#39;account o i dati dell&#39;utente, migliorare le
                                    prestazioni e
                                    informazioni su prodotti o servizi e
                                    funzionalità nuovi o correlati. Possiamo inoltre valutare la risposta
                                    dell&#39;utente ai
                                    suggerimenti ricevuti.
                                </li>
                                <li>d) Mantenere e migliorare i Servizi. Valutiamo i Dati di servizio per
                                    poter migliorare le
                                    prestazioni e la
                                    funzionalità dei Servizi. Quando ottimizziamo i Servizi per
                                    l&#39;utente, questi potrebbero
                                    migliorare per nostri i
                                    clienti e viceversa.
                                </li>
                                <li>e) Fornire e migliorare altri servizi richiesti dall&#39;utente.
                                    Possiamo utilizzare i Dati di
                                    servizio per fornire e
                                    migliorare altri servizi richiesti dall&#39;utente e dai nostri clienti,
                                    inclusi servizi di
                                    Google o di terze parti abilitati
                                    tramite i Servizi, le console amministrative, eventuali API, Google
                                    Cloud Platform e
                                    Marketplace.
                                </li>
                                <li>f) Fornire assistenza all&#39;utente. Utilizziamo i Dati di servizio
                                    quando necessario per
                                    fornire assistenza tecnica e
                                    servizi professionali secondo quanto richiesto dall&#39;utente e dai
                                    nostri clienti e per
                                    valutare se le esigenze
                                    dell&#39;utente sono state soddisfatte. Utilizziamo inoltre i Dati di
                                    servizio per migliorare l&#39;assistenza
                                    online e per
                                    comunicare con l&#39;utente e con i nostri clienti. Ciò include le
                                    notifiche sugli aggiornamenti
                                    ai Servizi e la
                                    risposta alle richieste di assistenza.
                                </li>
                                <li>g) Proteggere il cliente, gli utenti e DuskRise. Utilizziamo i Dati di
                                    servizio per migliorare
                                    la sicurezza e
                                    l&#39;affidabilità dei nostri servizi. Ciò include il rilevamento, la
                                    prevenzione e la risposta
                                    a frodi, utilizzi illeciti,
                                    rischi per la sicurezza e problemi tecnici che potrebbero danneggiare
                                    gli utenti, i clienti o
                                    DuskRise. Queste
                                    attività sono una parte importante del nostro impegno a proteggere i
                                    nostri servizi.
                                </li>
                                <li>h) Ottemperare agli obblighi di legge. Potremmo dover trattare i Dati di
                                    servizio per
                                    ottemperare ai nostri
                                    obblighi di legge, ad esempio per rispondere a un procedimento
                                    giudiziario o a una richiesta del
                                    governo
                                    con forza esecutiva o per ottemperare agli obblighi relativi alla tenuta
                                    e alla conservazione
                                    dei registri
                                    contabili.
                                </li>
                                <li>i) Altre finalità con il consenso dell&#39;utente. Possiamo richiedere
                                    il consenso dell&#39;utente
                                    per trattare le
                                    informazioni per altre finalità non coperte da quanto definito nei
                                    presenti Termini. L&#39;utente
                                    ha il diritto di
                                    ritirare il consenso in qualsiasi momento.
                                </li>
                            </ul>
                            <p>Per queste finalità possiamo utilizzare i Dati di servizio in combinazione
                                con le informazioni
                                raccolte da altri
                                prodotti e servizi DuskRise o di Google. Possiamo utilizzare algoritmi per
                                riconoscere pattern nei
                                Dati di
                                servizio, è possibile anche che la raccolta e revisione di Dati di servizio
                                avvenga manualmente, ad
                                esempio
                                quando l&#39;utente interagisce direttamente con i team di fatturazione o di
                                assistenza. Possiamo
                                aggregare e anonimizzare i Dati di servizio per eliminare i dettagli
                                personali, e possiamo
                                utilizzare i Dati di Servizio per la
                                reportistica interna e l&#39;analisi del prodotto e delle operazioni
                                commerciali applicabili.</p>
                        </div>
                        <div>
                            <h3>Dove vengono archiviati i dati</h3>
                            <p>I Servizi DuskRise si basano sull’utilizzo dei servizi di Google Cloud
                                Platform da queste località.
                                Il trattamento
                                dei Dati di servizio può avvenire su server di Google situati fuori dal
                                paese perché i Dati di
                                servizio vengono
                                generalmente trattati da unità operative centralizzate o regionalizzate come
                                fatturazione,
                                assistenza e
                                sicurezza.
                                Indipendentemente da dove vengono trattati i Dati di servizio, applichiamo
                                le stesse protezioni
                                descritte in
                                questo documento. Nel caso di trasferimento dei Dati di servizio fuori dallo
                                Spazio economico
                                europeo,
                                DuskRise rispetta le normative applicabili.</p>
                        </div>
                        <div>
                            <h3>Come proteggiamo i dati</h3>
                            <p>Creiamo i Servizi DuskRise con funzionalità di sicurezza solide per la
                                protezione delle informazioni.
                                Le
                                informazioni che otteniamo nel fornire i servizi ci aiutano a rilevare e
                                bloccare automaticamente le
                                minacce alla
                                sicurezza, affinché gli utenti non siano coinvolti.</p>
                            <p>Ci adoperiamo per proteggere l’utente e DuskRise da accessi non autorizzati
                                alle informazioni in
                                nostro
                                possesso e dall’alterazione, dalla divulgazione e dalla distruzione non
                                autorizzate di tali
                                informazioni. In
                                particolare, utilizzando i servizi offerti da Google Cloud:</p>
                            <ul>
                                <li>a) Criptiamo i Dati di servizio inattivi e in transito tra le nostre
                                    strutture.
                                </li>
                                <li>b) Esaminiamo regolarmente le nostre prassi di raccolta, archiviazione e
                                    trattamento delle
                                    informazioni,
                                    comprese le misure sulla sicurezza fisica, per impedire l&#39;accesso
                                    non autorizzato ai
                                    sistemi.
                                </li>
                                <li>c) Consentiamo l&#39;accesso alle informazioni personali soltanto a
                                    dipendenti, consulenti e
                                    partner di DuskRise
                                    che necessitano di tali informazioni per poterle elaborare per noi.
                                    Tutti coloro a cui viene
                                    concesso tale
                                    accesso devono rispettare rigidi obblighi contrattuali in merito alla
                                    riservatezza e potrebbero
                                    essere soggetti
                                    a sanzioni o risoluzione del contratto qualora non rispettassero tali
                                    obblighi.
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3>Come condividiamo i dati</h3>
                            <p>Non condividiamo Dati di servizio con società, organizzazioni o privati che
                                non fanno parte di
                                DuskRise, ad
                                eccezione dei seguenti casi:</p>

                            <ul>
                                <li>Con il consenso dell&#39;utente
                                    <p>Condivideremo Dati di servizio al di fuori di DuskRise solo ed
                                        esclusivamente con il consenso
                                        dell&#39;utente.</p>
                                    <p>Con gli amministratori dell&#39;utente e i rivenditori
                                        autorizzati.</p>
                                    <p>Quando l&#39;utente utilizza i Servizi, l&#39;amministratore
                                        dell&#39;utente e i partner
                                        autorizzati a gestire l&#39;account dell&#39;utente
                                        o dell&#39;organizzazione dell&#39;utente avranno accesso a
                                        determinati Dati di servizio. Ad
                                        esempio, potrebbero essere
                                        in grado di:</p>
                                    <ul>
                                        <li>
                                            Visualizzare informazioni sull&#39;account e dati di
                                            fatturazione, attività e
                                            statistiche. o Modificare la password dell&#39;account.
                                        </li>
                                        <li>Sospendere o interrompere l&#39;accesso all&#39;account da parte
                                            dell&#39;utente.
                                        </li>
                                        <li>Accedere ai dati dell&#39;account per ottemperare a una legge o
                                            una norma vigente, un
                                            procedimento giudiziario
                                            o una richiesta del governo con forza esecutiva.
                                        </li>
                                        <li>Limitare la capacità dell&#39;utente di eliminare o modificare
                                            le informazioni o le
                                            impostazioni sulla privacy.
                                        </li>
                                    </ul>
                                </li>
                                <li>Per trattamenti esterni
                                    <p>Forniamo informazioni alle nostre società consociate, ai nostri
                                        partner e ad altre aziende o
                                        persone fidate
                                        affinché si occupino del trattamento per nostro conto, in base alle
                                        nostre istruzioni e nel
                                        rispetto dei termini del
                                        presente documento e di altre misure appropriate relative a
                                        riservatezza e sicurezza.</p>
                                </li>
                                <li>Per motivi legali
                                    <p>Potremo condividere Dati di servizio all&#39;esterno di DuskRise se
                                        riteniamo in buona fede
                                        che l&#39;accesso, l&#39;utilizzo,
                                        la conservazione o la divulgazione di tali informazioni siano
                                        ragionevolmente necessari
                                        per:</p>
                                    <ul>
                                        <li>ottemperare a leggi o norme vigenti, procedimenti giudiziari o
                                            richieste del governo con
                                            forza esecutiva.
                                        </li>
                                        <li>applicare accordi vigenti, comprese le indagini in merito a
                                            potenziali violazioni.
                                        </li>
                                        <li>rilevare, impedire o altrimenti gestire attività fraudolente o
                                            problemi relativi alla
                                            sicurezza o di natura tecnica.
                                        </li>
                                        <li>tutelare i diritti, la proprietà o la sicurezza di DuskRise, dei
                                            nostri clienti, utenti
                                            e del pubblico, come richiesto o
                                            consentito dalla legge.
                                        </li>
                                    </ul>
                                    <p>Qualora DuskRise dovesse essere coinvolta in una riorganizzazione,
                                        fusione, acquisizione o
                                        vendita di asset,
                                        continuerà a garantire la riservatezza delle informazioni personali
                                        dell&#39;utente e
                                        comunicherà agli utenti
                                        interessati il futuro trasferimento delle informazioni personali o
                                        l&#39;applicazione di
                                        norme sulla privacy diverse.</p>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <h3>Accesso ai dati</h3>
                            <p>Il Cliente potrebbe richiedere di accedere ed esportare i suoi dati per
                                eseguirne il backup o il
                                trasferimento.
                                Alcuni servizi DuskRise consentono all&#39;utente di accedere e scaricare
                                direttamente i dati
                                archiviati nei servizi.</p>
                            <p>Il Cliente può accedere a diverse categorie di Dati di servizio direttamente
                                dai Servizi di DuskRise,
                                incluse
                                informazioni di contatto per la fatturazione, informazioni su pagamenti e
                                transazioni, nonché
                                impostazioni e
                                configurazioni relative ai Servizi.</p>
                            <p>Se l&#39;utente è altrimenti impossibilitato ad accedere ai suoi dati, può
                                richiedere l&#39;accesso
                                utilizzando i servizi di
                                assistenza di DuskRise.</p>
                        </div>
                        <div>
                            <h3>Eliminazione e conservazione dei dati</h3>
                            <p>Conserviamo i Dati di servizio per periodi di tempo diversi a seconda della
                                loro natura, dell&#39;uso
                                che ne facciamo
                                e di come il Cliente configura le sue impostazioni.</p>
                            <p>I Dati di servizio vengono eliminati o anonimizzati quando non sono più
                                necessari a garantire
                                adeguati livelli di
                                sicurezza e protezione del Cliente. Impostiamo i periodi di tempo per la
                                conservazione di ogni tipo
                                di dati e
                                operazione in base alle finalità della raccolta dei dati specifici e ci
                                assicuriamo che non vengono
                                conservati più
                                a lungo del necessario.
                                A volte dobbiamo conservare determinati dati per un periodo esteso di tempo
                                per finalità aziendali o
                                legali
                                legittime. Ad esempio, quando DuskRise elabora un pagamento effettuato dal
                                Cliente conserviamo i
                                relativi dati
                                come richiesto per fini fiscali o contabili. Altre finalità aziendali o
                                legali legittime che possono
                                richiedere la
                                conservazione dei dati da parte nostra sono ad esempio sicurezza,
                                prevenzione di frodi e usi
                                illeciti, garanzia di
                                continuità dei servizi e conformità alle leggi o alle normative vigenti.</p>
                            <p>Quando eliminiamo i dati, eseguiamo procedure dettagliate per assicurarci che
                                i dati siano rimossi in
                                modo
                                sicuro e completo dai sistemi attivi o conservati solo in forma
                                anonimizzata. Prendiamo adeguate
                                misure per
                                assicurarci che i nostri servizi proteggano le informazioni da cancellazioni
                                accidentali o da parte
                                di
                                malintenzionati mediante l&#39;uso di sistemi di backup.</p>
                        </div>
                        <div>
                            <h3>Standard europei sulla privacy e GDPR</h3>
                            <p>Qualora al trattamento delle informazioni relative al Cliente si applichi la
                                legge sulla protezione
                                dei dati
                                dell&#39;Unione Europea, del Regno Unito o della Svizzera, l&#39;utente avrà
                                determinati diritti,
                                tra cui il diritto di
                                accedere, correggere, eliminare o esportare le proprie informazioni, nonché
                                di opporsi al
                                trattamento delle
                                proprie informazioni o di richiederne la limitazione.</p>
                            <p>Per gli utenti residenti nello Spazio economico europeo, nel Regno Unito o in
                                Svizzera, il titolare
                                del
                                trattamento dei dati responsabile per i Dati di servizio è DuskRise S.r.l.
                                Tuttavia, qualora il
                                nostro cliente abbia
                                stipulato un contratto relativo ai Servizi con una Consociata di DuskRise,
                                tale società sarà il
                                titolare del
                                trattamento dei dati responsabile per i Dati di servizio soltanto in
                                relazione alla fatturazione dei
                                Servizi.</p>
                            <p>Oltre alle finalità e ai presupposti definiti in questo documento possiamo
                                trattare le informazioni
                                sulla base delle
                                seguenti motivazioni legali:</p>
                            <ul>
                                <li>Se necessario per l&#39;adempimento di un contratto con il Cliente.
                                    <p>Possiamo trattare le
                                        informazioni quando necessario al fine di stipulare un contratto con
                                        il Cliente o
                                        ottemperare agli impegni contrattuali con il Cliente.</p></li>
                                <li>Quando rispettiamo gli obblighi legali.<p>Tratteremo le informazioni
                                    dell&#39;utente quando
                                    saremo soggetti a obblighi di legge che ci impongono di farlo.</p></li>
                                <li>Quando perseguiamo interessi legittimi.<p>Possiamo trattare Dati di
                                    servizio sulla base di
                                    interessi legittimi nostri e di terzi, applicando al contempo
                                    salvaguardie appropriate a protezione della privacy dell&#39;utente. Ciò
                                    significa che trattiamo
                                    le informazioni
                                    relative al Cliente al fine di fornire i Servizi richiesti; offrire
                                    suggerimenti per ottimizzare
                                    l&#39;uso dei Servizi;
                                    mantenere e migliorare i Servizi; fornire e migliorare altri servizi
                                    richiesti dal Cliente;
                                    fornire assistenza; e
                                    proteggere contro danni ai diritti, alla proprietà o alla sicurezza di
                                    DuskRise, dei suoi
                                    utenti, dei suoi clienti,
                                    secondo quanto prescritto o consentito dalla legge.</p></li>
                            </ul>
                        </div>
                        <div>
                            <h3>Linee guida per i servizi di assistenza tecnica</h3>
                            <p>Le seguenti linee guida per i servizi di supporto tecnico (&quot;Linee
                                Guida&quot;) si applicano ai
                                contratti in cui DuskRise
                                fornisce i Servizi e il relativo supporto tecnico al Cliente.</p>
                            <ol type="1">
                                <li>Come parte dell&#39;acquisto dei Servizi da parte del Cliente, DuskRise
                                    fornirà al Cliente un
                                    Supporto di base
                                    tramite help desk di secondo e terzo livello.
                                </li>
                                <li>Supporto di base. Il Cliente riceverà gli aggiornamenti automatici dei
                                    Servizi e gli
                                    aggiornamenti di
                                    manutenzione, il supporto per le richieste di fatturazione e
                                    l&#39;accesso alla documentazione e
                                    ai white paper.
                                </li>
                                <li>Presentazione della richiesta di supporto.
                                    <ol>
                                        <li>Supporto di prima linea. Ove applicabile il Punto Cyber fornirà
                                            assistenza di primo
                                            livello al Cliente.
                                            DuskRise fornirà assistenza di secondo e terzo livello solo al
                                            Cliente.
                                        </li>
                                        <li>Sforzi del Cliente per risolvere gli errori. Prima di inoltrare
                                            una richiesta al Punto
                                            Cyber, il Cliente compirà
                                            ogni ragionevole sforzo per risolvere qualsiasi errore, bug,
                                            malfunzionamento o difetto
                                            di connettività di rete
                                            senza rivolgersi a DuskRise. Successivamente, il Cliente potrà
                                            presentare una Richiesta
                                            di supporto.
                                        </li>
                                        <li>Procedure per il riconoscimento e la risoluzione delle
                                            Richieste. Quando presenta una
                                            Richiesta, il Cliente
                                            fornirà tutte le informazioni diagnostiche richieste e assisterà
                                            il personale di
                                            assistenza di DuskRise nella
                                            misura necessaria a risolvere una Richiesta.
                                        </li>
                                        <li>Riconoscimento della Richiesta. DuskRise può rispondere a una
                                            Richiesta confermando il
                                            ricevimento della
                                            stessa. Il Cliente riconosce e comprende che DuskRise potrebbe
                                            non essere in grado di
                                            fornire risposte o
                                            risolvere tutte le Richieste.
                                        </li>
                                        <li>Richieste di funzionalità. Se DuskRise ritiene che una Richiesta
                                            sia una Richiesta di
                                            una nuova funzionalità,
                                            DuskRise registrerà tale Richiesta per considerarla da
                                            aggiungere a un futuro
                                            aggiornamento o rilascio dei
                                            Servizi e considererà la questione chiusa. DuskRise non ha alcun
                                            obbligo di rispondere o
                                            risolvere qualsiasi
                                            Richiesta di Funzioni o di includere tale Richiesta di Funzioni
                                            in qualsiasi
                                            aggiornamento o release futura.
                                        </li>
                                        <li>DuskRise in merito ad una Richiesta di Supporto non ha alcun
                                            obbligo ai sensi delle
                                            presenti Linee Guida
                                            di: (i) scrivere, costruire o migliorare qualsiasi Servizio, o
                                            scrivere codice per
                                            migliorare i Servizi; (ii)
                                            configurare nuovi Servizi per il Cliente; o (iii) progettare,
                                            costruire o rivedere i
                                            propri servizi per far
                                            funzionare l&#39;infrastruttura del Cliente.
                                        </li>
                                    </ol>
                                </li>
                                <li>Orari di assistenza e tempi di risposta DuskRise elaborerà le Richieste
                                    durante il normale
                                    orario di lavoro
                                    dalle 9 alle 18 dal lunedì al venerdì (“Orario di Funzionamento”). Tutte
                                    le Richieste ricevute
                                    al di fuori degli
                                    Orari di Funzionamento saranno registrate ed elaborate durante il Giorno
                                    lavorativo successivo.
                                    Gli unici
                                    livelli di servizio contemplati si basano sul livello “next business
                                    day”.
                                </li>
                                <li>Manutenzione. Per garantire prestazioni ottimali dei Servizi, DuskRise
                                    effettua una Manutenzione
                                    periodica.
                                    Nella maggior parte dei casi, la Manutenzione avrà un impatto negativo
                                    limitato o nullo sulla
                                    disponibilità e
                                    sulla funzionalità dei Servizi. Se DuskRise prevede che la Manutenzione
                                    programmata possa avere
                                    un
                                    impatto negativo sulla disponibilità o sulla funzionalità dei Servizi,
                                    DuskRise si impegnerà per
                                    quanto
                                    possibile a fornire un preavviso in merito alla Manutenzione. Inoltre,
                                    DuskRise può eseguire in
                                    qualsiasi
                                    momento una Manutenzione straordinaria di emergenza. Se DuskRise prevede
                                    che tale Manutenzione
                                    non
                                    programmata di emergenza possa influire negativamente sulla
                                    disponibilità o sulla funzionalità
                                    dei Servizi,
                                    DuskRise si impegnerà in modo ragionevole a fornire un preavviso di tale
                                    Manutenzione. Gli
                                    avvisi di
                                    manutenzione di cui sopra saranno forniti tramite lo strumento di
                                    supporto di DuskRise o tramite
                                    un&#39;e-mail
                                    all&#39;indirizzo e-mail di notifica.
                                </li>
                                <li>Supporto linguistico in generale. Tutta l&#39;assistenza fornita da
                                    DuskRise ai sensi delle
                                    presenti Linee Guida
                                    sarà fornita in lingua italiana o inglese.
                                </li>
                                <li>Attività di trattamento dei Dati di Supporto. DuskRise raccoglie ed
                                    elabora i Dati di Supporto,
                                    che rientrano
                                    nei Dati di Servizio, allo scopo di fornire assistenza tecnica ai sensi
                                    delle presenti Linee
                                    Guida e di
                                    mantenere i Servizi.
                                </li>
                                <li>Servizi professionali. Oltre ai servizi di assistenza e manutenzione
                                    sopra descritti, DuskRise
                                    può fornire al
                                    Cliente servizi di consulenza limitati ai sensi delle presenti Linee
                                    Guida, in conformità con un
                                    ordine
                                    aggiuntivo tra DuskRise e il Cliente e con le schede tecniche associate
                                    ai servizi. Potrebbero
                                    essere applicati
                                    costi aggiuntivi. I servizi di consulenza sono solo raccomandazioni. Il
                                    Cliente è responsabile
                                    dei risultati
                                    ottenuti nel momento in cui decide se mettere in pratica i consigli di
                                    DuskRise. DuskRise può
                                    consegnare le
                                    raccomandazioni al Cliente sotto forma di documento di lavoro o
                                    relazione, che il Cliente può
                                    utilizzare,
                                    modificare e riprodurre per i propri scopi commerciali interni. DuskRise
                                    non concederà
                                    altrimenti in licenza
                                    alcuna proprietà intellettuale al Cliente come parte dei servizi di
                                    consulenza forniti ai sensi
                                    delle presenti
                                    Linee Guida. Qualsiasi altro servizio di consulenza, professionale o di
                                    implementazione sarà
                                    soggetto ai
                                    termini di un accordo separato tra DuskRise e il Cliente.
                                </li>
                            </ol>
                        </div>
                        <div>
                            <p>Ai fini e per gli effetti degli artt. 1341 e 1342 c.c., il Cliente dichiara
                                di aver letto e di
                                approvare specificamente i
                                seguenti articoli dell’Allegato A in particolare ai “Termini di servizio
                                applicabili all’utilizzo
                                dei servizi DuskRise”:
                                Art. 1.4 (Modifiche), Art. 2.3 (Controversie relative a fatture e rimborsi),
                                Art. 2.4 (Pagamenti
                                insoluti.
                                Sospensione), Art. 4 (Sospensione), Art. 10.3 (Recesso), Art. 13
                                (Disclaimer), Art. 14 (Limitazioni
                                di
                                Responsabilità) e 15.2 (Trasferimento).</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}