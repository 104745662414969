import {useEffect, useState} from "react";

export function useLocalStorage<T>(key: string): [T | null, (value: T) => void] {
    const isLocalStorageAvailable = typeof localStorage !== 'undefined';
    const [stateValue, setStateValue] = useState<T | null>(() => {
        if (isLocalStorageAvailable) {
            const stored = localStorage.getItem(key);
            return stored ? JSON.parse(stored) : null;
        } else {
            return null;
        }
    });

    const setValue = (val) => {
        if (isLocalStorageAvailable) {
            localStorage.setItem(key, JSON.stringify(val));
        }
        setStateValue(val)
    }

    return [stateValue, setValue];
}

/**
 * YET TO BE TESTED!!!
 */
export function useSessionStorage<T>(key: string, defaultValue: object = null): [T | null, (value: T) => void] {
    const isSessionStorageAvailable = typeof sessionStorage !== 'undefined';
    const [value, setValue] = useState<T | null>(() => {
        if (isSessionStorageAvailable) {
            const stored = sessionStorage.getItem(key);
            return stored ? JSON.parse(stored) : null;
        } else {
            return defaultValue;
        }
    });

    useEffect(() => {
        if (isSessionStorageAvailable && value !== null) {
            sessionStorage.setItem(key, JSON.stringify(value));
        }
    }, [isSessionStorageAvailable, key, value]);

    return [value, setValue];
}
