import {createContext, useContext, useState, useEffect} from 'react';
import {useRouter} from "next/router";
import {useSessionStorage} from "./localStorageHelper";

const MobileAppViewContext = createContext();

const MobileAppViewProvider = ({children}) => {
    /*
    To set the view as a mobile app view, simply call any page adding the query string ?mobileApp=true.
    The value will be persisted in Session storage and be available throughout the app
     */
    const router = useRouter();
    const query = router.query;
    const [isMobileAppView, setIsMobileAppView] = useState(null);  // Need to rely on global state and not only
                                                                            // on session storage due to hydration problems
    const [isMobileAppViewSession, setIsMobileAppSession] = useSessionStorage('mobile-app-query', null);

    useEffect(() => {
        if (query && query.mobileApp) {
            setIsMobileAppView(query.mobileApp.toLowerCase() === 'true');
            setIsMobileAppSession(query.mobileApp.toLowerCase() === 'true');
        } else if (isMobileAppViewSession !== null) {
            setIsMobileAppView(isMobileAppViewSession)
        }
    }, [query]);

    return (
        <MobileAppViewContext.Provider value={ {isMobileAppView} }>
            { children }
        </MobileAppViewContext.Provider>
    );
};

const useMobileAppView = () => useContext(MobileAppViewContext);

export {MobileAppViewProvider, useMobileAppView};
