import React, {useMemo, useCallback} from 'react';
import {useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import {Form, InputGroup, Pagination, Table, Button, Card, Row, Col} from 'react-bootstrap';
import useMediaQuery from '../helpers/useMediaQuery'
import FeatherIcon from 'feather-icons-react';

function TableComponent({
                            data,
                            tableColumns,
                            onButtonClick,
                            showPagination,
                            pageSize,
                            showSearch,
                            showButton,
                            defaultSortBy,
                            cardMode= false
                        }) {
    const isDesktop = useMediaQuery()
    const tableData = useMemo(() => data, [data]);
    const handleButtonClick = useCallback((row) => {
        if (onButtonClick) {
            onButtonClick(row.original);
        }
    }, [onButtonClick]);
    const {
        setGlobalFilter,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        state: {pageIndex, sortBy},
        prepareRow,
    } = useTable(
        {
            columns: tableColumns,
            data: tableData,
            initialState: {pageSize, sortBy: defaultSortBy || []}, // Utilizza defaultSortBy come ordinamento predefinito
        },
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    return (
        <>{ showSearch && (
            <div className="main-content mx-3 my-3 border-bottom">
                <InputGroup className="input-group-merge input-group-flush input-group-reverse">
                    <Form.Control type="search" placeholder="Cerca"
                                  onChange={ (e) => setGlobalFilter(e.target.value ? e.target.value : undefined) }/>
                    <InputGroup.Text>
                        <FeatherIcon icon="search" size="1em"/>
                    </InputGroup.Text>
                </InputGroup>
            </div>
        ) }
            {isDesktop && <Table { ...getTableProps() } responsive size="sm">
                <thead>
                { headerGroups.map(headerGroup => (
                    <tr { ...headerGroup.getHeaderGroupProps() }>
                        { headerGroup.headers.map(column => (
                            <th { ...column.getHeaderProps({
                                style: {minWidth: column.minWidth, width: column.width},
                            }) }>{ column.render('Header') }</th>
                        )) }
                        { showButton && <th></th> }
                    </tr>
                )) }
                </thead>
                <tbody { ...getTableBodyProps() }>
                { page.map(row => {
                    prepareRow(row);
                    return (
                        <tr { ...row.getRowProps() } >
                            { row.cells.map(cell => {
                                return (<td { ...cell.getCellProps() }>{ cell.render('Cell') }</td>);
                            }) }
                            { showButton && (
                                <td className='text-end'><Button variant="white" className='border-0 btn-rounded-circle'
                                                                 size="sm"
                                                                 onClick={ () => handleButtonClick(row) }><FeatherIcon
                                    icon="eye" size="1.5em"/></Button></td>
                            ) }
                        </tr>
                    );
                }) }
                </tbody>
            </Table> }
            {!isDesktop &&      <Row>
                {page.map((row) => {
                    prepareRow(row);
                    return (
                        <Col sm={12} key={row.id}>
                        <Card  >
                            <Card.Body>
                                {row.cells.map((cell) => (
                                    <div key={cell.column.id} className={'d-flex justify-content-between'}>
                                        <strong>{cell.column.Header}:</strong> <span>{cell.render('Cell')}</span>
                                    </div>
                                ))}
                                {showButton && (
                                    <Button
                                        variant="primary"
                                        size="sm"
                                        onClick={() => handleButtonClick(row)}
                                        className="mt-3"
                                    >
                                        Dettaglio
                                    </Button>
                                )}
                            </Card.Body>
                        </Card></Col>
                    );
                })}
            </Row>}
            { showPagination && (
                <>
                    <Pagination size={ isDesktop ? '' : 'sm' } className='justify-content-center'>
                        <Pagination.First onClick={ () => gotoPage(0) } disabled={ !canPreviousPage }/>
                        <Pagination.Prev onClick={ () => previousPage() } disabled={ !canPreviousPage }/>
                        { pageIndex > 2 && pageCount > 6 && (
                            <>
                                <Pagination.Item onClick={ () => gotoPage(0) }>1</Pagination.Item>
                                <Pagination.Ellipsis disabled/>
                            </>
                        ) }
                        { pageCount <= 5 ? (
                            Array.from({length: pageCount}, (_, i) => (
                                <Pagination.Item key={ i } active={ i === pageIndex } onClick={ () => gotoPage(i) }>
                                    { i + 1 }
                                </Pagination.Item>
                            ))
                        ) : (
                            Array.from({length: Math.min(5, pageCount)}, (_, i) => {
                                const page = i + Math.max(pageIndex - 2, 0);
                                if (page < pageCount) {
                                    return (
                                        <Pagination.Item
                                            key={ page }
                                            active={ page === pageIndex }
                                            onClick={ () => gotoPage(page) }
                                        >
                                            { page + 1 }
                                        </Pagination.Item>
                                    );
                                }
                                return null;
                            })
                        ) }
                        { pageIndex < pageCount - 3 && pageCount > 6 && (
                            <>
                                <Pagination.Ellipsis disabled/>
                                <Pagination.Item
                                    onClick={ () => gotoPage(pageCount - 1) }>{ pageCount }</Pagination.Item>
                            </>
                        ) }
                        <Pagination.Next onClick={ () => nextPage() } disabled={ !canNextPage }/>
                        <Pagination.Last onClick={ () => gotoPage(pageCount - 1) } disabled={ !canNextPage }/>
                    </Pagination>
                </>
            ) }
        </>
    );
}

export default TableComponent;
