import Link from "next/link";
import {useRouter} from "next/router";
import React, {useState, useEffect} from "react";
import {
    Nav,
    Navbar,
    NavDropdown
} from "react-bootstrap";
import Avatar from "./Avatar";
import {useUser} from "@auth0/nextjs-auth0/client";
import {navModules} from "../helpers/navModules";
import {useRole} from "../helpers/RoleProvider";
import useMediaQuery from '../helpers/useMediaQuery'
import {Epilogue} from "next/font/google"

const epilogue = Epilogue({ subsets: ['latin'] })
export default function Topbar(...props) {
    const router = useRouter();
    const {user} = useUser();
    const {role, isSuperadmin, isLoading} = useRole();
    const userFirstTwoLetters = user?.nickname?.slice(0, 2);
    const [menuList, setMenuList] = useState([]);
    const isMobile = useMediaQuery()
    useEffect(() => {
        setMenuList([]);
        navModules.forEach((item) => {
            item.role === role && setMenuList(item.modules.filter(module => !module?.superadmin || isSuperadmin));
        });
    }, [role, isSuperadmin, isLoading]);

    return (
        <>
            <Navbar
                collapseOnSelect
                expand="lg"
                sticky="top"
                className={ "justify-content-between " + epilogue.classname}
            >
                <Navbar.Brand as={Link} href="/">
                    <img
                        className="navbar-brand-img ms-4"
                        src={ `/img/logo/puntocyber.svg` }
                        alt="..."
                        style={{width: "150px", transform: "scale(3)"}}
                    />
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse>
                    <Nav activeKey={router.pathname} className="fw-bold ms-1 ms-auto">
                        {menuList &&
                            menuList.map((item, key) => {
                                return (
                                    item &&
                                    !item.hide && (
                                        <Nav.Link
                                            disabled={item.disabled}
                                            as={Link}
                                            key={key}
                                            href={item.href ? item.href : ""}
                                            className={[!item.disabled ? `px-3 ${epilogue.className}` : `px-3 opacity-50 ${epilogue.className}`]}
                                        >
                                            {item.name}
                                        </Nav.Link>
                                    )
                                );
                            })}
                    </Nav>
                </Navbar.Collapse>
                {role && (
                    <Navbar.Collapse className="p-0 m-0 border-0">
                        <Avatar className="me-2" size="sm">
                            <Avatar.Title className="rounded-circle text-capitalize">
                                {userFirstTwoLetters}
                            </Avatar.Title>
                        </Avatar>
                        <NavDropdown
                            title=""
                            align={isMobile?'end':''}
                            id="dropdown-menu-align-end"
                            className="me-4 ms-3 me-md-3"
                        >
                            <NavDropdown.Item as={Link} href="/profile">
                                Il mio profilo
                            </NavDropdown.Item>
                            <NavDropdown.Item as={Link} href="/api/auth/logout">
                                Logout
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Navbar.Collapse>
                )}
            </Navbar >
        </>
    );
}
