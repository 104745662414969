import {WrapperComponent} from "../components";
import {Card, Col, Row} from "react-bootstrap";
import React from "react";
import gears from '../public/img/Gears.svg';
import Image from "next/image";

export default function InstantCheckUpInProgress() {
    return <WrapperComponent >
        <Row className="justify-content-center">
            <Col xs={ 12 } md={ 10 } lg={ 8 } xl={ 6 } className="text-center py-6">
                <h1 className="mb-3">Instant Check-UP</h1>
                <p className="mb-5 text-muted">
                    Valuta la tua esposizione grazie ai nostri servizi Cyber!
                </p>
            </Col>
        </Row>
        <Card>
            <Card.Header >
                <div >
                    <h4 className="card-header-title">In lavorazione</h4>
                </div>
            </Card.Header>
            <Card.Body>
                <p>Stiamo analizzando gli asset inseriti.<br/> Non appena il report sarà pronto riceverai una mail con il link per aprirlo.</p>
                <div className={'text-center'}><Image src={gears} alt='...' width={'200px'} /></div>
            </Card.Body>
        </Card>
    </WrapperComponent>
}