import React from "react";

export default function PrivacyPolicy() {
    return(
        <div>
            <div className="ml-4 mr-3">
                <div>
                    <p>
                        DuskRise srl. (di seguito “DuskRise” o il “Titolare”) La informa, ai sensi dell’art. 13 del Regolamento (UE) n. 2016/679 (di seguito il “GDPR”), che i Suoi dati personali saranno trattati nel rispetto della vigente normativa in materia di protezione dei dati personali e, comunque, dei principi di riservatezza cui è ispirata l’attività del Titolare.
                    </p>
                    <h3>Titolare del trattamento Responsabili del trattamento</h3>
                    <p>Il Titolare del trattamento è DuskRise srl, con sede legale in Roma via Ostiense 92, e-mail privacy@duskrise.com L’elenco aggiornato dei Responsabili del trattamento è disponibile presso la sede del Titolare.</p>
                    <h3>Tipologie di dati trattati</h3>
                    <p>Potranno essere raccolti e trattati i seguenti dati: nome, cognome, data e luogo di nascita, codice fiscale, via e città di residenza e numero/i di telefono. Come parte dell’investigazione potrebbero essere raccolti ulteriori dati personali, la cui natura non è predicibile e potrà ricadere nelle seguenti fattispecie: • Email associata a persone fisiche • Username o identificativo di account e relativa password offuscata associati a una persona fisica</p>
                    <h3>Finalità e base giuridica del trattamento</h3>
                    <p>I dati raccolti saranno trattati per la gestione degli adempimenti contrattuali e per l’adempimento degli obblighi previsti dalla normativa vigente. Il trattamento dei dati personali per la suddetta finalità non richiede il Suo consenso in quanto il trattamento è necessario per adempiere un contratto in oggetto, nonché per l’adempimento di obblighi legali ai sensi dell’art. 6, c. 1, lett. b) e c) del GDPR.</p>
                    <h3>Destinatari o categorie di destinatari</h3>
                    <p>I dati potranno essere resi accessibili, portati a conoscenza di o comunicati ai seguenti soggetti, i quali agiranno, a seconda dei casi in qualità di Titolari Autonomi, Responsabili o Incaricati del trattamento, nell’esercizio delle loro funzioni:

                       </p>
                    <ul>
                        <li>società del gruppo di cui fa parte il Titolare (controllanti, controllate, collegate), dipendenti e/o collaboratori a qualsivoglia titolo del Titolare e/o di società del gruppo di cui fa parte il Titolare;</li>
                        <li>soggetti pubblici o privati, persone fisiche o giuridiche, di cui il Titolare si avvalga per lo svolgimento delle attività strumentali al raggiungimento della finalità di cui sopra o a cui il Titolare sia tenuta a comunicare i dati personali, in forza di obblighi legali o contrattuali (ad es.: società clienti in qualità di Titolari Autonomi).</li>

                    </ul>
                    <p>In ogni caso, i dati personali non saranno diffusi.</p>
                    <h3>Periodo di conservazione</h3>
                    <p>I dati raccolti saranno trattati per il tempo strettamente necessario all’esecuzione del servizio e conservati per un periodo non superiore a 6 (sei) mesi dalla cessazione del contratto.</p>
                    <h3>Diritti di accesso, cancellazione, limitazione e portabilità</h3>
                    <p>La informiamo che, in qualunque momento, relativamente ai Suoi dati, Lei potrà esercitare i diritti previsti nei limiti ed alle condizioni previste dagli articoli 15-22 del GDPR. Per l’esercizio di tali diritti, di seguito descritti, La preghiamo di contattare il Titolare tramite l’ufficio Privacy all’indirizzo email: privacy@duskrise.com a tale richiesta sarà fornito idoneo riscontro nelle tempistiche previste dal GDPR. Nel dettaglio l’Interessato ha il diritto di:</p>
                    <ul>
                        <li>chiedere al Titolare del Trattamento l’accesso, la rettifica o la cancellazione (“Diritto all’Oblio”) dei dati personali o la limitazione del trattamento dei dati personali che lo riguardano o di opporsi al loro trattamento;</li>
                        <li>ottenere la portabilità dei dati;</li>
                        <li>
                            proporre reclamo all’Autorità di controllo, con le modalità descritte sul sito internet www.garanteprivacy.it.</li>
                    </ul>
                    <h3>Aggiornamenti</h3>
                    <p>La presente Privacy Policy sarà soggetta ad aggiornamenti. Il Titolare La invita, pertanto, a visitare periodicamente questa pagina.</p>
                </div>
            </div>
        </div>
    )
}