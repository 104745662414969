import React, { useCallback, useMemo } from 'react';
import { Button, Card, CloseButton, Col, ListGroup, Modal, Row } from 'react-bootstrap';

export default function ModalAddResource({ visible, onDismiss, id_product, products, onCLickAddResource, isPrimary, ...props }) {
    const data = useMemo(
        () => products.filter(product => (isPrimary ? product.parent_product_id === null : product.parent_product_id === id_product)),
        [id_product, isPrimary, products]
    );

    const handleButtonClick = useCallback((el) => {
        if (onCLickAddResource) {
            onCLickAddResource(el);
        }
    }, [onCLickAddResource]);
    return (
        <Modal show={visible} onHide={onDismiss} centered {...props}>
            <Card className='modal-card'>
                <Card.Header>
                    <h4 className="card-header-title">{isPrimary ? 'Aggiungi nuovo servizio' : 'Aggiungi nuova risorsa'}</h4>
                    <CloseButton onClick={onDismiss} />
                </Card.Header>
                <Card.Body style={{ maxHeight: '300px', overflowY: 'auto', overflowX: 'hidden' }}>
                    <ListGroup className="list-group-flush my-n3 ">
                        {data.map((product, index) => (
                            <ListGroup.Item key={index} style={{ order: product.order_priority }}>
                                <Row className={`align-items-center`}>
                                    <Col xs={3}>
                                        <span >
                                            {product.subtotal_price} €
                                        </span>
                                    </Col>
                                    <Col xs={7}>
                                        <span >
                                            {product.name}
                                        </span>
                                    </Col>
                                    <Col xs={2}>
                                        <Button variant="white" size="sm" onClick={() => handleButtonClick(product)}>
                                            +
                                        </Button>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Card.Body>
            </Card>
        </Modal>

    );
}