import React, {useEffect, useState} from "react";
import _ from 'lodash'
import {Button, Card, Col, Row} from 'react-bootstrap';
import ModalAddResource from "../../modals/ModalAddResource";
import ProductCardSection from "./ProductCardSection";
import groupBy from 'lodash/groupBy';
export default function ProductCard({base_item_line, products, item_lines, onDeleteItem, formikProps, addResource, canAddAddonProducts = true, canDeleteProducts = true, onBlurFactory = null, clickedOnSubmit = false,isExpired =false, ...props}) {
    const [modalAddResourceVisible, setModalAddResourceVisible] = useState(false);
    const [hasAddonProducts, setHasAddonProducts] = useState(false)

    useEffect(() => {
        if (products) {
            setHasAddonProducts(_.some(products, (product) => product.parent_product_id === base_item_line.product_id))
        }
    }, [base_item_line, products])

    const modalSystemClose = (el) => {
        addResource(el, base_item_line)
        setModalAddResourceVisible(false)
    }
    const groupByItem = () => {
        let list_item = item_lines.filter(line => line === base_item_line ||  line.parent_item_id === base_item_line.id)
        return groupBy(list_item, 'product_id')
    }
    const sortByOrderPriority = (product) => {
        const orderClass = `order-${product.order_priority}`;
        return product.order_priority === 1 ? `${orderClass} pb-5 border-bottom` : orderClass;//TODO da capire come sistemare con la modifica
    };

    return (
        <Card className={isExpired?'border-1 border-danger':''}>
            <Row>
                {Object.entries(groupByItem()).map(([key,value])=>{
                    return <>
                    <Col sm={12} style={{order:value[0]?.product?.order_priority}}>
                    {/*<Col sm={12} className={ sortByOrderPriority(value[0].product) } >*/}
                    <Card.Header key={key}  >
                        <div className="pt-1">
                            <h4 className={ `card-header-title ${ isExpired&&' text-danger'}` }>{ value[0].product.name } {isExpired && <span className={'text-danger'}>- Prodotto non più vendibile</span>}</h4>
                        <h5 className="card-subtitle pt-3 text-muted">{ value[0].product.description }</h5>
                        </div>
                    </Card.Header>
                        { formikProps.initialValues && (value && value.map((line) =>
                            <><ProductCardSection child_item_line={ line }
                                                onDeleteItemLine={onDeleteItem}
                                                canDeleteProducts={canDeleteProducts}
                                                formikProps={formikProps}
                                                onBlurFactory={onBlurFactory}
                                                key={ `section_${ line.id }` }
                                                clickedOnSubmit={clickedOnSubmit}
                            />

                            </>
                        ))
                        }
                    </Col>
                    </>
                })}
            </Row>
            { (canAddAddonProducts && hasAddonProducts &&
                <Card.Body className='mt-0'>
                    <div className="d-flex justify-content-between">
                        <Row>
                            <Col>
                                <Button
                                    variant="secondary"
                                    onClick={ () => setModalAddResourceVisible(true) }>
                                    Aggiungi altre risorse
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Card.Body>) }
            {(canAddAddonProducts && hasAddonProducts &&
                <ModalAddResource
                    onCLickAddResource={ modalSystemClose }
                    visible={ modalAddResourceVisible }
                    id_product={ base_item_line.product_id }
                    products={ products }
                    onDismiss={ () => setModalAddResourceVisible(false) }
                    variant="secondary"
                />
            )}
        </Card>
    );
}
