import {CHECKUP_NAME, DOMAIN_MONITORING_PLUS_NAME, EMAIL_MONITORING_NAME} from "../helpers/storeHelpers";
import React, {useEffect, useState} from "react";
import {Faq, Panel} from "./index";
import {Button, Col, Container, Row} from "react-bootstrap";
import Image from "next/image";
import logo from "../public/img/PC_PoweredBy_White_1.svg";
import GaugeChart from "./GaugeChart";
import {formatStatusScore} from "../helpers/formatAssetTypeHelper";
import Link from "next/link";
import message from "../public/img/Speech.svg";
import email from "../public/img/Email.svg";
import specialist from "../public/img/FindUserMale.svg";


const text =
    {
        it: {
            low: 'Un Risk Score basso indica una mancata individuazione di esposizioni rilevanti, presenza di BOT o credenziali direttamente esposte. L\'analisi di primo livello effettuata mostra solo le esposizioni allo stato attuale, pertanto suggeriamo di attivare il servizio di Telesorveglianza per conoscere immediatamente eventuali future minacce agli asset selezionati.',
            medium: 'Un Risk Score medio è frutto di una analisi di primo livello delle superfici di attacco relative al dominio richiesto e alla email inserita. Ciò implica una esposizione di medio/alta gravità. Suggeriamo una immediata analisi di secondo livello per ricevere un report dettagliato sulle esposizioni attuali e l’attivazione del servizio di Telesorveglianza per conoscere immediatamente eventuali future minacce agli asset selezionati.',
            high: 'Un Risk Score alto implica la presenza di esposizioni che possono causare perdita di dati sensibili come una compromissione delle tue caselle di posta e/o di alcuni tuoi sistemi. Ti consigliamo di richiedere immediatamente un Cyber Check-Up per ottenere il dettaglio delle esposizioni rilevate o di attivare il servizio di Telesorveglianza per prevenire future minacce ed evitare eventuali danni finanziari e reputazionali.',
            critical: 'Un Risk Score a livello critico indica la presenza di esposizioni significative che necessitano di attenzione immediata per salvaguardare la sicurezza dei tuoi dati e la funzionalità dei tuoi sistemi. Sebbene la situazione richieda un intervento rapido, esistono soluzioni efficaci per mitigare i rischi. Ti consigliamo vivamente di richiedere un Cyber Check-Up per ottenere un\'analisi dettagliata delle vulnerabilità identificate, consentendoti di affrontarle con precisione. Inoltre, l\'attivazione del servizio di Telesorveglianza può offrirti una prevenzione continua, monitorando potenziali future minacce e fornendoti le informazioni necessarie per prevenire danni.'
        },
        en: {
            low: 'A low Risk Score indicates a failure to detect significant exposures, the presence of BOTs, or directly exposed credentials. The first-level analysis performed only shows exposures at the current state, so we suggest activating the Telesurveillance service to immediately know of any future threats to the selected assets.',
            medium: 'A medium Risk Score is the result of a first-level analysis of the attack surfaces related to the requested domain and the entered email. This implies a medium/high severity exposure. We suggest an immediate second-level analysis to receive a detailed report on current exposures and the activation of the Telesurveillance service to immediately know of any future threats to the selected assets.',
            high: 'A high Risk Score detected from the first-level analysis indicates the presence of serious exposures that can lead to the loss of sensitive data (data breach), and the compromise of accounts and critical systems. It is therefore recommended to take immediate action to prevent significant financial and reputational damage, an immediate second-level analysis to receive a detailed report on current exposures, and the activation of the Telesurveillance service to immediately know of any future threats to the selected assets.',
            critical: 'A high Risk Score detected from the first-level analysis indicates the presence of serious exposures that can lead to the loss of sensitive data (data breach), and the compromise of accounts and critical systems. It is therefore recommended to take immediate action to prevent significant financial and reputational damage, an immediate second-level analysis to receive a detailed report on current exposures, and the activation of the Telesurveillance service to immediately know of any future threats to the selected assets.'
        }
    }

const checkUpCart = (domain, email, reference_code) => {
    return {
        agreement_code: null,
        items: {
            [CHECKUP_NAME]: [['', !!domain ? domain : !!email ? email.split('@')[1] : '']]
        },
        reference_code: reference_code ? reference_code : null,
        src: null
    }
}

const monitoringEmailCart = (mail, reference_code) => {
    return {
        agreement_code: null,
        items:
            {[EMAIL_MONITORING_NAME]: mail ? [mail] : ['']}
        ,
        reference_code: reference_code ? reference_code : null,
        src: null
    }
}
const monitoringPlusCart = (domain, reference_code) => {
    return {
        agreement_code: null,
        items: {
            [DOMAIN_MONITORING_PLUS_NAME]: domain ? [domain] : ['']
        },
        reference_code: reference_code ? reference_code : null,
        src: null
    }
}
export default function InstantCheckUpReport(
    {
        data,
        partner = null
    }
) {
    const [score, setScore] = useState(null)
    const [nameDomain, setNameDomain] = useState(null)
    const [nameEmail, setNameEmail] = useState(null)
    const [referenceCode, setReferenceCode] = useState(null)
    const [isEventType, setIsEventType] = useState(false)
    const styleCustom = {
        background: 'rgba(255, 101, 91, 0.38)',
        borderRadius: '4px'
    }
    const url = new URL(window.location.href);

    useEffect(() => {
        if (data) {
            let eventType = data.report_type
            setIsEventType(eventType === 'instant_checkup_event')
            for (const item of data.items) {
                if (item.type === 'domain') {
                    setNameDomain(item.value)
                }
                if (item.type === 'email') {
                    setNameEmail(item.value)
                }
            }
            setReferenceCode(data.reference_code)
            calculateScore(data.overall_risk_score);
        }
    }, [data]);

    const createCartUrl = (itemCart, includeOrigin = true) => {
        return (includeOrigin)
            ? `${ url.origin }/landing/buy?prods=${ encodeURIComponent(JSON.stringify(itemCart)) }${ partner ? '&partner=' + partner : '' }`
            : `/landing/buy?prods=${ encodeURIComponent(JSON.stringify(itemCart)) }${ partner ? '&partner=' + partner : '' }`;
    }
    const calculateScore = (result) => {
        const scoreMapping = {
            'critical': 100,
            'high': 75,
            'medium': 50,
            'low': 25,
        };
        let calculatedScore = 0;
        if (result in scoreMapping) {
            calculatedScore = scoreMapping[result];
        }
        // for (const item of result) {
        //     if (item.toUpperCase() === 'CRITICAL') {
        //         calculatedScore = 100;
        //         break;
        //     } else {
        //         const mappedValue = scoreMapping[item.toUpperCase()];
        //         if (mappedValue > calculatedScore) {
        //             calculatedScore = mappedValue;
        //         }
        //     }
        // }
        setScore(calculatedScore);
    }
    const partnerLogoUrl = `/img/logo/partner/${ partner?.replaceAll('/', '')?.replaceAll('\\', '') }.svg`

    return (
        <div className={ 'bgInstantCheckUp' }>
            <Container fluid={ 'sm' } className={ 'mt-4' }>
                <div className="d-flex justify-content-between d-grid mb-5 mt-5">
                    <Image src={ logo } alt='logo' className={ 'resizableTopLogo' }/>
                    { partner ? <Image src={ partnerLogoUrl } alt='logo' className={ 'resizableTopLogo' } width={ 100 }
                                       height={ 50 }
                                       style={ {transform: "scale(0.8)"} }/> : <div></div> }
                </div>
                <div className={ 'mt-4 ' }>
                    <h1 style={ {fontSize: '31px'} }><b>Risk</b> score</h1>

                    { isEventType ?
                        <p style={ {lineHeight: '24px', fontSize: '16px', fontWeight: '400'} }>Il monitoraggio dei
                            seguenti
                            asset: { nameDomain && ` ${ nameDomain }` }
                            { nameDomain && nameEmail && ',' }
                            { nameEmail && ` ${ nameEmail } ` }, mostra una variazione del tuo livello di rischio a
                            causa di
                            nuovi eventi malevoli.
                            Per conoscere i dettagli ed estendere la tua capacità di prevenzione ti consigliamo di
                            scegliere
                            uno dei nostri servizi premium di seguito elencati:
                        </p> : <p style={ { lineHeight: '24px', fontSize: '16px', fontWeight: '400' } }>
                    L'analisi del rischio della superficie esterna
                    esposta dei seguenti asset:
                    { nameDomain && ` ${ nameDomain }` }
                    { nameDomain && nameEmail && ',' }
                    { nameEmail && ` ${ nameEmail } ` }
                    è stata completata con successo.
                    <br/> Di seguito riportiamo il livello di rischio e l'analisi quantitativa delle minacce
                    scoperte:
                </p>
                }
                { !isEventType && <Row>
                    <Col className={ 'mt-3' } sm={ 12 } md={ 12 } lg={ 6 }>
                        <GaugeChart value={ score }/>
                    </Col>
                    <Col className={ 'p-4' } sm={ 12 } md={ 12 } lg={ 6 }>
                        <h2>COSA SIGNIFICA RISK SCORE <b
                            className={ 'text-uppercase' }>{ formatStatusScore(data.overall_risk_score) }?</b>
                        </h2>
                        <p style={ {fontSize: '12px'} }>{ text.it[data.overall_risk_score] }</p>
                        { data.botnets_exposure !== 0 &&
                            <div className={ 'p-2 my-1' } style={ styleCustom }>
                                { data.botnets_exposure } Infezioni da virus
                            </div>
                        }
                        { data.credentials_exposure + data.dump_credentials !== 0 &&
                            <div className={ 'p-2 my-1' }
                                 style={ styleCustom }>
                                { data.credentials_exposure + data.dump_credentials } Compromissioni
                                sulle identità digitali (email)
                            </div>
                        }
                        { data.vulnerabilities !== 0 &&
                            <div className={ 'p-2 my-1' }
                                 style={ styleCustom }>
                                { data.vulnerabilities } Vulnerabilità
                            </div>
                        }
                    </Col>
                </Row> }
                <Row className="align-items-stretch">
                    { [
                        {
                            title: 'Cyber Check-Up',
                            description: 'Un report dettagliato di tutte le vulnerabilità e le credenziali esposte rilevate, con relative mitigazioni per prevenire gli attacchi.',
                            buttonLabel: 'Acquista Subito',
                            cartUrl: createCartUrl(checkUpCart(nameDomain, nameEmail, referenceCode)),
                            visualize: true
                        }, {
                            title: 'Telesorveglianza Email',
                            description: 'Monitoraggio continuo di credenziali di accesso, con notifica immediata di nuove esposizioni.',
                            buttonLabel: 'Attiva Subito',
                            cartUrl: createCartUrl(monitoringEmailCart(nameEmail, referenceCode)),
                            visualize: !nameDomain
                        }, {
                            title: 'Telesorveglianza Plus',
                            description: 'Il servizio che analizza e monitora un dominio e le sue email collegate. È sempre attivo e ti avvisa immediatamente se rileva pericoli.',
                            buttonLabel: 'Attiva il servizio',
                            cartUrl: createCartUrl(monitoringPlusCart(nameDomain, referenceCode)),
                            visualize: !!nameDomain
                        }
                    ].map((item, index) => (
                        item.visualize && (<Col key={ index } xs={ 12 } md={ 6 } className="d-flex flex-column"
                                                style={ {maxHeight: '100%'} }>
                            <Panel style={ {flex: 1} } className="d-flex flex-column align-items-center">
                                <div className={ 'text-center p-3 d-flex flex-column align-items-center h-100' }>
                                    <h1 className={ 'text-primary text-uppercase' }
                                        style={ {fontSize: '20px', fontWeight: 600} }>{ item.title }</h1>
                                    <p className="flex-grow-1 "
                                       style={ {fontWeight: '400'} }>{ item.description }</p>

                                    <Link href={ item.cartUrl }>
                                        <Button className={ ' align-self-center' }>
                                            { item.buttonLabel }
                                        </Button>
                                    </Link>
                                </div>
                            </Panel>
                        </Col>)
                    )) }
                </Row>
        </div>
</Container>
    <Container className={ 'mt-5' }>
        <h1 style={ {fontSize: '36px'} } className={ 'text-primary text-center mt-6 mb-5' }>FAQ</h1>
        <Faq/>
    </Container>
    <div className={ 'mt-7 ' }>
        <div className={ 'text-center pt-5' }>
            <h1 style={ {fontSize: '32px'} }>Vuoi saperne di più?</h1>
            <p>Contattaci per avere una demo approfondita del servizio e il
                supporto di un membro della nostra rete<br/> territoriale per esplorare tutti i vantaggi
                dell’offerta.</p>
        </div>
        <div className={ 'mt-7 pb-7 ' }>
            <Container>
                <Panel className={ 'p-3' }>
                    <Row>
                        <Col sm={ 12 } md={ !partner ? 4 : 6 } lg={ !partner ? 4 : 6 }>
                            <div style={ {backgroundColor: '#393E4E'} } className={ 'm-2' }>
                                <div className={ 'text-center p-3' }><Image src={ message } alt='...'
                                                                            width={ 55 }/><p
                                    className={ 'text-primary text-uppercase mt-3 ' }>whatsapp</p>
                                    <p>344
                                        0657671</p></div>
                            </div>
                        </Col>
                        <Col sm={ 12 } md={ !partner ? 4 : 6 } lg={ !partner ? 4 : 6 }>
                            <div style={ {backgroundColor: '#393E4E'} } className={ 'm-2' }>
                                <div className={ 'text-center p-3 ' }><Image src={ email } alt='...'
                                                                             width={ 146 }/><p
                                    className={ 'text-primary text-uppercase mt-3 ' }>e-mail</p>
                                    <Link href="mailto:info@puntocyber.com"><p
                                        className={ 'text-white' }>info@puntocyber.com</p></Link></div>
                            </div>
                        </Col>{ !partner &&
                        <Col sm={ 12 } md={ 4 } lg={ 4 }>
                            <div style={ {backgroundColor: '#393E4E'} } className={ 'm-2' }>
                                <div className={ 'text-center p-3' }><Image src={ specialist } alt='...'
                                                                            width={ 55 }/><p
                                    className={ 'text-primary text-uppercase mt-3' }>cyber
                                    specialist</p>
                                    <p>800 912 692</p></div>
                            </div>
                        </Col> }
                    </Row>
                </Panel>
            </Container>
        </div>
    </div>
</div>
)
}