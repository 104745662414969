import React from 'react'
import {useFormikContext} from "formik";
import {Form} from "react-bootstrap";
export default function CustomSelect({title = 'choose',
    name = 'select',
    placeholder = '',
    options = [],
    formGroupClassName = 'mb-3',
    onChange,
    onBlur,
    clickedOnSubmit = false,
    ...otherSelectProps
}) {
    const {values, touched, errors} =
        useFormikContext();
    return (
        <Form.Group className={formGroupClassName}>
            {title !== 'choose'&&
                (<Form.Label htmlFor={name}>{title}</Form.Label>)}
            <Form.Select
                name={name}
                value={values[name]}
                onChange={onChange}
                isInvalid={(clickedOnSubmit || touched[name]) && !!errors[name]}
                onBlur={onBlur}
                id={name}
                {...otherSelectProps}
            >
                {placeholder && <option value={''}>{placeholder}</option>}
                {
                    options && options.map((op) => {
                        return <option value={op.value} key={op.value}>{op.name}</option>
                    })
                }
            </Form.Select>
            <Form.Control.Feedback type="invalid">
                {errors[name]}
            </Form.Control.Feedback>
        </Form.Group>
    )
}
