import { navModules } from './navModules';

export default function canIDoIt(role, path, is_superadmin = false) {
    let status = false;
    navModules.forEach((item)=>{
        if(item.role === role){
            item.modules && item.modules.forEach((module) => {
                module.permissions && module.permissions.forEach((permission) => {
                    if (permission.path === path) {
                        if (!permission.superadmin || is_superadmin) {
                            status = true;
                       }
                    }
                });
            });
        }
    })
    return status;
}