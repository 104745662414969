import {PieChart, Pie, Cell} from 'recharts';
import React from "react";
import {Nunito_Sans} from "next/font/google"
import {formatStatusScore} from "../helpers/formatAssetTypeHelper";
const nunito = Nunito_Sans({ subsets: ['latin'] ,weight: ["400", "600", "700", "800", "900"]})
export default function GaugeChart({value}) {
    const MAX_POINTS = 100;
    const step_points = MAX_POINTS / 4;
    const BG_COLORS = [
        'rgba(123,237,92,0.15)',
        'rgba(250,207,67,0.16)',
        'rgba(251,157,63,0.16)',
        'rgba(253, 92, 92, 0.25)'
    ];
    const riskLabels = ['LOW', 'MEDIUM', 'HIGH', 'CRITICAL'];
    const riskColors = [
        "url(#gradient-low)",
        "url(#gradient-medium)",
        "url(#gradient-high)",
        "url(#gradient-critical)"
    ];
    const riskColorsText = ["#96DA8B", "#FFCC49", "#FF9649", "#DD1313"];
    const labelIndex = Math.min(Math.floor((value - 1) / 25), riskLabels.length-1 );
    const backgroundData = [
        {name: 'A', value: step_points},
        {name: 'B', value: step_points},
        {name: 'C', value: step_points},
        {name: 'd', value: step_points}
    ];
    const pieChartData = [
        {value: value, fill:riskColors[labelIndex]},
        {name: 'remainingPoints', value: MAX_POINTS-value , fill: 'rgba(27, 32, 41, 0)'},
    ];
    const chartGesture = {
        height: 180,
        width: 280,
        startAngle: 180,
        cx: 135,
        innerRadius: 140,
    }
    return (
        <>
            <div className="chart__gauge__wrapper mt-5 mb-6">
                <div className="chart__gauge__wrapper-pie">
                    <PieChart height={ chartGesture.height } width={ chartGesture.width}>
                        <svg>
                            <defs>
                                <linearGradient id="gradient-low" x1="0%" y1="0%" x2="0%" y2="100%">
                                    <stop offset="0%" style={ {stopColor: '#62ED4C', stopOpacity: 1} }/>
                                    <stop offset="100%" style={ {stopColor: '#96DA8B', stopOpacity: 1} }/>
                                </linearGradient>
                                <linearGradient id="gradient-medium" x1="0%" y1="0%" x2="0%" y2="100%">
                                    <stop offset="0%" style={ {stopColor: '#FFCC49', stopOpacity: 1} }/>
                                    <stop offset="100%" style={ {stopColor: '#FFED49', stopOpacity: 1} }/>
                                </linearGradient>
                                <linearGradient id="gradient-high" x1="0%" y1="0%" x2="0%" y2="100%">
                                    <stop offset="0%" style={ {stopColor: '#FF6A49', stopOpacity: 1} }/>
                                    <stop offset="100%" style={ {stopColor: '#FF9649', stopOpacity: 1} }/>
                                </linearGradient>
                                <linearGradient id="gradient-critical" x1="0%" y1="0%" x2="0%" y2="100%">
                                    <stop offset="0%" style={ {stopColor: '#D90000', stopOpacity: 1} }/>
                                    <stop offset="100%" style={ {stopColor: '#FF655B', stopOpacity: 1} }/>
                                </linearGradient>
                            </defs>
                        </svg>
                        <Pie
                            data={backgroundData}
                            startAngle={chartGesture.startAngle}
                            cx={ chartGesture.cx }
                            cy={ chartGesture.height }
                            endAngle={0}
                            innerRadius={ chartGesture.innerRadius }
                            outerRadius={ chartGesture.height }
                            fill="#82ca9d"
                            paddingAngle={1}
                            stroke="none"
                            dataKey="value"
                        >
                            {
                                backgroundData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={BG_COLORS[index % BG_COLORS.length]}/>
                                ))
                            }
                        </Pie>
                        <Pie
                            stroke="none"
                            data={ pieChartData }
                            dataKey="value"
                            cx={ chartGesture.cx }
                            cy={ chartGesture.height }
                            startAngle={chartGesture.startAngle}
                            endAngle={ 0 }
                            innerRadius={ chartGesture.innerRadius }
                            outerRadius={ chartGesture.height }
                            paddingAngle={ 0 }
                        />
                    </PieChart>
                    <p className={ "chart__gauge__value-label text-uppercase "+ nunito.className} style={{ color: riskColorsText[labelIndex], fontWeight: 300, fontStyle:'normal' }}>
                        {formatStatusScore(riskLabels[labelIndex]) }
                    </p>
                </div>
            </div>
        </>
    );
}