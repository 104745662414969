import _ from "lodash";
import {generateGuid} from "./guidGenerationHelper";
import * as Yup from "yup";
import {getAgreementCodeSchema, validateInputAssetType} from "./formatAssetTypeHelper"
import {toast} from "react-hot-toast";

export const DOMAIN_MONITORING_SKU = 'PC-TSD-001'
export const DOMAIN_MONITORING_PLUS_SKU = 'PC-TSDP-001'
export const EMAIL_MONITORING_SKU = 'PC-TSE-001'
export const SECTOR_SKU = 'PC-GIS-001'
export const CHECKUP_SKU = 'PC-CC-001'
export const GLOBAL_INTELLIGENCE_SKU = 'PC-GI-001'

export const DOMAIN_MONITORING_NAME = 'domain-monitoring'
export const DOMAIN_MONITORING_PLUS_NAME = 'domain-monitoring-plus'
export const EMAIL_MONITORING_NAME = 'email-monitoring'
export const CHECKUP_NAME = 'cyber-checkup'
export const GLOBAL_INTELLIGENCE_NAME = 'global-intelligence'

export const productNamesToSkuMap = {
    [DOMAIN_MONITORING_NAME]: DOMAIN_MONITORING_SKU,
    [DOMAIN_MONITORING_PLUS_NAME]: DOMAIN_MONITORING_PLUS_SKU,
    [EMAIL_MONITORING_NAME]: EMAIL_MONITORING_SKU,
    [CHECKUP_NAME]: CHECKUP_SKU,
    [GLOBAL_INTELLIGENCE_NAME]: GLOBAL_INTELLIGENCE_SKU,
}

export const VALIDATOR_BLACKLIST = 'VALIDATOR_BLACKLIST'

export const AGREEMENT_CODE_FIELD_NAME = 'agreement_code'

export const TAX_RATIO = 0.22

const TEST_USERS = [
    "puntocyber.centro@duskrise.com",
    "puntocyber.customer@duskrise.com",
    "puntocyber.review@duskrise.com",
    "alessandro.marchetti.90@gmail.com",
    "marcopiscitelli42@gmail.com",
    "f.diluva@gmail.com",
    "alex01.mobile@gmail.com",
    "antoniocaiazza@gmail.com",
]

const TEST_NICKNAMES = [
    "puntocyber.centro",
    "puntocyber.customer",
    "puntocyber.review",
    "alessandro.marchetti.90",
    "marcopiscitelli42",
    "f.diluva",
    "alex01.mobile",
    "antoniocaiazza",
]

const domainLimit = 20
const emailLimit = 50
const sectorLimit = 2  // One sector is already in the base product -> 2 + 1 = 3
const checkupLimit = 20

export function mapProductToItemLine(product, parent_item_id = null, assets = null) {
    let generatedAssets;
    if (assets) {
        generatedAssets = assets;
    } else {
        generatedAssets = [];
        for (const asset_configuration of product.product_assets) {
            const {asset_type, quantity} = asset_configuration;
            for (let i = 0; i < quantity; i++) {
                let asset = {
                    "id": generateGuid(),
                    "value": null,
                    "type": asset_type
                }
                generatedAssets.push(asset)
            }
        }
    }

    return {
        id: generateGuid(),
        product_id: product.id,
        parent_item_id: parent_item_id,
        subtotal_price: product.subtotal_price,
        assets: generatedAssets,
        product: product,
    }
}


export const mapCartToInitialValues = (cart, options = {}) => {
    const {includeAgreementCode = false, partnershipAgreementCode} = options

    const initialValues = {}
    for (const line of cart.item_lines) {
        for (const asset of line.assets) {
            initialValues[line.id + '_' + asset.id] = asset.value || ""
        }
    }
    if (includeAgreementCode) {
        initialValues[AGREEMENT_CODE_FIELD_NAME] = cart?.agreement_code || cart?.agreement?.code || partnershipAgreementCode  || null
    }
    return initialValues
}


export const mapCartToValidationSchemaShape = (cart, areFieldsRequired, options = {}) => {
    const {includeAgreementCode = false} = options
    const validationSchemaObj = {}
    for (const line of cart.item_lines) {
        //TODO need to upgrade logic
        const isBundleLine = line.assets.length > 2
        for (const asset of line.assets) {
            validationSchemaObj[line.id + '_' + asset.id] = validateInputAssetType(asset.type, !isBundleLine && areFieldsRequired)
        }
    }
    if (includeAgreementCode) {
        validationSchemaObj[AGREEMENT_CODE_FIELD_NAME] = getAgreementCodeSchema()
    }
    return Yup.object().shape(validationSchemaObj)
}

// export const mapCartToAsyncValidators = (cart) => {
//     const validators = {}
//     for (const line of cart.item_lines) {
//         for (const asset of line.assets) {
//             if (asset.type.toLowerCase() === "domain") {
//                 validators[line.id + '_' + asset.id] = (value) => checkBlackListMutation({'items':[value]})
//             }
//         }
//     }
//     return validators
// }

export const mapCartToValidatorTypes = (cart) => {
    const validatorTypes = {}
    for (const line of cart.item_lines) {
        for (const asset of line.assets) {
            if (asset.type.toLowerCase() === "domain") {
                validatorTypes[line.id + '_' + asset.id] = VALIDATOR_BLACKLIST
            }
        }
    }
    return validatorTypes
}

export const mapCartToAssetTypes = (cart) => {
    const assetTypes = {}
    for (const line of cart.item_lines) {
        for (const asset of line.assets) {
            assetTypes[line.id + '_' + asset.id] = asset.type
        }
    }
    return assetTypes
}


export function prepareCartOrOrderForUi(entity, products = null, removeItemLinesIfNotValid = false, inPlace = false) {
    if (!inPlace) {
        entity = _.cloneDeep(entity)
    }

    if (!products) {
        products = entity.item_lines.map(line => line.product)
        products = _.uniqBy(products, "id")
    }

    const productsMap = _.keyBy(products, "id");

    // Validate or clean entity
    if (removeItemLinesIfNotValid) {
        entity.item_lines = entity.item_lines.filter(line => !!productsMap[line.product_id])
    } else {
        const nonExistentItemLines = entity.item_lines.filter(line => !productsMap[line.product_id])
        if (nonExistentItemLines.length > 0) {
            throw Error(`Couldn't find products [${ nonExistentItemLines.map(line => line.product_id).join(',') }] within the products`);
        }
    }

    for (let line of entity.item_lines) {
        line.product = productsMap[line.product_id]
        line.subtotal_price = line.product.subtotal_price
        line.tax = line.product.tax
        line.total_price = line.product.total_price
        const assetsByType = _.groupBy(line.assets, "type")
        for (let assetConfig of line.product.product_assets) {
            const howManyForType = assetsByType[assetConfig.asset_type]?.length || 0;
            if (assetConfig.quantity > howManyForType) {
                const howManyToAdd = assetConfig.quantity - howManyForType;
                for (let i = 0; i < howManyToAdd; i++) {
                    line.assets.push({
                        id: generateGuid(),
                        value: "",
                        type: assetConfig.asset_type,
                    })
                }
            }
        }
    }

    entity.agreement_code = entity?.agreement?.code;

    return entity
}


// export function prepareCartOrOrderForUiOld(entity, products, removeItemLinesIfNotValid = false, inPlace = false) {
//     if (!inPlace) {
//         entity = _.cloneDeep(entity)
//     }
//     const productsMap = _.keyBy(products, "id");
//
//     // Validate or clean entity
//     if (removeItemLinesIfNotValid) {
//         entity.item_lines = entity.item_lines.filter(line => !!productsMap[line.product_id])
//     } else {
//         const nonExistentItemLines = entity.item_lines.filter(line => !productsMap[line.product_id])
//         if (nonExistentItemLines.length > 0) {
//             throw Error(`Couldn't find products [${ nonExistentItemLines.map(line => line.product_id).join(',') }] within the products`);
//         }
//     }
//
//     for (let line of entity.item_lines) {
//         line.product = productsMap[line.product_id]
//         const assetsByType = _.groupBy(line.assets, "type")
//         for (let assetConfig of product.product_assets) {
//             const howManyForType = assetsByType[assetConfig.asset_type]?.length || 0;
//             if (assetConfig.quantity > howManyForType) {
//                 const howManyToAdd = assetConfig.quantity - howManyForType;
//                 for (let i = 0; i < howManyToAdd; i++) {
//                     line.assets.push({
//                         id: generateGuid(),
//                         value: "",
//                         type: assetConfig.asset_type,
//                     })
//                 }
//             }
//         }
//     }
//
//     entity.agreement_code = entity?.agreement?.code;
//
//     return entity
// }


export const prepareCartOrOrderForPut = (entity, options = {}) => {
    const {includeInvoiceInformation = true, includePublished = false, includeProducts = false} = options
    let result = _.cloneDeep(entity);
    for (const item_line of result.item_lines) {
        item_line.assets = item_line.assets.filter((asset) => asset.value !== null && asset.value !== undefined && asset.value !== '')
        for(const asset of item_line.assets){
            if(asset.type === 'Domain' || asset.type === 'Email'){
                asset.value = asset.value.toLowerCase()
            }
        }
    }

    if (!includeInvoiceInformation) {
        delete result.invoice_information
    }
    result.item_lines = result.item_lines.map((line) => (
        includeProducts
            ? {
                id: line.id,
                product_id: line.product_id,
                parent_item_id: line.parent_item_id,
                assets: line.assets,
                product: line.product,
            }
            : {
                id: line.id,
                product_id: line.product_id,
                parent_item_id: line.parent_item_id,
                assets: line.assets,
            }))

    result = {  // Mapping to remove additional lines
        item_lines: result.item_lines,
        invoice_information: result.invoice_information,
        reference_code: result.reference_code,
        agreement_code: (result.hasOwnProperty('agreement_code') ? result.agreement_code?.toUpperCase() : result.agreement?.code?.toUpperCase()) || null,
    }

    if (includePublished) {
        result.published = entity.published
    }
    if (!includeInvoiceInformation) {
        delete result.invoice_information
    }
    return result
}

export const prepareOrderForPost = (entity, published, user_id) => {
    let result = _.cloneDeep(entity);
    for (const item_line of result.item_lines) {
        item_line.assets = item_line.assets.filter((asset) => asset.value !== null && asset.value !== undefined && asset.value !== '')
        for(const asset of item_line.assets){
            if(asset.type === 'Domain' || asset.type === 'Email'){
                asset.value = asset.value.toLowerCase()
            }
        }
    }
    result.item_lines = result.item_lines.map((line) => ({
        id: line.id,
        product_id: line.product_id,
        parent_item_id: line.parent_item_id,
        assets: line.assets,
    }))
    result = {
        item_lines: result.item_lines,
        published: published,
        user_id: user_id,
        agreement_code: result.agreement_code?.toUpperCase() || null,
    }
    return result
}

/**
 * This function is designed to operate *in place*
 */
export function sortCartOrOrder(entity) {
    for (let line of entity.item_lines) {
        line.assets.sort((asset1, asset2) => (
            asset1.type === asset2.type
                ? asset1.value.localeCompare(asset2.value)
                : asset1.type.localeCompare(asset2.type))
        )
    }
}

export function parseCartInfoFromLanding(cart, products) {
    const items = cart.items
    let finalCart = {
        item_lines: [],
        reference_code: cart.reference_code,
        agreement_code: cart.agreement_code,
    }

    const globalIntelligenceItemLineId = generateGuid()

    const finalMappedItems = {}
    Object.keys(items).forEach((itemKey) => {
        const sku = (productNamesToSkuMap[itemKey]) ? productNamesToSkuMap[itemKey] :  itemKey;
        finalMappedItems[sku] = items[itemKey]
    });

    // Iterate over the SKUs from the landing page and push item_lines to finalCart
    Object.keys(finalMappedItems).forEach((sku) => {
        const product = products.find(p => p.sku === sku)
        const assets = finalMappedItems[sku];
        const mappedItemLines = assets.map(asset => ({
            id: (sku === GLOBAL_INTELLIGENCE_SKU) ? globalIntelligenceItemLineId : generateGuid(),
            product_id: product.id,
            parent_item_id: (sku === SECTOR_SKU) ? globalIntelligenceItemLineId : null, // Sector is a child of Global Intelligence
            assets: mapLandingAssetToApiAssets(sku, asset)
        }))
        finalCart.item_lines.push(...mappedItemLines)
    })

    return finalCart
}

export function mapLandingAssetToApiAssets(sku, assetValue) {
    switch (sku) {
        case GLOBAL_INTELLIGENCE_SKU:
            return [{id: generateGuid(), value: assetValue, type: "Sector"}]
        case SECTOR_SKU:
            return [{id: generateGuid(), value: assetValue, type: "Sector"}]
        case CHECKUP_SKU:
            return Array.isArray(assetValue)
                ? [{id: generateGuid(), value: assetValue[0], type: "CompanyName"},
                    {id: generateGuid(), value: assetValue[1], type: "Domain"}]
                : [{id: generateGuid(), value: assetValue, type: "CompanyName"}]
        case DOMAIN_MONITORING_SKU:
            return [{id: generateGuid(), value: assetValue, type: "Domain"}]
        case DOMAIN_MONITORING_PLUS_SKU:
            return [{id: generateGuid(), value: assetValue, type: "Domain"}]
        case EMAIL_MONITORING_SKU:
            return [{id: generateGuid(), value: assetValue, type: "Email"}]
    }
}

export const getPaymentStatus = (payments) => {
    if (payments && payments.length === 0) {
        return "-"
    }
    const failed = payments && payments.some((payment) => payment.status === "Failed");
    const accepted = payments && payments.some((payment) => payment.status === "Accepted");

    if (accepted) {
        return "Completato";
    } else if (failed) {
        return "Fallito";
    } else {
        return "-";
    }
};

/**
 * Limit Asset Type
 */

export function limitAssetConfiguration(cart, checkForLimit, sku = null) {
    if (cart) {
        let domainCount = 0
        let domainPlusCount = 0
        let emailCount = 0
        let sectorCount = 0
        let checkupCount = 0
        for (let item of cart.item_lines) {
            let sku = item.product.sku
            if (sku === DOMAIN_MONITORING_SKU) {
                domainCount += 1
            } else if (sku === DOMAIN_MONITORING_PLUS_SKU) {
                domainPlusCount += 1
            } else if (sku === EMAIL_MONITORING_SKU) {
                emailCount += 1
            } else if (sku === SECTOR_SKU) {
                sectorCount += 1
            } else if (sku === CHECKUP_SKU) {
                checkupCount += 1
            }
        }
        if (checkForLimit) {
            if (sku === DOMAIN_MONITORING_SKU && domainCount >= domainLimit) {
                toast.error(`Spiacenti, il limite massimo di Telesorveglianza Dominio è stato raggiunto`)
                return true
            }
            if (sku === DOMAIN_MONITORING_PLUS_SKU && domainPlusCount >= domainLimit) {
                toast.error(`Spiacenti, il limite massimo di Telesorveglianza Dominio Plus è stato raggiunto`)
                return true
            }
            if (sku === EMAIL_MONITORING_SKU && emailCount >= emailLimit) {
                toast.error(`Spiacenti, il limite massimo di Telesorveglianza Email è stato raggiunto`)
                return true
            }
            if (sku === SECTOR_SKU && sectorCount >= sectorLimit) {
                toast.error(`Spiacenti, il limite massimo di Settori analizzabili è stato raggiunto`)
                return true
            }
            if (sku === CHECKUP_SKU && checkupCount >= checkupLimit) {
                toast.error(`Spiacenti, il limite di Cyber Check-Up è stato raggiunto`)
                return true
            }
        } else {
            if (domainCount > domainLimit) {
                toast.error(`Spiacenti, il limite massimo di Telesorveglianza Dominio è stato raggiunto`)
                return true
            }
            if (emailCount > emailLimit) {
                toast.error(`Spiacenti, il limite massimo di Telesorveglianza Email è stato raggiunto`)
                return true
            }
            if (sectorCount > sectorLimit) {
                toast.error(`Spiacenti, il limite massimo di Settori analizzabili è stato raggiunto`)
                return true
            }
            if (checkupCount > checkupLimit) {
                toast.error(`Spiacenti, il limite di Cyber Check-Up è stato raggiunto`)
                return true
            }
        }
        return false
    }
}

export function isTestUser(user) {
    return TEST_USERS.indexOf(user?.email?.toLowerCase()) !== -1
        || TEST_NICKNAMES.indexOf(user?.nickname?.toLowerCase()) !== -1
}

export function updateTotalPrice(cartOrOrder) {

    let discountRatio = cartOrOrder?.agreement?.discount_percentage_ratio || 0
    let subTotalBeforeDiscount = 0
    for (const item_line of cartOrOrder?.item_lines) {
        subTotalBeforeDiscount += (item_line?.product?.subtotal_price || item_line?.subtotal_price)
    }
    const discount = subTotalBeforeDiscount * discountRatio
    const subTotalAfterDiscount = subTotalBeforeDiscount - discount
    const tax = subTotalAfterDiscount * TAX_RATIO
    const total = subTotalAfterDiscount + tax

    return {
        subTotalBeforeDiscount: subTotalBeforeDiscount,
        discount: discount,
        subTotalAfterDiscount: subTotalAfterDiscount,
        tax: tax,
        total: total,
    }
}

export function calculateMinimumOrderPrice(cartOrOrder) {
    let minimumPriceArray = []
    for (const item_line of cartOrOrder?.item_lines) {
        minimumPriceArray.push(item_line?.product?.minimum_order_total_price)
    }

    return minimumPriceArray.length > 0 ? Math.max(...minimumPriceArray) : 600
}

export function checkExpiredProduct(cartOrOrder) {
    let date = new Date()
    let expired = 0
    if (cartOrOrder) {
        for (const line of cartOrOrder.item_lines) {
            if (line?.product.deletion_datetime_utc && new Date(line.product.deletion_datetime_utc) < date) {
                expired += 1
            }
        }
        if (expired === 0) {
            return false
        } else {
            toast.dismiss()
            toast.error('Uno o più prodotti sono scaduti, impossibile completare l\'ordine')
            return true
        }
    }
}

export function checkExpiredSingleProduct(itemline) {
    let date = new Date()
    if (itemline.product.deletion_datetime_utc === null){
        return false
    }
    return new Date(itemline.product.deletion_datetime_utc) < date
}